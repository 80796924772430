<template>
  <div class="dashboardheader">
    <div class="headerTop">
      <div class="header-left">
        <subscribe-button
          v-if="adventure.allowSubscribe"
          :displayAsIcon="false"
          :campaignId="adventure.subscriptionKey"
          >Subscribe</subscribe-button
        >
        <base-input
          v-if="!readOnly"
          v-model="title"
          :options="{
            underlineEffect: false,
            hoverEffect: true,
            borderless: true,
            placeholder: `${titlePlaceholder}`,
            large: true,
            blue: true,
            maximumLength: 20,
            inlineErrors: true,
          }"
          :validation="{ delay: 1000, callback: saveAdventureTitle }"
        ></base-input>
        <div v-else class="title">{{ title }}</div>
      </div>

      <icon
        :onWhite="false"
        class="headerIcon"
        :options="menuIconOptions"
        @menuClick="handleMenuClick"
        >menu</icon
      >
    </div>
    <help v-if="showHelp" @close="showHelp = false"> </help>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { auth } from "../../api/firebaseInit";

// Classes
import CheckInArray from "../../api/classes/CheckinArray";

// API
import { updateAdventureTitle } from "../../api/firestoreAdventures";
import { getUser, updateUser } from "../../api/firestoreUser";
import { Adventure } from "../../api/classes/Adventure";

import { constants } from "../../constants";

// Components
import Icon from "../controls/Icon";
import BaseInput from "./BaseInput.vue";
import SubscribeButton from "./SubscribeButton.vue";
import Help from "./../pages/Help.vue"

import { AutoSave } from "../../api/classes/AutoSave";
import { UrlBuilder } from "../../api/classes/UrlBuilder";

export default {
  name: "DashboardHeader",

  emits: [
    "clickLatestCheckIn",
    "changeView",
    "showstats",
    "showShare",
    "refresh",
  ],

  components: {
    BaseInput,
    Icon,
    Help,
    SubscribeButton,
  },

  props: {
    adventure: Adventure,
    checkIns: {
      type: CheckInArray,
      required: true,
    },
    readOnly: Boolean,
  },

  watch: {
    "adventure.name": function () {
      this.title = this.adventure.name;
      this.titlePlaceholder = "Untitled Adventure";
    },
    readOnly: function (isReadOnly) {
      if (isReadOnly) {
        this.stopAutoSave();
      } else {
        this.startAutoSave();
      }
    },
  },

  data() {
    return {
      showMenu: false,
      showHelp: false,
      //showShare: false,
      title: "",
      titlePlaceholder: "",
      autoSave: AutoSave,
      currentAdventureUserDisplayName: "",
    };
  },

  computed: {
    ...mapState({
      user: "user",
      loggedIn: "loggedIn",
    }),
    isOwner() {
      return this.loggedIn && this.user.username == this.$route.params.username;
    },
    isViewMode() {
      return UrlBuilder.isDashboardUrlViewMode(this.$route.path);
    },
    showEdit() {
      return this.isOwner && this.isViewMode;
    },
    showView() {
      return this.isOwner && !this.isViewMode;
    },
    menuIconOptions() {
      let options = constants.ICON_OPTIONS.ON_DARK;
      options.hover.backgroundColour = "var(--ish-bluehover)";
      options.menu = {
        position: true,
        menuItems: [
          { id: 0, name: "Home", show: this.loggedIn, iconName: "home" },
          {
            id: 1,
            name: this.currentAdventureUserDisplayName,
            show: !this.isOwner,
          },
          //   { id: 11, name: "Auto Correct Dates", show: false },
          { isDivider: true, show: this.loggedIn },
          //   { id: 3, name: "Share...", iconName: "share" },
          { id: 8, name: "Preview", show: this.showView, iconName: "preview" },
          {
            id: 7,
            name: "Exit Preview",
            show: this.showEdit,
            iconName: "preview",
          },
          { isDivider: true, show: this.showEdit || this.showView },
          { id: 9, name: "Refresh", iconName: "refresh" },
          { isDivider: true },
          {
            id: 12,
            name: "Help",
            show: this.loggedIn,
            subText: "Useful tips and tricks!",
            iconName: "help",
          },
          { isDivider: true, show: this.loggedIn },
          {
            id: 5,
            name: "Log Out",
            show: this.loggedIn,
            subText: this.user.email,
          },
          { isDivider: true, show: this.loggedIn },
          {
            id: 11,
            name: "",
            subText: process.env.VUE_APP_VERSION,
            isFullWidth: true,
            isLabel: true,
          },
        ],
      };
      return options;
    },
  },

  mounted() {
    const vm = this;
    this.title = this.adventure.name;

    if (this.loggedIn) {
      this.startAutoSave();
      // remember the adventure, so we can come back here the next time they log in
      if (this.user.lastAdventureId != this.adventure.id) {
        this.$store.commit("SET_USER_DATA", {
          lastAdventureId: this.adventure.id,
        });
        updateUser(this.user)
          .then(() => {
            console.log("saved adventure id for user");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    // Get the display name of the user this adventure belongs too
    if (!this.isOwner) {
      getUser(this.adventure.uid).then((user) => {
        vm.currentAdventureUserDisplayName = user.displayName;
      });
    } else {
      vm.currentAdventureUserDisplayName = this.user.displayName;
    }
  },

  methods: {
    handleMenuClick(menuItem) {
      if (menuItem.id == 0) {
        this.$router.push(UrlBuilder.urlForUserHome());
      } else if (menuItem.id == 1) {
        this.$router.push(
          UrlBuilder.urlForUserHome(this.$route.params.username)
        );
      } else if (menuItem.id == 3) {
        // show share
        this.$emit("showShare");
      } else if (menuItem.id == 5) {
        // log out
        auth.signOut().then(() => {
          this.$router.push("/");
        });
      } else if (menuItem.id == 6) {
        // log in
        this.$router.push("/login");
      } else if (menuItem.id == 7) {
        // Edit
        this.$router.push(
          UrlBuilder.urlForDashboard(
            this.adventure.username,
            this.adventure.id,
            this.checkIns.current.id,
            { mode: "edit" }
          )
        );
      } else if (menuItem.id == 8) {
        // View
        this.$router.push(
          UrlBuilder.urlForDashboard(
            this.adventure.username,
            this.adventure.id,
            this.checkIns.current.id,
            { mode: "view" }
          )
        );
      } else if (menuItem.id == 9) {
        // Refresh
        this.$emit("refresh");
      } else if (menuItem.id == 10) {
        this.$emit("showstats");
        //   } else if (menuItem.id == 11) {
        //     this.checkIns.autoCorrectDates();
        //     console.log("Auto done");
      } else if (menuItem.id == 12) {
        this.showHelp = true;
      }
    },

    startAutoSave() {
      this.autoSave = new AutoSave({ checkIns: this.checkIns }, (message) => {
        //console.log("Saving: " + message);
        //this.saveMessage = message;
      });
      this.autoSave.start();
    },

    stopAutoSave() {
      if (this.autoSave) {
        this.autoSave.stop();
      }
    },

    saveAdventureTitle(title) {
      return updateAdventureTitle(this.adventure.id, title);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dashboardheader {
  background-color: #004aad;
  width: 100%;
  padding-top: 10px;
  box-sizing: border-box;
}

.headerTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.headerIcon {
  margin-right: 10px;
}

.headerTop input {
  flex-grow: 1;
}

.title {
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  flex-grow: 1;
  font-size: var(--ish-font-size-large);
  text-align: center;
}
/* Float the link section to the right */
.logo {
  margin-left: 20px;
  cursor: pointer;
  height: 50px;
}

.header-left {
  display: flex;
  height: 100%;
  width: 100%;
  margin-left: 10px;
  align-items: center;
  justify-content: flex-start;
}

.summaryMobile {
  background: white;
  display: none;
}
/* Add media queries for responsiveness - when the screen is 500px wide or less, stack the links on top of each other */
@media screen and (max-width: 500px) {
  .header .heading {
    float: none;
    display: block;
    text-align: left;
  }

  .header-left {
    justify-content: space-between;
  }

  .title {
    text-align: left;
  }

  .logo {
    height: 30px;
  }

  .summaryWeb {
    display: none;
  }
  .summaryMobile {
    display: none;
  }
}
</style>
