/**
 * Export event list.
 *
 * @author    Naotoshi Fujita
 * @copyright Naotoshi Fujita. All rights reserved.
 */

export const EVENTS = [
  'mounted',
  'updated',
  'move',
  'moved',
  'drag',
  'dragged',
  'visible',
  'hidden',
  'active',
  'inactive',
  'click',
  'arrows:mounted',
  'arrows:updated',
  'pagination:mounted',
  'pagination:updated',
  'navigation:mounted',
  'autoplay:play',
  'autoplay:pause',
  'autoplay:playing',
  'lazyload:loaded',
];