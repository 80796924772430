export { getLocationDetails };

/**
 * Returns { name: address } details for a given location or placeId
 * 
 * @param {} google 
 * @param {*} map 
 * @param {*} location 
 * @param {*} placeId 
 */
const getLocationDetails = function (google, map, location, placeId) {
  return new Promise(function (resolve) {
    if (placeId) {
      // Place Details search
      const service = new google.maps.places.PlacesService(map);
      service.getDetails({ placeId: placeId, fields: ['name', 'formatted_address'] }, (place, status) => {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          resolve({ name: place.name, address: place.formatted_address });
        } else {
          resolve({ name: null, address: null });
        }
      })
    } else {
      // Location based details 
      const service = new google.maps.Geocoder();
      service.geocode({ location: location }, (results, status) => {
        if (status === "OK") {
          resolve({ name: "", address: results[0].formatted_address });
        } else {
          resolve({ name: null, address: null });
        }
      });
    }
  })
};

