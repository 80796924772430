<template>
  <modal-dialog
    class="alert"
    :title="title"
    :actions="shareActions"
    @close="$emit('close')"
  >
    <div>
      <p>{{ openingMessage }}</p>
      <input ref="urlInput" class="urlInput" type="text" :value="selectedShare.url" />
      <input class="urlInputHidden" type="text" :value="selectedShare.url" ref="myInput" />
      <p>What would you like to share?</p>

      <template v-for="share in shareLinks" :key="share.title">
        <label class="checkBoxContainer">
          <span><b>{{ share.title ?? 'Here' }}</b><span> - {{ share.description }} </span>
          </span>
          <input type="radio" name="type" :value="share" v-model="selectedShare" />
          <span class="checkmark"></span>
        </label>
        
      </template>

      <div
        v-show="showPopup"
        :class="{ open: openPopup, close: closePopup }"
        class="popup"
      >
        copied
      </div>
    </div>
  </modal-dialog>
</template>

<script>
import { defineComponent } from "vue";
import ModalDialog from "./ModalDialog.vue";
import { analytics } from "../../api/firebaseInit";

// import { UrlBuilder } from "../../api/classes/UrlBuilder";
// import { CheckIn } from "../../api/classes/CheckIn";
import { Adventure } from "../../api/classes/Adventure";

import { mapState } from "vuex";
//import CheckInArray from "../../api/classes/CheckinArray";

export default defineComponent({
  name: "share",
  trackAsPageView: true,
  trackWithPageName: "Share",
  emits: ["close"],
  components: {
    ModalDialog,
  },
  props: {
    adventure: Adventure,
    /**
     * Array of { title, url, description }
     */
    shareLinks: {
      type: Array,
      default: function() {
        return [];
      }
    },
  },

  data() {
    return {
      //url: this.shareLinks.length > 0 ? this.shareLinks[0].url : "",
      openPopup: false,
      closePopup: false,
      selectedShare: this.shareLinks.length > 0 ? this.shareLinks[0] : null,
    };
  },

  computed: {
    ...mapState({
      user: "user",
      loggedIn: "loggedIn",
    }),
    isOwner() {
      return this.loggedIn && this.adventure.uid == this.user.uid;
    },
    openingMessage() {
      if (this.isOwner) {
        return "Share this link for others to explore your adventure.";
      } else {
        return `Share this link for others to explore '${this.adventure.name}'.`;
      }
    },
    title() {
      return "Share";
    },
    shareActions() {
      const vm = this;
      return [
        {
          isSecondary: true,
          title: "Close",
          handle: () => {
            vm.$emit("close");
          },
        },
        {
          isPrimary: true,
          title: "Copy Link",
          handle: () => {
            vm.handleCopy();
          },
        },
      ];
    },
  },

  mounted() {
    let defaultShareLink = this.shareLinks.find( s => s.default == true);
    if (defaultShareLink) {
      this.selectedShare = defaultShareLink;
    } 
  },

  methods: {
    showPopup(show) {
      this.openPopup = show;
      this.closePopup = !show;
    },
    selectText() {
      this.$refs.myInput.select();
      this.$refs.myInput.setSelectionRange(0, 99999); /* for mobile devices */
    },
    handleCopy() {
      analytics.logEvent("share", {
        method: "CopyLink",
        content_type: this.selectedShare.title,
        item_id: this.adventure.username,
        adventure_id: this.adventure.id,
      });
      navigator.clipboard.writeText(this.$refs.urlInput.value);
      const vm = this;
      this.showPopup(true);
      setTimeout(function () {
        vm.showPopup(false);
      }, 2000);
    },
  },
});
</script>

<style scoped>
.urlInput {
  -webkit-appearance: none;
  height: 30px;
  width: 100%;
  min-width: 300px;
  border-radius: 5px;
  background: var(--ish-lightgrey);
  cursor: text;
  text-overflow: ellipsis;
  box-sizing: border-box;
  border: 0px;
  padding: 15px;
  font-size: var(--ish-font-normal);
}

/* "Hide" the input control we'll be copying from
    Can't make it heoght/width 0 as text will be unselectable
 */
.urlInputHidden {
  opacity: 0;
  height: 0.1px;
  /* width: 0.1px; */
}

.popup {
  position: absolute;
  width: 200px;
  bottom: 20px;
  left: -150px;
  background: var(--ish-darkgrey);
  color: white;
  padding: 10px 20px;
  text-align: center;
  transition: left 500ms, opacity 500ms;
  opacity: 0;
}

.popup.close {
  left: -150px;
  opacity: 0;
}

.popup.open {
  left: 20px;
  opacity: 1;
}

.subText {
  font-size: var(--ish-font-size-small);
  padding-left: 35px;
  margin-top: -10px;
  margin-bottom: 20px;
  display: inline;
}
/* Customize the label (the container) */
.checkBoxContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 22px;
  cursor: pointer;
  font-size: 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
}

/* Hide the browser's default checkbox */
.checkBoxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #eee;
  border-radius: 12px;
}

.checkmark {
  display:flex;
  justify-content: center;
  align-items: center;
}

/* On mouse-over, add a grey background color */
.checkBoxContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkBoxContainer input:checked ~ .checkmark {
  background-color: var(--ish-blue);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkBoxContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkBoxContainer .checkmark:after {
  width: 2px;
  height: 2.5px;
  border: solid white;
  background: white;
  border-radius: 5px;
}

.smallText {
  font-size: 0.7em;
  padding-left: 35px; 
  padding-bottom: 20px;
}

@media screen and (max-width: 500px) {
  .urlInput {
    width: 100%;
    min-width: 0px;
  }
  .popup {
    width: 70px;
  }
}
</style>