import { db, storage } from "./firebaseInit.js";
import { COLLECTIONS } from "./firestoreConstants.js";
import { adventureConverter } from "./classes/Adventure";
import { getCheckIns } from "./firestoreCheckIn.js";

export function getAdventure(id) {
  return new Promise(function (resolve, reject) {
    db.collection(COLLECTIONS.ADVENTURES)
      .doc(id)
      .withConverter(adventureConverter)
      .get()
      .then(function (doc) {
        resolve(doc.data());
      }).catch(
        function (error) {
          reject("Error Reading Adventure " + error);
        }
      );
  });
}

/**
 * Retrieve all adventures for the given uid.
 * 
 * @param {String} uid 
 */
export function getAdventuresByUid(uid) {
  return new Promise(function (resolve, reject) {
    if (!uid) reject("uid missing")
    else {
      db.collection(COLLECTIONS.ADVENTURES)
        .where("uid", "==", uid) 
        
        .withConverter(adventureConverter)
        .get()
        .then(function (querySnapshot) {
          const result = [];
          querySnapshot.forEach(function (adventure) {
            result.push(adventure.data())
          })
          resolve(result);
        })
        .catch(function (error) {
          reject(error);
        })
    }
  });
}

/**
 * Get all the public adventures for the given username
 * @param {*} username 
 * @returns 
 */
export function getPublicAdventuresByUsername(username) {
  return new Promise(function (resolve, reject) {
    if (!username) reject("username missing")
    else {
      db.collection(COLLECTIONS.ADVENTURES)
        .where("username", "==", username)
        .where("public", "==", true)
        .withConverter(adventureConverter)
        .get()
        .then(function (querySnapshot) {
          const result = [];
          querySnapshot.forEach(function (adventure) {
            result.push(adventure.data())
          })
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        })
    }
  });
}

export function updateAdventure(adventure) {
  return new Promise(function (resolve, reject) {
    if (adventure.id) {    
      db.collection(COLLECTIONS.ADVENTURES)
        .doc(adventure.id)
        .withConverter(adventureConverter)
        .set(adventure, { merge: true })
        .then ( () => {
          resolve();
        })
        .catch( (error) => {
          reject(error);
        })
    } else {
      reject("Can't update adventure, no id");
    }
  });
}

export function updateAdventureTitle(id, title) {
  return new Promise(function (resolve, reject) {
    if (title.length == 0) reject("Title can't be empty")
    else if (title.length > 20) reject("Title too long")
    else if (id) {
      db.collection(COLLECTIONS.ADVENTURES)
        .doc(id)
        .update({
          name: title,
        })
        .then( () => {
          resolve();
        })
        .catch( (error) => {
          reject(error);
        })
      }
    })
}

export function updateAdventureVisibility(id, isPublic) {
  return new Promise(function (resolve, reject) {
    db.collection(COLLECTIONS.ADVENTURES)
      .doc(id)
      .update({
        public: isPublic,
      })
      .then( () => {
        resolve();
      })
      .catch( (error) => {
        reject(error);
      })
    })
}

export function addAdventure(adventure) {
  return new Promise(function (resolve, reject) {
    if (adventure.uid) {
      db.collection(COLLECTIONS.ADVENTURES)
        .withConverter(adventureConverter)
        .add(adventure, { merge: true })
        .then(function (docRef) {
          let adventureSaved = adventure;
          adventureSaved.id = docRef.id;
          resolve(adventureSaved);
        }).catch(function (error) {
          reject(error);
        });
    } else {
      reject("Adventure has no uid");
    }
  });
}

export function deleteAdventure(uid, id) {
  return new Promise(function (resolve, reject) {
    
    if (id) {

      // create a batch to handle all the delete
      let batch = db.batch();
      let imageStoragePaths = [];
      let deleteCounts = {
        adventure: 0,
        checkIns: 0,
        images: 0,
      }
      // CHECKINS
      // set up all the checkin delete operations
      let addCheckInDeletesToBatch = getCheckIns(uid, id).then( (checkIns) => {
        checkIns.forEach( (checkIn) => {

          // add to the batch transations
          deleteCounts.checkIns += 1;
          batch.delete(checkIn);  

          // record the images that need to be deleted too
          checkIn.images.forEach( (image) => {
            imageStoragePaths.push(image.storagePath);
          });
        })
      });

      // ADVENTURE
      // delete the adventure document itself
      let addAventureDeleteToBatch = getAdventure(id).then ( (adventure) => {
        deleteCounts.adventures = 1;
        batch.delete(adventure);
      });
      
      Promise.all([addCheckInDeletesToBatch, addAventureDeleteToBatch])
      
        .then( () => {
          // COMMIT THE checkin and adventure document deletes
          batch.commit().then( () => {

            // TODO: move this to a cloud function trigger on checkin deletes
            // delete the images from storage
            imageStoragePaths.forEach( (path) => {
              storage.ref(path).delete()
                .then( () => {
                  deleteCounts.images += 1;
                })
                .catch( (error) => {
                  reject("Failed to delete all images.");
                })
            })

            resolve(deleteCounts);

          }).catch( (error) => {
            reject("Failed to delete adventure and it's checkins");
          })
        })
        .catch ( (error) => {
          // either the checkin/adventure deletes failed or they worked and couldn't delete images
          reject(error);
        });
    } else {
      reject("Must supply the id of the adventure to delete.");
    }
  });
}

