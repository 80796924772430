<template>
  <modal-dialog title="Export" :actions="exportActions" @close="$emit('close')">
    <div>
      <p>Your data will be exported in CSV format.</p>
    </div>
  </modal-dialog>
</template>

<script>
import { defineComponent } from "vue";
import { downloadCSV } from "../../../api/csvExport.js";

import ModalDialog from "../../controls/ModalDialog.vue";
import CheckInArray from "../../../api/classes/CheckinArray";

export default defineComponent({
  name: "export",
  trackAsPageView: true,
  trackWithPageName: "Export",
  emits: ["close"],

  components: {
    ModalDialog,
  },

  props: {
    checkIns: CheckInArray,
  },

  data() {
    return {
      email: "",
      exportActions: [
        {
          isSecondary: true,
          title: "Cancel",
          handle: () => {
            this.$emit("close");
          },
        },
        {
          title: "Export",
          handle: (action) => {
            action.showSpinner = true;
            let vm = this;
            let data = {
              data: vm.checkIns.map((c) => ({ 
                Date: c.date, 
                Place: c.title,
                Address: c.address,
                Location: c.location.toString(),
                Distance: c.distanceWalked,
                Notes: c.notes,
                Accommodation: c.accommodation.value,
                RestDays: c.numberOfRestDays,
                ResupplyNotes: c.resupplyNotes,
              })),
              filename: "export.csv",
            };
            downloadCSV(data);
            action.showSpinner = false;
          },
        },
      ],
    };
  },
});
</script>

<style scoped>
</style>