<template>
  <GoogleMapMarker 
    :map="map" 
    :google="google" 
    :options="options"
    @click="handleClick">
  </GoogleMapMarker>
</template>

<script>
import { defineComponent } from "vue";
import GoogleMapMarker from "../maps/GoogleMapMarker"
import { constants } from "../../constants";
import { Location } from "../../api/classes/Location";

export default defineComponent({
  name: "CurrentLocationMarker",
  
  emits: ["click"],
  
  components: {
    GoogleMapMarker,
  },

  prop: {
    options: Object,
  },

  props: {
    google: Object,
    map: Object,
    location: Location,
  },

  computed: {
    options() {
      return {
        position: this.location.toGoogleMapLocation(),
        icon: {
          url: constants.MARKERS.CURRENT_LOCATION.default,
        },
        draggable: false,
      }
    }
  },

  methods: {
    handleClick() {
      this.$emit('click', this.location);
    },

  },

  render() {
    return null;
  },
});
</script>

