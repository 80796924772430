export { UrlBuilder }
import store from "../../store.js";

/**
 * Common representation of a geo-coordinate based location.
 */
class UrlBuilder {

  /**
   * Returns the url for the user's public home page
   * @param {*} username 
   */
  static urlForUserHome = function(username, options) {
    let includeRoot = (options && options.includeRoot) ?? false;

    let _username = username;
    if (!_username) {
      _username = store.state.user.username;
    }
    
    const url = `/${_username}`

    return (includeRoot ? window.location.origin : "") + url    
  } 

  /**
   * 
   * @param {*} username 
   * @param {*} adventureId 
   * @param {*} checkInId 
   * @param {*} options { mode: edit/view, latest: true/false, start: true/false, lat:, lng:, zoom:, includeRoot: true/false}
   */
  static urlForDashboard = function(username, adventureId, checkInId, options) {
    
    if (!options) {
      options = {}
    }
    let includeRoot = options.includeRoot ?? false;
    let mode = (options.mode == 'edit') ? "edit" : "view";
    
    // Query string parameters
    let queryStringParams = [];
    
    // goto
    if (options.latest) {
      queryStringParams.push({goto: 'latest'});
    } else if (options.start) {
      queryStringParams.push({goto: 'start'});
    }

    // lat, lng, zoom
    if (options.lat) {
      queryStringParams.push({lat: encodeURIComponent(options.lat)});
    }
    if (options.lng) {
      queryStringParams.push({lng: encodeURIComponent(options.lng)});
    }
    if (options.zoom) {
      queryStringParams.push({zoom: encodeURIComponent(options.zoom)});
    }
    if (options.isShare) {
      queryStringParams.push({ share: true });
    }
    
    let url = `/${username}/${mode}/${adventureId}`;

    // add checkin id, if we haven't specified start or latest
    if ((!options.start && !options.latest)) {
      if (checkInId) {
        url += `/${checkInId}`;
      } else {
        // should we error ?
      }
    }

    // combine query string parameters into a single query string
    let queryString = "";
    if (queryStringParams && queryStringParams.length > 0) {
      queryString = "?" + queryStringParams.map( (param) => {
        let name = Object.keys(param)[0];
        let value = param[name];
        return `${name}=${value}`;
      }).join('&');
    }
    
    return (includeRoot ? window.location.origin : "") + url + queryString;
  }

  // static urlForEditAdventure = function(adventureId, goto) {
  //   return UrlBuilder.urlForDashboard('tokes', adventureId, null, { mode: 'edit', latest: true, start: false });
  // }

  // static urlForViewAdventure = function (adventureId) {
  //   return UrlBuilder.urlForDashboard(adventureId, null, { mode: 'view', latest: true, start: false });
  // }

  // static urlForEditCheckIn = function(adventureId, checkInId) {
  //   return UrlBuilder.urlForDashboard(adventureId, checkInId, { mode: 'edit', latest: true, start: false });
  // }
  
  // static urlForViewCheckIn = function (adventureId, checkInId) {
  //   return UrlBuilder.urlForDashboard(adventureId, checkInId, { mode: 'view', latest: true, start: false });
  // }

  static isDashboardUrlViewMode = function(path) {
    return path.indexOf('view') > 0;
  }
}