import { UrlBuilder } from "./UrlBuilder";
export {ShareLinks}

class ShareLinks {
    constructor(adventure, checkIns, zoomLevel, centre) {
        this.adventure = adventure;
        this.checkIns = checkIns;
        this.zoomLevel = zoomLevel;
        this.DEFAULT_ZOOM = 5;
        this.centre = centre;
    }

    asArray() {
      let result = [this.dashboard()]
      let first = this.first()
      if (first) { result.push(first) }
      let current = this.current() 
      if (current) { result.push({ ...this.current(), default: true }) }
      let recent = this.recent()
      if (recent) { result.push(recent) }

      if (result.length === 1) {
        result[0].default = true
      }
      return result
    }

    dashboard() {
      return {
        title: 'Dashboard',
        url: UrlBuilder.urlForUserHome(this.adventure.username, {includeRoot: true}),
        description: `show ${this.adventure.username}'s homepage and all adventures`
      }
    }

    current() {
      if (!this.checkIns || this.checkIns.length === 0) {
        return null
      }
      let checkIn = this.checkIns.current;
      let location = this.centre ?? checkIn.location;
      return { 
          title: "Current",
          url: UrlBuilder.urlForDashboard(
              this.adventure.username, 
              this.adventure.id, 
              checkIn.id, 
              { lat: location.lat, 
                lng: location.lng, 
                zoom: this.zoomLevel ?? this.DEFAULT_ZOOM, 
                includeRoot: true,
                isShare: true}),
          description: 'show the current map view'
      }
    }

    recent() {
      if (!this.checkIns || this.checkIns.length === 0) {
        return null
      }
      let checkIn = this.checkIns.mostRecent();
      return { 
          title: "Latest",
          url: UrlBuilder.urlForDashboard(
              this.adventure.username, 
              this.adventure.id, checkIn.id, 
            { latest: true, zoom: this.DEFAULT_ZOOM, includeRoot: true, isShare: true}),
          description: 'show the latest check in' 
      }
    }

    first() {
        if (!this.checkIns || this.checkIns.length === 0) {
          return null
        }
        let checkIn = this.checkIns.first();  
        return { 
            title: "Start",
            url: UrlBuilder.urlForDashboard(
                this.adventure.username, 
                this.adventure.id, checkIn.id, 
                { start: true,  zoom: this.DEFAULT_ZOOM, includeRoot: true, isShare: true }),
            description: `show the first check in, ${checkIn.title}`, 
        }
    }

    daysOnTrailDescription(checkIn) {
        let dayDescription = this.checkIns.statistics[checkIn.id].daysOnTrailDescription;
        if (checkIn.title) {
            dayDescription += " - " + checkIn.title;
        }
        return dayDescription;
    }
}