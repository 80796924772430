import { firebase } from "../firebaseInit.js";
const { DateTime } = require("luxon");

export { User, UserPrivate, userPublicConverter, userPrivateConverter };

class User {
  constructor(config) {
    // public properties that even anon users will be able to view
    this.displayName = config.displayName;
    this.username = config.username;
    // private property will be a UserPrivate instance added in firestoreUser.getUser for authenticated users only
    this.private = null; 
  }
}

class UserPrivate {
  constructor(config) {
    this.uid = config.uid;
    this.email = config.email;
    this.lastLoggedInDate = config.lastLoggedInDate;
    this.lastAdventureId = config.lastAdventureId;
    this.following = config.following;
  }
}

/**
 * FirestoreDataConverter implementation for User instances
 */
var userPublicConverter = {
  toFirestore: function (user) {
    const result = {};
    if (user.username) { result.username = user.username.toLowerCase(); }
    if (user.displayName) { result.displayName = user.displayName }
    return result;
  },

  fromFirestore: function (snapshot, options) {
    const data = snapshot.data(options);
    const config = {
      username: data.username,
      displayName: data.displayName,
    }

    return new User(config);
  }
};

/**
 * FirestoreDataConverter implementation for UserPrivate instances
 */
 var userPrivateConverter = {
  toFirestore: function (privateData) {
    const result = {};
    if (privateData.email) { result.email = privateData.email; }
    if (privateData.lastLoggedInDate) { result.lastLoggedInDate = firebase.firestore.Timestamp.fromDate(privateData.lastLoggedInDate.toJSDate()); }
    if (privateData.lastAdventureId) { result.lastAdventureId = privateData.lastAdventureId }
    if (privateData.following) { result.following = privateData.following }
    return result;
  },

  fromFirestore: function (snapshot, options) {
    const data = snapshot.data(options);
 
    const config = {
      uid: snapshot.id,
      email: data.email,
      lastLoggedInDate: data.lastLoggedInDate ? DateTime.fromJSDate(data.lastLoggedInDate.toDate()) : DateTime.local(),
      lastAdventureId: data.lastAdventureId,
      following: data.following ?? [],
    }

    return new UserPrivate(config);
  }
};
