
export { MapLayer, mapLayerConverter };

import { firebase } from "../firebaseInit";
const { DateTime } = require("luxon");

class MapLayer {
  constructor(config) {
    this.id = config.id;
    this.date = config.date;
    this.name = config.name;
    this.trailId = config.trailId;
    this.type = config.type;
    this.storagePath = config.storagePath;
    this.version = config.version;

    // the url is derived from the storagePath and set within firestoreMapLayer.getLayer method
    this.url = null;
  }
  toString() {
    return `${this.id}, ${this.name}, ${this.storagePath}`;
  }
}

// 
/**
 * FirestoreDataConverter implementation for MapLayer instances
 */
var mapLayerConverter = {
  toFirestore: function (mapLayer) {
    
    const result = {};
    if (mapLayer.date) { result.date = firebase.firestore.Timestamp.fromDate(mapLayer.date.toJSDate()) }
    if (mapLayer.name) { result.name = mapLayer.name }
    if (mapLayer.trailId) { result.trailId = mapLayer.trailId }
    if (mapLayer.type) { result.type = mapLayer.type }
    if (mapLayer.storagePath) { result.storagePath = mapLayer.storagePath }
    if (mapLayer.version) { result.version = mapLayer.version }
    return result;
  },

  fromFirestore: function (snapshot, options) {
    
    const data = snapshot.data(options);
    return new MapLayer(
      {
        id: snapshot.id, 
        date: data.date ? DateTime.fromJSDate(data.date.toDate()) : DateTime.local(),
        name: data.name, 
        trailId: data.name,
        type: data.type,
        storagePath: data.storagePath,
        version: data.version,
      })
  }
};
