<template>
  <AnonPageWrapper>
    <div class="forgotPassword">
      <p class="onDdark">
        Let us know what email you use to sign in to iStayedHere and we'll help
        you reset your password.
      </p>
      <TextInput v-model="email" :lightOnDark="true" label="Email"></TextInput>

      <BaseButton class="passwordResetButton" :buttonType="buttonType"
        >Send reset email</BaseButton
      >
    </div>
  </AnonPageWrapper>
</template>

<script>
import { defineComponent } from "vue";
import TextInput from "../controls/TextInput";
import BaseButton from "../controls/BaseButton";
import AnonPageWrapper from "../controls/AnonPageWrapper";

import { constants } from "../../constants";

export default defineComponent({
  name: "ForgotPassword",

  components: {
    TextInput,
    BaseButton,
    AnonPageWrapper,
  },

  data() {
    return {
      email: "",
      buttonType: constants.APPBUTTONTYPE.PRIMARYONDARK,
    };
  },
});
</script>

<style scoped>
p {
  margin-bottom: 40px;
}

.forgotPassword {
  height: 100%;
  background-color: transparent;
}

.passwordResetButton {
  margin-top: 40px;
  width: 100%;
}
</style>
