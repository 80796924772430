<template>
    <vue3-chart-js v-if="config.type"
      :id="config.id"
      ref="chartRef"
      :type="config.type"
      :data="config.data"
      :options="config.options"
    ></vue3-chart-js>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
import CheckInArray from '../../../api/classes/CheckinArray'
import { getAccommodationData } from "../../../api/graphServices";

export default defineComponent({
  name: "accommodation-chart",
  
  components: {
    Vue3ChartJs,
  },

  props: {
    checkIns: CheckInArray,
  },

  setup(props) {
    const config = ref({});

    onMounted(async () => {

      getAccommodationData(props.checkIns).then ( (result) => {
        config.value = {
          id: 'accommodationChart',
          type: 'pie',
          data: {
            labels: result.labels,
            datasets: [
              {
                backgroundColor: ["#e7e7e7", "#004AAD", "#32a5d3", "#7a7a7a", "#cf0707", "#ff914d"],
                data: result.data,
              }
            ],
          },
          options: {
            maintainAspectRatio: false,
            plugins: {
                title: {
                    display: true,
                    text: 'ACCOMMODATION',
                    color: 'rgba(74, 74, 74, 1)',
                    font: {
                        size: 16,
                        weight: 350,
                    }
                },
                legend: {
                  display: true,
                  position: 'bottom',
                },
                datalabels: {
                  formatter: (value, ctx) => {

                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                      sum += data;
                    });
                    let percentage = (value * 100 / sum).toFixed(2) + "%";
                    return percentage;


                  },
                  color: '#fff',
                },
              }
            }
          }
        
      })
      .catch( (error) => {
        console.log(error);
      })
    }); 
    return {
      config,
    }
  }

})
</script>

<style scoped>

</style>