export class CustomLink {
    
    constructor(title, url, id) {
        this.id = id;
        this.title = title;
        this.url = url;
    }
}

var customLinkConverter = {
    toFirestore: function (customLink) {
      return {
        title: customLink.title,
        url: customLink.url,
      };
    },
  
    fromFirestore: function (snapshot, options) {
      const data = snapshot.data(options);
      return new CustomLink(data.title, data.url, snapshot.id);
    }
  };