<template>
    <vue3-chart-js v-if="config.type"
      :id="config.id"
      ref="chartRef"
      :type="config.type"
      :data="config.data"
      :options="config.options"
    ></vue3-chart-js>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import CheckInArray from '../../../api/classes/CheckinArray'
import { getDistanceData } from "../../../api/graphServices";
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
import { Chart } from 'chart.js'
import zoomPlugin from 'chartjs-plugin-zoom'

export default defineComponent({
  name: "cummulative-distance-chart",
  
  emits: ['selectedCheckIn'],
  
  components: {
    Vue3ChartJs,
  },

  props: {
    checkIns: CheckInArray,
    maximumVisibleBars: {
      type: Number,
      default: 10,
    }
  },

  setup(props) {
    // reactive properties available on instance
    const config = ref({});
    const chartRef = ref(null);
    
    // variables local to setup
    const mostRecent = props.checkIns.mostRecent();
    const maxDay = mostRecent ? props.checkIns.statistics[mostRecent.id].daysOnTrail : 10;
    const distanceTravelledSoFar = mostRecent ? props.checkIns.statistics[mostRecent.id].distanceTravelledSoFar + 20 : 100;
    const dayOffset = 4;

    // Function to zoom the chart to show only the latest checkins
    const zoomToLatest = function(chart, showDays) {
      if (maxDay < showDays) {
        return;
      }
      let zoomLevel = 2 - (showDays / (maxDay - dayOffset));
      zoomLevel = (zoomLevel < 1) ? 1 : zoomLevel ;
      chart.resetZoom();
      chart.zoom(zoomLevel, false);
      chart.pan({x: -5000}, undefined, 'default');
    };
    
    // Once mounted we want to set the data and configuration options for the chart
    onMounted(async () => {
      const zoomOptions = {
        limits: {
          y: { min: 0, max: distanceTravelledSoFar },
          x: { min: 0, max: maxDay + dayOffset },
        },
        pan: {
          enabled: true,
          mode: 'x',
        },
        zoom: {
          wheel: {
            enabled: false,
          },
          pinch: {
            enabled: false
          },
          mode: 'x',
        }
      };
      
      getDistanceData(props.checkIns)
        .then ( (result) => {
          config.value = {
            id: 'cummulativeDistanceChart',
            type: 'scatter',
            data: {
              labels: result.labels,
              datasets: [
                {
                  showLine: true,
                  backgroundColor: '#004aad',
                  data: result.cummulativeDistanceWalked,
                  pointBackgroundColor: function(context) {
                    // color zero days with orange
                    var index = context.dataIndex;
                    if (index > 0) {
                      var lastValue = context.dataset.data[index - 1];
                      var value = context.dataset.data[index];
                      return (lastValue == value) ? 'orange' : '#004aad';
                    } else {
                      return '#004aad';
                    }
                  }
                },
              ],
            },
            options: {
              maintainAspectRatio: false,
              interaction: {
                intersect: false,
                mode: 'index',
              },  
              plugins: {
                zoom: zoomOptions,
                title: {
                    display: true,
                    text: 'TOTAL DISTANCE',
                    color: 'rgba(74, 74, 74, 1)',
                    font: {
                        size: 16,
                        weight: 350,
                    }
                },
                legend: {
                  display: false,
                },
                tooltip: {
                  
                  callbacks: {
                      footer: function(context) {
                        let chartContext = context[0];
                        var index = chartContext.dataIndex;
                        return result.footerText[index];
                      },
                      label: function(context) {
                        return context.formattedValue + ' km';
                      },
                      title: function(context) {
                        let chartContext = context[0];
                        return 'Day ' + chartContext.label;
                      }
                  }
                },  
              },
              scales: {
                  x: {
                    type: "linear",
                    ticks: {
                      callback: (value, index, values) => Math.floor(value) === value ? value : null
                    },
                    min: 0, 
                    max: maxDay + dayOffset,
                    title: {
                      display: true,
                      text: "day",
                    },
                    offset: false,
                    grid: {
                      offset: false,
                    },
                  },
                  y: {
                    min: 0,
                    max: distanceTravelledSoFar,
                    title: {
                      display: true,
                      text: "km"
                    }
                  }

                }
              }
          }
        })
        .catch( (error) => {
          console.log(error);
        })
        .finally ( () => {
          // Now zoom in to the latest 10 checkins
          zoomToLatest(chartRef.value.chartJSState.chart, props.maximumVisibleBars);
        })
    }); 

    // expose the properties we're using in the template for the chart
    return {
      config,
      chartRef,
      zoomToLatest,
    }
  },

  created() {
    Chart.register([zoomPlugin])
  },

  watch: {
    maximumVisibleBars() {
      this.zoomToLatest(this.chartRef.chartJSState.chart, this.maximumVisibleBars);
    }
  },

})
</script>

<style scoped>

</style>