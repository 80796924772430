import { createStore } from "vuex";
import { getUser } from "./api/firestoreUser";
import ShortcutManager from "./api/classes/ShortcutManager";

export default createStore({
  state() {
    return {
      loggedIn: false,
      user: {},
      shortcutManager: new ShortcutManager(),
    }
  },

  getters: {
    user(state) {
      return state.user
    }
  },
  mutations: {
    ADD_SHORTCUT_LISTENER(state, options) {
      state.shortcutManager.addListener(
        options.name, 
        options.callback, 
        options.listenTo, 
        options.block);
    },
    REMOVE_SHORTCUT_LISTENER(state, name) {
      state.shortcutManager.removeListener(name);
    },
    CLEAR_USER_STATE(state) {
      // remove all state information about a user
      state.loggedIn = false;
      state.user = {};
    },
    SET_LOGGED_IN(state, value) {
      state.loggedIn = value;
    },
    SET_USER_AUTH_STATE(state, authUser) {
      // authUser comes from firebase authentication process
      if (!state.user) {
        state.user = {};
      }
      state.user.isAdmin = authUser ? authUser.isAdmin : false;
      state.user.uid = authUser ? authUser.uid : null;
      state.user.email = authUser ? authUser.email : null;      
      state.user.displayName = authUser ? authUser.displayName : null;      
    },
    SET_USER_DATA(state, data) {
      // data enables us to further augment the user record with data that is not returned from auth process
      // but is needed throughout app. 
      if (data.username) { state.user.username = data.username; }
      if (data.displayName) { state.user.displayName = data.displayName; }
      if (data.lastAdventureId) { state.user.lastAdventureId = data.lastAdventureId; }
      if (data.following) { state.user.following = data.following; } 
    }
  },
  actions: {
    /**
     * Updates state with the currently logged in user
     * @param {*} context provide easy access to commit method of state 
     * @param {*} user authUser as returned from firebase authentication
     * @returns 
     */
    updateAuthUser({ commit }, user) {
      
      return new Promise( (resolve, reject) => {
        // to be sure we don't mix states between user changes, clear everything first
        commit("CLEAR_USER_STATE");

        // if there is a user, populate state with new user data
        if (user) {

          // mark as logged in
          commit("SET_LOGGED_IN", true);
          
          // persist authUser data from the new user
          user.getIdTokenResult().then( (result) => {
            commit("SET_USER_AUTH_STATE", {
              isAdmin: result.claims.admin ?? false,
              email: user.email,
              uid: user.uid,
              displayName: user.displayName,
            });

            // get the other user data from the database
            getUser(user.uid, true)
              .then( (dbUser) => {
                  
                commit("SET_USER_DATA", {
                  username: dbUser.username,
                  displayName: dbUser.displayName,
                  lastAdventureId: dbUser.private.lastAdventureId,
                  following: dbUser.private.following,
                })
                resolve();
              }).catch( (error) => {
                // probably happens because the user doesn't yet exist in the firestore
                console.log(error);
                resolve();
              })
          })
          
          

        } else {
          // Anonomous user, just return
          resolve();
        }
      })

    }
  }
});