
<template>
  <GoogleMarkerWithLabel 
    :map="map" 
    :google="google" 
    :options="markerOptions">
  </GoogleMarkerWithLabel>
</template>

<script>
import { defineComponent } from "vue";

import GoogleMarkerWithLabel from "./GoogleMarkerWithLabel.vue";
import { Location } from "../../api/classes/Location";

export default defineComponent({
  name: "DistanceMarker",

  components: {
    GoogleMarkerWithLabel,
  },

  // data() {
  //   return {
  //     className: String,
  //   };
  // },

  props: {
    google: Object,
    map: Object,
    location: Location,
    isMajor: Boolean,
    showLabel: Boolean,
    name: String,
    mapTypeId: String,
  },

  computed: {
    className() {
      return this.getLabelClassName();
    },
    markerOptions() {
      return {
        position: this.location.toGoogleMapLocation(),
        icon: {
          path: this.google.maps.SymbolPath.CIRCLE,
          scale: this.isMajor ? 2 : 1,
          fillColor: "#434343",
          fillOpacity: 1,
          strokeColor: "#434343",
        },
        labelContent: this.showLabel ? this.name : "",
        map: this.map,
        draggable: false,
        labelAnchor: { x: 5, y: 5 },
        labelClass: this.getLabelClassName(),
      };
    },
  },

  // mounted() {
  //   const vm = this;
  //   this.google.maps.event.addListener(this.map, "maptypeid_changed",
  //     function () {
  //       vm.className = vm.getLabelClassName();
  //     }
  //   );
  // },

  methods: {
    getLabelClassName() {
      return this.map.getMapTypeId() + "DistanceLabel";
    }
  },

  /**
   * Watch for properties that might change after the component is created
   */
  watch: {
    /**
     * Make sure we hide/show the labels when property changes
     */
    showLabel: function (show) {
      this.markerOptions.labelContent = show ? this.name : "";
    },
  },

  render() {
    return null;
  },
});
</script>

<style>
.hybridDistanceLabel,
.satelliteDistanceLabel {
  color: white;
  font-size: 10px;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.roadmapDistanceLabel,
.terrainDistanceLabel,
.OSMDistanceLabel {
  color: #434343;
  font-size: 10px;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}
</style>
