<script>
import { defineComponent } from "vue";
import { Location } from "../../api/classes/Location";

export default defineComponent({
  name: "GoogleMapMarker",

  emits: ["dragend", "click"],

  data() {
    return {
      marker: Object,
    };
  },

  props: {
    google: Object,
    map: Object,
    options: Object
  },

  mounted() {
    this.marker = new this.google.maps.Marker({
      map:this.map, 
      ...this.options
      } );
    this.attachListeners();
  },

  /**
   * We only need to watch for things that might change after the component has mounted
  */
  watch: { 
    options() {
      // if icon changed...
      let icon = this.marker.getIcon();
      if (icon.url != this.options.icon.url) {
        icon.url = this.options.icon.url;
        this.marker.setIcon(icon);
      }
      
      // if label changed...
      if (this.marker.getLabel() != this.options.label.text) {
        this.marker.setLabel(this.options.label);        
      }

      // if draggable state changed...
      if (this.marker.getDraggable != this.options.draggable)
      {
        this.marker.setDraggable = this.options.draggable;
      }
    }
  },

  methods: {
    attachListeners() {
      let vm = this;
      this.marker.addListener("dragend", (e) => {
        vm.$emit("dragend", Location.fromGoogleMapLocation(e.latLng));
      });

      this.marker.addListener("click", () => {
        vm.$emit("click");
      });
    },
  },

  /**
   * Remove the marker from the map. Note for some reason the
   * setMap(null) method is only removing the label not the marker,
   * so we additionally set it's visibility.
   */
  beforeUnmount() {
    this.marker.setMap(null);
    this.marker.setVisible(false);
    this.marker = null;
  },


  render() {
    return null;
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
