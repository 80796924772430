<template>
  <vue3-chart-js
    v-if="config.type"
    :id="config.id"
    ref="chartRef"
    :type="config.type"
    :data="config.data"
    :options="config.options"
  ></vue3-chart-js>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import CheckInArray from "../../../api/classes/CheckinArray";
import { getDistanceData } from "../../../api/graphServices";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";

import { Chart } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
// import BaseButton from "../BaseButton.vue";

export default defineComponent({
  name: "distance-chart",

  emits: ["selectedCheckIn"],

  components: {
    Vue3ChartJs,
    // BaseButton,
  },

  props: {
    checkIns: CheckInArray,
    maximumVisibleBars: {
      type: Number,
      default: 10,
    },
  },

  setup(props) {
    const chartRef = ref(null);
    const config = ref({});

    // local setup variables
    let mostRecent = props.checkIns.mostRecent();
    let maxDay = mostRecent
      ? props.checkIns.statistics[mostRecent.id].daysOnTrail
      : 10;

    // the number of empty days to add to the end of the series
    const dayOffset = 4;

    const zoomOptions = {
      limits: {
        y: { min: 0, max: 100 },
        x: { min: 0, max: maxDay + dayOffset },
      },
      pan: {
        enabled: true,
        mode: "x",
      },
      zoom: {
        wheel: {
          enabled: false,
        },
        pinch: {
          enabled: false,
        },
        drag: {
          enabled: false,
        },
        mode: "x",
      },
    };

    const zoomToLatest = function (chart, showDays) {
      if (maxDay < showDays) {
        return;
      }
      let zoomLevel = 2 - showDays / (maxDay - dayOffset);
      zoomLevel = zoomLevel < 1 ? 1 : zoomLevel;
      chart.resetZoom();
      chart.zoom(zoomLevel, false);
      chart.pan({ x: -5000 }, undefined, "default");
    };

    onMounted(async () => {
      getDistanceData(props.checkIns)
        .then((result) => {
          config.value = {
            id: "distanceChart",
            type: "bar",
            data: {
              labels: result.labels,
              datasets: [
                {
                  label: "daily distances",
                  backgroundColor: "#004aad",
                  data: result.data,
                  order: 100,
                  legend: {
                    display: true,
                  },
                },
                {
                  type: "line",
                  label: "Average",
                  borderColor: "#ff914d",
                  borderWidth: 2,
                  data: result.averageDailyWalkingDistance,
                },
                {
                  type: "line",
                  label: "Raw Average",
                  borderColor: "#32a5d3",
                  borderWidth: 2,
                  data: result.rawAverageDailyDistance,
                },
              ],
            },
            options: {
              maintainAspectRatio: false,
              interaction: {
                intersect: false,
                mode: "index",
              },
              plugins: {
                zoom: zoomOptions,
                title: {
                  display: true,
                  text: "DAILY DISTANCES",
                  color: "rgba(74, 74, 74, 1)",
                  font: {
                    size: 16,
                    weight: 350,
                  },
                },
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    footer: function (context) {
                      let chartContext = context[0];
                      var index = chartContext.dataIndex;
                      return result.footerText[index];
                    },
                    label: function (context) {
                      let label = context.formattedValue + " km";
                      if (context.datasetIndex == 2) {
                        label += " Raw Average";
                      } else if (context.datasetIndex == 1) {
                        label += " Daily Average";
                      }
                      return label;
                    },
                    title: function (context) {
                      let chartContext = context[0];
                      return "Day " + chartContext.label;
                    },
                  },
                },
              },
              scales: {
                x: {
                  type: "linear",
                  ticks: {
                    callback: (value, index, values) =>
                      Math.floor(value) === value ? value : null,
                  },
                  min: 0,
                  max: maxDay + dayOffset,
                  title: {
                    display: true,
                    text: "day",
                  },
                  offset: false,
                  grid: {
                    offset: false,
                  },
                },
                y: {
                  min: 0,
                  max: mostRecent
                    ? Math.max(...props.checkIns.map((c) => c.distanceWalked)) +
                      10
                    : 10,
                  title: {
                    display: true,
                    text: "km",
                  },
                },
              },
            },
          };
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          const chart = chartRef.value.chartJSState.chart;
          zoomToLatest(chart, props.maximumVisibleBars);
        });
    });

    return {
      config,
      chartRef,
      zoomToLatest,
    };
  },

  created() {
    Chart.register([zoomPlugin]);
  },

  watch: {
    maximumVisibleBars() {
      this.zoomToLatest(
        this.chartRef.chartJSState.chart,
        this.maximumVisibleBars
      );
    },
  },
});
</script>

<style scoped>
</style>