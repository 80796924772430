<script>
import { defineComponent } from "vue";
import ComponentLoadedEvent from "../../api/classes/ComponentLoadedEvent"; 

export default defineComponent({
  name: "GoogleMapLayer",

  emits: ['loaded', "load_failed"],

  props: {
    google: Object,
    map: Object,
    layer: Object,
  },

  data() {
    return {
      kmlLayer: this.google.maps.KmlLayer,
    }
    
  },

  mounted() {
    this.kmlLayer = new this.google.maps.KmlLayer(this.layer.url, {
      suppressInfoWindows: false,
      preserveViewport: true,
      map: this.map,
    });

    this.attachListeners();
  },

  methods: {
    attachListeners() {
      const vm = this;

      // 
      this.google.maps.event.addListener(this.kmlLayer, "status_changed", () => {
        const status = this.kmlLayer.getStatus();
        if (status == this.google.maps.KmlLayerStatus.OK ) {
          vm.$emit("loaded", new ComponentLoadedEvent(this.layer.name, `${this.layer.name} loaded`));
        } else {
          vm.$emit("load_failed", new ComponentLoadedEvent(this.layer.name, `${this.layer.name} failed to loaded with error ${status}`));
        }
      });
    }
  },

  render() {
    return null;
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
