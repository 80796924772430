/**
 * Returns the average of the array values, excluding anything where the qualifier evaluates to false
 * @param {*} array 
 * @param {*} qualifier 
 * @returns 
 */
export function average(array, qualifier) {
  var sum = 0, count = 0, val;
  for (var i in array) {
    val = array[i];
    if (!qualifier || qualifier(val)) {
      sum += val;
      count++;
    }
  }
  return sum / count;
}

/**
 * Returns an array of the same length as the paramter array, using a moving average of size equal to count.
 * @param {*} array 
 * @param {*} count 
 * @param {*} qualifier 
 * @returns 
 */
export function movingAverage(array, count, qualifier) {
  var result = [], val;

  // pad beginning of result with null values
  for (var i = 0; i < count - 1; i++) 
    result.push(null);

  // calculate average for each subarray and add to result
  for (var j = 0, len = array.length - count; j <= len; j++) {

    val = average(array.slice(j, j + count), qualifier);
    if (isNaN(val))
      result.push(null);
    else
      result.push(val);
    }

  return result;
}

/**
 * Returns the running average as an array of the same size as the original
 * @param {} array 
 * @param {*} qualifier 
 */
export function runningAverage(array, qualifier) {
  var result = [], val;
  for (var i=1; i<array.length+1; i++) {
    val = average(array.slice(0, i), qualifier);
    if (isNaN(val))
      result.push(null);
    else
      result.push(val);
  }
  return result;
}

/**
 * Returns an array with running totals
 * @param {*} array 
 * @returns 
 */
export function runningTotal(array) {
  var result = [], val;
  var total = 0;
  for (var i = 0; i < array.length; i++) {
    total += array[i];
    result.push(total);
  }
  return result;
}