<template>
    <div class="imageViewer" :class="{ fullscreen: fullScreen }">
        <splide
            v-if="imagesInternal.length > 0"
            ref="primary"
            :options="options"
            :slides="imagesInternal"
        >
            <splide-slide
                v-for="image in imagesInternal"
                :key="image.storageUrl"
            >
                <img :src="image.storageUrl" :alt="image.name" />
                <div
                    v-if="!readOnly || (readOnly && image.caption)"
                    class="caption"
                >
                    <input
                        type="text"
                        @input="$emit('update:images', imagesInternal)"
                        v-model="image.caption"
                        placeholder="Caption (optional)"
                    />
                </div>
            </splide-slide>
        </splide>
        <div
            v-if="imagesInternal.length == 0 && !readOnly"
            class="imagePlaceholder"
        >
            <div class="addImage" @click="$refs.fileuploader.browseFile()">
                Add image
            </div>
        </div>
        <div class="controlStrip" v-if="!readOnly">
            <file-uploader
                ref="fileuploader"
                class="control"
                :uploadToPath="uploadPath"
                @uploaded="handleUploaded"
            ></file-uploader>

            <icon
                class="control"
                v-if="!readOnly && imagesInternal.length > 0"
                :options="iconOptions"
                @click="showDeleteAlert = true"
                >delete</icon
            >
        </div>

        <modal-dialog
            v-if="showDeleteAlert"
            title="Delete Image"
            :actions="deleteActions"
            @close="showDeleteAlert = false"
        >
            <p>You sure you want to delete this image?</p>
        </modal-dialog>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { constants } from "../../constants";
import { storage } from "../../api/firebaseInit";
import { mapState } from "vuex";

import Splide from "../controls/Splide";
import SplideSlide from "../controls/SplideSlide";
import Icon from "../controls/Icon";
import FileUploader from "../controls/FileUploader";
import ModalDialog from "./ModalDialog.vue";
// import BaseInput from "./BaseInput.vue";

export default defineComponent({
    name: "ImageViewer",

    components: {
        Splide,
        SplideSlide,
        Icon,
        FileUploader,
        ModalDialog,
        // BaseInput,
    },

    props: {
        fullScreen: {
            type: Boolean,
            default: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        adventureId: String,
        checkInId: String,
        images: {
            type: Object,
            default: null,
        },
    },

    computed: {
        ...mapState({
            user: "user",
        }),
        uploadPath() {
            return this.getImagePath();
        },
        iconOptions() {
            return constants.ICON_OPTIONS.ON_WHITE_ACTIONABLE;
        },
        captionInputOptions() {
            let vm = this;
            return {
                placeholder: "Caption (optional)",
            };
        },
    },

    watch: {
        images() {
            this.bind();
        },
        fullScreen() {
            let options = { ...this.options };
            options.height = this.fullScreen ? "100%" : 200;
            this.options = options;
        },
    },

    data() {
        return {
            showDeleteAlert: false,
            options: {
                rewind: true,
                gap: "0rem",
                cover: true,
                height: 300,
                width: "100%",
                keyboard: false,
            },
            imagesInternal: Object,
            splide: Object,
            deleteActions: [
                {
                    id: 0,
                    title: "Delete",
                    isDestructive: true,
                    handle: (action) => {
                        let vm = this;
                        action.showSpinner = true;
                        this.delete()
                            .then( () => {
                                action.showSpinner = false;
                            })
                            .catch( err => {
                                //TODO error logging
                                console.log(err);
                                vm.showSpinner = false;
                            })
                            .finally( () => {
                                this.showDeleteAlert = false;
                            })
                    }
                },
                {
                    id: 1,
                    title: "Cancel",
                    isSecondary: true,
                    handle: () => {
                        this.showDeleteAlert = false;
                    },
                },
            ],
        };
    },

    created() {
        this.bind();
    },

    methods: {
        bind() {
            this.imagesInternal = this.images;
        },

        getImagePath() {
            const userId = this.user.uid;

            // get a unique image id
            let imageId = "1";
            if (this.images.length > 0) {
                const ids = this.images.map((image) => {
                    // assume the characters following the final / are the image number
                    const splitName = image.storagePath.split("/");
                    return splitName[splitName.length - 1];
                });
                imageId = Math.max(...ids) + 1;
            }
            const path = `images/${userId}/${this.adventureId}/${this.checkInId}/${imageId}`;

            return path;
        },

        // handleDelete() {
        //   this.showDeleteAlert = true;
        // },

        delete() {
            let vm = this;
            return new Promise( (resolve, reject) => {
                let currentIndex = vm.$refs.primary.splide.index;
                let image = vm.imagesInternal[currentIndex];
    
                // remove from checkin instance (and splide)
                vm.imagesInternal.splice(currentIndex, 1);
                vm.$refs.primary.splide.options.start =
                    currentIndex == vm.imagesInternal.length
                        ? vm.imagesInternal.length - 1
                        : currentIndex;
                vm.imagesInternal = [...vm.imagesInternal];
    
                // let the parent know of the new image list - this will trigger a save in Sidebar
                vm.$emit("update:images", vm.imagesInternal);
    
                // delete the image from storage too
                var storageReference = storage.ref(image.storagePath);
                storageReference
                    .delete()
                    .then(() => {
                        resolve()
                    })
                    .catch((error) => {
                        reject();
                    });
            })

        },

        handleUploaded(result) {
            // display the file
            const vm = this;
            // add this on the checkin record (will autosave)
            var storageReference = storage.ref(result.storagePath);
            storageReference
                .getDownloadURL()
                .then((url) => {
                    let newImage = {
                        storagePath: result.storagePath,
                        name: result.filename,
                        storageUrl: url,
                    };

                    // insert after current index
                    let currentIndex = 0;

                    // if the splide is present (i.e. we have images already), then make sure when
                    // it reloads that it jumps to the inserted slide
                    if (this.$refs.primary) {
                        currentIndex = this.$refs.primary.splide.index;
                        this.$refs.primary.splide.options.start =
                            currentIndex + 1;
                    }

                    // insert the image into the imagesInternal array
                    if (currentIndex == vm.imagesInternal.length - 1) {
                        vm.imagesInternal.push(newImage);
                    } else {
                        vm.imagesInternal.splice(currentIndex + 1, 0, newImage);
                    }

                    // reset the images on the splide - have to set, rather than mutate
                    vm.imagesInternal = [...vm.imagesInternal];

                    vm.$emit("update:images", vm.imagesInternal);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
});
</script>

<style scoped>
.imageViewer.fullscreen {
    position: fixed;
    right: 0px;
    left: 0px;
    top: 0px;
    bottom: 0px;
    background: black;
}

.caption {
    height: 40px;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 10px;
    position: absolute;
    width: 100%;
}

.caption input {
    width: 70%;
    border: none;
    background-color: transparent;
    color: white;
    text-align: center;
}

.controlStrip {
    display: flex;
    justify-content: center;
    align-items: center;
}

.control:last-child {
    margin-left: 30px;
}
.control:first-child {
    margin-left: 0px;
}

.control {
    display: flex;
    justify-items: center;
    align-items: center;
}
.imagePlaceholder {
    background-color: var(--ish-lightgrey);
    color: var(--ish-mediumgrey);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    /* this is the padding we add to push the splide page navigation down */
    margin-bottom: 30px;
}

.imagePlaceholder div {
    cursor: pointer;
}
</style>