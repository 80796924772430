<template>
    <div :class="{'customlinkinput--edit': showEdit}">
        <div class="editBlock" v-if="showEdit">
            <base-input v-model="title" :options="{placeholder: 'Link Title'}"></base-input>
            <base-input v-model="url" :options="{placeholder: 'https://www.example.com'}"></base-input>
            <div class="editBlock__actions">
                <icon tooltip="Delete" @click="$emit('delete')" :options="deleteIconOptions">delete</icon>
                <div>
                    <base-button :isSecondary="true" @click="handleCancel">Cancel</base-button>
                    <base-button @click="handleSave">Save</base-button>
                </div>
            </div>
        </div>
        <div class="viewBlock" v-else>
            <div class='title'>{{value.title ?? value.url}}</div>
            <a href="#" @click="handleEdit">Edit</a>
            <!-- <base-button @click="handleEdit">Edit</base-button> -->
        </div>
    </div>
</template>

<script >
import { defineComponent } from 'vue'

// Classes
import { CustomLink } from '../../api/classes/CustomLink';

import { constants } from '../../constants';

// Components
import BaseInput from './BaseInput.vue'
import BaseButton from './BaseButton.vue';
import Icon from './Icon.vue';

export default defineComponent({
    name:'customlinkinput',
    
    components: { 
        BaseInput,
        BaseButton,
        Icon,
    },

    emits: ["update:modelValue", "delete"],

    props: {
        // The bound value of the input, set by clients using the v-model property
        modelValue: CustomLink,
    },

    data() {
        return {
            showEdit: false,
            internalCustomLink: CustomLink,
        }
    },
    mounted() {
        if (this.   modelValue) {
            this.title = this.modelValue.title;
            this.url = this.modelValue.url;
            
            this.showEdit = !this.url;
        }
    },

    computed: {
        deleteIconOptions() {
            let options = constants.ICON_OPTIONS.RED;
            return options;
        },
        value: {
            get() {
                return this.modelValue;
            },
            set() {
                //this.$emit("update:modelValue", value);
            }
        }
    },

    methods: {
        
        handleCancel() {
            this.showEdit = false;
        },
        handleSave() {
            this.value.title = this.title;
            this.value.url = this.url;
            this.$emit("update:modelValue", this.value);
            this.showEdit = false;
        },
        handleEdit() {
            this.showEdit = true;
        }

    }

})
</script>

<style scoped>

.customlinkinput--edit {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid var(--ish-lightgrey);
}

.viewBlock {
    height: 40px;
    display:flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
}

.editBlock__actions {
    display:flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
}
</style>