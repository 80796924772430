<template>
  <modal-dialog title="Help" @close="$emit('close')">
    <div>
      <p>
        Still early days in the development of iStayedHere, so here are a couple
        of things to be aware of;
      </p>
      <ul>
        <li>
          <b>One check in per day</b> - while you technically can add more per
          day, bad things might happen to the order people navigate your
          adventure!
        </li>
        <li>
          <b>One image per check in</b> - while you can add more, only one will
          be shown to people you share with.
        </li>
        <li>
          <b>Check your dates</b> - iStayedHere will calculate a default date
          for your check in based on previous check ins and rest/off trail day
          counts. This normally works well if you're entering check ins in the
          order you stayed places. It's not perfect though so best check!
        </li>
        <li>
          <b>Check your distances</b> - Similarly for distances. iStayedHere
          will base the default distance walked on the distance between the last
          check in and the one you're adding. Works well if you're entering
          check ins in the order you stayed places, but it's not perfect so best
          check!
        </li>
        <li>
          <b>Refreshing data</b> - if you think the dates are right but the
          order isn't, you can refresh the data (Menu -> Refresh) and this can
          sort things out.
        </li>
      </ul>
    </div>
  </modal-dialog>
</template>

<script>
import { defineComponent } from "vue";
import ModalDialog from "../controls/ModalDialog.vue";

export default defineComponent({
  name: "help",
  trackAsPageView: true,
  trackWithPageName: "Help",
  emits: ["close"],
  components: {
    ModalDialog,
  },
});
</script>

<style scoped>
</style>