
//import { firebase } from "../firebaseInit.js";
// var _ = require('lodash');
import firebase from 'firebase/app';
import { constants } from "../../constants";
import { Location } from "./Location";
import { Accommodation } from "./Accommodation";
import { CustomLink } from './CustomLink';

const { DateTime } = require("luxon");

export { CheckIn, checkInConverter };

/**
 * Class representing a checkin at a location on a map
 * 
 * @constructor
 * 
 * @param {Object} config
 * 
 * Object containing the following properties;
 * - {String} id
 * - {String} address
 * - {String} notes
 * - {DateTime} date
 * - {constants.CHECKINTYPE} type
 * - {Number} nearestTrailMarker
 * - {Number} distanceWalked
 * - {Array} images
 * - {Number} numberOfRestDays
 * - {Number} numberOfOffTrailDays
 * - {String} adventureId
 * - {Array[CustomLink]} customLinks
 * - {Boolean} resupply
 * - {String} resupplyNotes
 */
class CheckIn {
  
  /**
   * Create an instance that tracks if any properties are changed, adding two properties, isDirty & dirtyProperties
   * 
   * @param {Object} config
   *  Same config as the default CheckIn constructor
   * @param {function} callback
   *  Optional callback function that will be called when a property changes. Callback should
   * have signature callback(id: String, propertyName: String) where id is the id of the checkin being updated
   * and propertyName is the property that's changed
   * @returns 
   */
  static trackedInstance = function(config, callback) {
    let instance = new CheckIn(config);
    return new Proxy(instance, new proxyHandler(instance, null, callback));    
  }

  constructor(config) {
    if (!config.location) { throw "Must include a Location instance in config."; }

    this.id = config.id ?? null;
    this.uid = config.uid ?? null;
    this.location = config.location;
    this.title = config.title ?? null;
    this.address = config.address ?? null;
    this.accommodation = config.accommodation ?? null;
    this.notes = config.notes ?? null;
    this.date = config.date ?? DateTime.local();
    this.type = config.type ?? constants.CHECKINTYPE.STAY;
    this.nearestTrailMarker = config.nearestTrailMarker ?? 0;
    this.distanceWalked = config.distanceWalked ?? 0;
    this.images = config.images ?? [];
    this.numberOfRestDays = config.numberOfRestDays ?? 0;
    this.numberOfOffTrailDays = config.numberOfOffTrailDays ?? 0;
    this.adventureId = config.adventureId; 
    this.customLinks = config.customLinks ?? [];
    this.resupply = config.resupply ?? false;
    this.resupplyNotes = config.resupplyNotes ?? null;
  }

  /**
   * For debugging purposes to identify the instance
   * @returns {String}
   *  id:adventureId, address
   */
  toString() {
    return `${this.id}: ${this.adventureId}, ${this.address}`;
  }

  /**
   * Returns whether the instance has changed sinced being instantiated.
   */
  get isDirty() {
    return this._isDirty ?? false;
  }
  
  /**
   * Returns Array of the names of the properties that have been set since the instance was instantiated
   */
  get dirtyProperties() {
    return this._dirtyProperties ?? [];
  }

  /**
   * Resets the dirty flag. Typically done after succfessfully saving the data
   */
  resetIsDirty() {
    this._isDirty = false;
  }
}

/**
 * Proxy handler, sets the following additional properties to a CheckIn instance
 *    - isDirty - set to true if any properties change after instantiation
 *    - dirtyProperties - if isDirty true, contains an array of the properties that have changed.
 * 
 * @constructor
 *
 * @param {Object} parent
 *  Class instance representing the top level CheckIn instance. This is passed into each recursive instance
 * of the proxyHandler to enable the CheckIn.isDirty/dirtyProperties to be set.
 * 
 * @param {String} prefix
 *  String to be prepended to the description of the property being updated. Used to identify which deep property
 * was updated for example, 'customLinks.1.text' would be the text property of the 2nd object in the customLinks array
 * 
 * @param {function} callback
 *  Callback function that will be called for each property that is set. The callback should have the signature
 * callback(propertyName)
 */

var proxyHandler = class {

  constructor(parent, prefix, callback) {
    this.parent = parent;
    this.prefix = prefix;
    this.callback = callback;
  }

  get(obj, prop) {
    
    // for the objects we want to track deeply...
    const deepTrackedProperties = ['customLinks','images','accommodation'];
    if (deepTrackedProperties.includes(prop)) {
      if (obj[prop]) {
        const prefix = this.prefix ? this.prefix + "." + prop : prop;
        return new Proxy(obj[prop], new proxyHandler(this.parent, prefix, this.callback))
      }
    } else {
      return obj[prop];
    }
  }

  set(obj, prop, value) {

    // ignore setting date if the same value
    if (prop == 'date') {
      if (obj[prop].equals(value)) {
        return true;
      }
    }

    // persist data
    obj[prop] = value;

    // Don't track internal properties 
    if (!prop.startsWith('_')) {

      // mark this instance dirty
      this.parent._isDirty = true;
  
      // record which property caused it to become (or remain) dirty
      if (!this.parent._dirtyProperties) { this.parent._dirtyProperties = []; }
      let propertyName = this.prefix ? this.prefix + "." + prop : prop;
      if (this.parent._dirtyProperties.indexOf(propertyName) < 0) {
        this.parent._dirtyProperties.push(propertyName);
      }

      // let the callback know which property changed
      this.callback(this.parent.id, propertyName);
      
    }

    return true;
  }
}

// 
/**
 * FirestoreDataConverter implementation for CheckIn instances
 */
var checkInConverter = {
  toFirestore: function (checkIn) {

    const result = {};
    if (checkIn.uid) { result.uid = checkIn.uid; }
    if (checkIn.title) { result.title = checkIn.title; }
    if (checkIn.adventureId) { result.adventureId = checkIn.adventureId; }
    if (checkIn.location) {
      result.location = checkIn.location.toGeoPoint();
    }
    if (checkIn.address) { result.address = checkIn.address; }
    //if (checkIn.accommodationKey) { result.accommodationKey = checkIn.accommodationKey; }
    if (checkIn.accommodation) { 
      // A null key is a sign we're trying to remove the accommodation with an Accommodation.nullInstance().
      if (checkIn.accommodation.key != null) {
        result.accommodation = {
          key: checkIn.accommodation.key,
          value: checkIn.accommodation.value,
          imageName: checkIn.accommodation.imageName,
          imageRotation: checkIn.accommodation.imageRotation,
        };
      } else {
        // Remove the accommodation reference
        result.accommodation = {};
      }
    }

    if (checkIn.notes) { result.notes = checkIn.notes; }
    if (checkIn.date) { result.date = firebase.firestore.Timestamp.fromDate(checkIn.date.toJSDate()); }
    if (checkIn.type) { result.type = checkIn.type }
    if (checkIn.numberOfRestDays >= 0) { result.numberOfRestDays = checkIn.numberOfRestDays }
    if (checkIn.numberOfOffTrailDays >= 0) { result.numberOfOffTrailDays = checkIn.numberOfOffTrailDays }
    if (checkIn.nearestTrailMarker) { result.nearestTrailMarker = parseFloat(checkIn.nearestTrailMarker.toFixed(1)) }
    // if (checkIn.imageUrl) { result.imageUrl = checkIn.imageUrl }
    if (checkIn.distanceWalked >= 0) { result.distanceWalked = checkIn.distanceWalked }
    if (checkIn.images) { result.images = checkIn.images }
    if (checkIn.customLinks) { 
      result.customLinks = checkIn.customLinks.map( c => {
        return {title: c.title ?? "", url: c.url ?? ""};
        }) 
    }
    if (checkIn.resupply != null) { result.resupply = checkIn.resupply; }
    if (checkIn.resupplyNotes) { result.resupplyNotes = checkIn.resupplyNotes; }

    return result;
  },

  fromFirestore: function (snapshot, options) {
    const data = snapshot.data(options);
    let location = null;
    if (data.location) {
      location = new Location.fromGeoPoint(data.location);
    }
    
    const config = {
      id: snapshot.id,
      uid: data.uid ?? null,
      location: location,
      title: data.title ?? null,
      adventureId: data.adventureId ?? null,
      address: data.address ?? null,
      accommodation: data.accommodation ? new Accommodation(data.accommodation) : Accommodation.nullInstance(),
      notes: data.notes ?? null,
      type: data.type ?? null,
      date: data.date ? DateTime.fromJSDate(data.date.toDate()) : DateTime.local(),
      numberOfRestDays: data.numberOfRestDays ?? 0,
      numberOfOffTrailDays: data.numberOfOffTrailDays ?? 0,
      nearestTrailMarker: data.nearestTrailMarker ?? 0,
      distanceWalked: data.distanceWalked ?? 0,
      images: data.images ?? [],
      customLinks: data.customLinks ? data.customLinks.map( c => { return new CustomLink(c.title, c.url ) }): [],
      resupply: data.resupply ?? false,
      resupplyNotes: data.resupplyNotes ?? null,
    }
      
    return new CheckIn(config);
  }
};
