<template>
    <div class="dashboard" :class="{ loading: isLoading }">
        <loader
            v-if="isLoading"
            :size="50"
            class="loader"
            message="Loading"
        ></loader>

        <dashboard-header
            :readOnly="readOnly"
            v-if="!isLoading"
            :checkIns="checkIns"
            :adventure="adventure"
            @clickLatestCheckIn="handleMapControlClick(2)"
            @changeView="handleChangeView"
            @showstats="handleShowStatistics"
            @showShare="showShare = true"
            @refresh="handleRefresh()"
        ></dashboard-header>

        <div class="content" v-show="!isLoading">
            <GoogleMapLoader
                :mapConfig="mapConfig"
                apiKey="AIzaSyCs-89OBo37p0p6oLh5kmr9cqq1E7gNn7E"
                :panToLocation="panToLocation"
                :fitBounds="fitBounds"
                :showMinMaxControls="showZoomControl"
                @idle="handelMapIdle"
                @initialised="handleMapInitialised(true)"
                @loaded="handleMapLoadedEvent"
                @click="handleMapClick"
                v-show="!isLoading"
            >
                <template v-slot:default="mapProps">
                    <GoogleMapControl
                        v-for="control in mapControls"
                        :key="control.id"
                        :google="mapProps.google"
                        :map="mapProps.map"
                        :options="control.options"
                        :isBlinking="control.isBlinking"
                        @click="handleMapControlClick"
                    >
                    </GoogleMapControl>

                    <GoogleMapLayer
                        v-for="layer in layers"
                        :key="layer.id"
                        :layer="layer"
                        :google="mapProps.google"
                        :map="mapProps.map"
                        @loaded="handleLoadedEvent"
                    ></GoogleMapLayer>

                    <DistanceMarker
                        v-for="marker in visibleDistanceMarkers"
                        :map="mapProps.map"
                        :google="mapProps.google"
                        :key="marker.id"
                        :location="marker.location"
                        :isMajor="marker.isMajor"
                        :showLabel="marker.showLabel"
                        :name="marker.name"
                        :mapTypeId="marker.mapTypeId"
                    >
                    </DistanceMarker>

                    <CheckInMarker
                        v-for="checkIn in checkIns"
                        :key="checkIn.id"
                        :map="mapProps.map"
                        :google="mapProps.google"
                        :checkIn="checkIn"
                        :daysOnTrail="
                            checkIns.statistics[checkIn.id].daysOnTrail
                        "
                        :selected="isSelected(checkIn.id)"
                        :canMove="!readOnly"
                        @click="handleCheckInMarkerClick"
                        @moved="handleCheckInMarkerMoved"
                    >
                        >
                    </CheckInMarker>

                    <PreviewMarker
                        :map="mapProps.map"
                        :google="mapProps.google"
                        v-if="showPreviewMarker"
                        :options="previewMarkerOptions"
                        :showDialog="showPreviewMarker"
                        @checkin="handleCheckIn"
                        @firstcheckin="handleFirstCheckIn"
                        @close="handlePreviewClose"
                    >
                    </PreviewMarker>

                    <current-location-marker
                        v-if="currentLocation"
                        :map="mapProps.map"
                        :google="mapProps.google"
                        :location="currentLocation"
                        @click="handleCurrentLocationClick"
                    >
                    </current-location-marker>

                    <transition name="slide-fade">
                        <sidebar
                            v-if="showSidebar"
                            :sidebarType="sidebarType"
                            :adventure="adventure"
                            :allCheckIns="checkIns"
                            :readOnly="readOnly"
                            @close="handleCloseSidebar"
                            @saved="handleSavedSidebar"
                            @didNavigate="handleDidNavigateSidebar"
                            @clickedExitGraphs="handleExitGraphClick"
                            @showShare="showShare = true"
                            @showStatistics="handleShowStatistics"
                        ></sidebar>
                    </transition>

                    <transition name="slide-fade">
                        <pop-up-message
                            v-if="showPopUpMessage"
                            :message="popUpMessage"
                            @close="showPopUpMessage = false"
                        ></pop-up-message>
                    </transition>

                    <map-info
                        v-if="showMapInfo"
                        :map="mapProps.map"
                        :google="mapProps.google"
                        :trailVersion="trailVersion"
                        @close="showMapInfo = false"
                    >
                    </map-info>
                </template>
            </GoogleMapLoader>
        </div>
        <share
            v-if="showShare"
            :adventure="adventure"
            :shareLinks="shareLinks"
            @close="showShare = false"
        ></share>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
const { DateTime } = require("luxon");

import { defineComponent } from "vue";
import { mapSettings } from "../../constants/mapSettings";
import { constants } from "../../constants";
import { ErrorLookup } from "../../api/classes/ErrorLookup";

import { LAYERTYPE } from "../../api/firestoreConstants.js";

// Classes
import { Adventure } from "../../api/classes/Adventure";
import { Location } from "../../api/classes/Location";
import { CheckIn } from "../../api/classes/CheckIn";
import CheckInArray from "../../api/classes/CheckinArray";

// API
import { addCheckIn } from "../../api/firestoreCheckIn";
import { ShareLinks } from "../../api/classes/ShareLinks";
import {
    getNearestMarker,
    getVisibleDistanceMarkers,
    getFirstDistanceMarker,
    getDistanceMarkers,
} from "../../api/firestoreDistanceMarkers.js";
import { getLayers } from "../../api/firestoreMapLayer.js";
import { getCheckIns } from "../../api/firestoreCheckIn.js";
import { getAdventure } from "../../api/firestoreAdventures";
import { UrlBuilder } from "../../api/classes/UrlBuilder";

// Vue Components
import Loader from "../controls/Loader";
import DashboardHeader from "../controls/DashboardHeader";
import GoogleMapLoader from "../maps/GoogleMapLoader.vue";
import GoogleMapControl from "../maps/GoogleMapControl.vue";
import GoogleMapLayer from "../maps/GoogleMapLayer.vue";
import DistanceMarker from "../maps/DistanceMarker.vue";
import CheckInMarker from "../maps/CheckInMarker.vue";
import PreviewMarker from "../maps/PreviewMarker.vue";
import Sidebar from "../sidebar/Sidebar.vue";
import CurrentLocationMarker from "../maps/CurrentLocationMarker.vue";
import PopUpMessage from "../controls/PopUpMessage";
import Share from "../controls/Share.vue";
import MapInfo from "../maps/MapInfo.vue";

export default defineComponent({
    name: "Dashboard",
    title() { return "Map " + this.readOnly ? "View" : "Edit" },
    components: {
        Loader,
        DashboardHeader,
        GoogleMapControl,
        GoogleMapLoader,
        GoogleMapLayer,
        DistanceMarker,
        CheckInMarker,
        PreviewMarker,
        Sidebar,
        CurrentLocationMarker,
        PopUpMessage,
        Share,
        MapInfo,
    },

    props: {
        preview: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            showPopUpMessage: false,
            showShare: false,
            showMapInfo: false,
            popUpMessage: "Error",
            showZoomControl: true,
            currentLocation: null,
            sidebarType: "CHECKIN",
            isLoading: true,
            layers: [],
            visibleDistanceMarkers: [],
            checkIns: new CheckInArray(),
            mapControls: [],
            panToLocation: { location: new Location(-41.2769, 174.7731) },
            showPreviewMarker: false,
            previewMarkerOptions: {
                // where to place te marker based on where the user clicks on the map
                position: Location,
                // flag to indicate whether this is the first checkin
                ifFirstCheckIn: Boolean,
                // optional  - the place id related to where the user clicked
                placeId: String,
            },
            showSidebar: false,
            sidebarOptions: {
                checkIn: CheckIn,
            },
            // setting this will force the map to pan to include all these check ins
            fitBounds: null,
            currentView: Object,
            adventure: Adventure.null(),
            // set to true if previewing as owner
            previewAsOwner: false,
        };
    },

    mounted() {
        this.showZoomControl = screen.width >= 760;
        this.attachListeners();
        this.refreshMapControls();
    },

    computed: {
        ...mapGetters({
            user: "user",
        }),
        
        trailVersion() {
            if (!this.layers || this.layers.length == 0) {
                return "?";
            }
            return DateTime.fromISO(this.layers[0].version).toLocaleString({ day: 'numeric', month: 'long', year: 'numeric' });
        },

        shareLinks() {
            let shareLinks = new ShareLinks(
                this.adventure,
                this.checkIns,
                this.currentView.zoom,
                this.currentView.centre,
            );
            
            return shareLinks.asArray()
        },

        isMobile() {
            return screen.width <= 760;
        },

        isOwner() {
            return this.adventure.uid == this.user.uid;
        },

        readOnly() {
            return (
                this.$route.path.indexOf("view") >= 0 ||
                !this.isOwner ||
                this.previewAsOwner
            );
        },

        isSelected: function () {
            return function (id) {
                const result = this.checkIns.current.id === id;
                return result;
            };
        },
        adventureId() {
            return this.$route.params.adventureid;
        },
        mapConfig() {
            const wellington = { lat: -41.2769, lng: 174.7731 };
            return {
                ...mapSettings,
                center: wellington,
            };
        },
    },

    watch: {
        readOnly: function () {
            this.refreshMapControls();
            this.displayCurrentLocation();
        },
    },

    methods: {
        refreshMapControls() {
            
            this.mapControls.length = 0;
            this.mapControls.push({
                options: {
                    id: 1,
                    index: 1,
                    title: "Map Information",
                    position: constants.MAPCONTROL_POSITION.TOP_RIGHT,
                    materialIconsName: "info",
                    materialIconsClass: "material-icons-outlined",
                },
            });

            // this.mapControls.push({
            //     options: {
            //         id: 11,
            //         index: 11,
            //         title: "Check Ins",
            //         position: constants.MAPCONTROL_POSITION.RIGHT_CENTRE,
            //         materialIconsName: "place",
            //     },
            // });
            // this.mapControls.push({
            //     options: {
            //         id: 11,
            //         index: 11,
            //         title: "Resupplies",
            //         position: constants.MAPCONTROL_POSITION.RIGHT_CENTRE,
            //         materialIconsName: "shopping_cart",
            //     },
            // });


            if (!this.readOnly) {
                this.mapControls.push({
                    options: {
                        id: 2,
                        index: 2,
                        title: "Go to the last check in.",
                        materialIconsName: "directions_walk",
                    },
                });

                this.mapControls.push({
                    options: {
                        id: 3,
                        index: 3,
                        title: "Zoom to location",
                        materialIconsName: "gps_fixed",
                    },
                });
            } 
        },
        attachListeners() {
            let vm = this;
            window.onresize = function () {
                vm.showZoomControl = screen.width >= 760;
            };
        },

        getCurrentLocation() {
            let vm = this;
            return new Promise(function (resolve, reject) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        resolve(new Location(pos.lat, pos.lng));
                    },
                    () => {
                        const error = "Can't find your current location.";
                        vm.showPopUpMessage = true;
                        vm.popUpMessage = error;
                        reject(error);
                    },
                    {
                        maximumAge: 10000,
                        timeout: 5000,
                    }
                );
            });
        },

        displayCurrentLocation() {
            let vm = this;
            this.getCurrentLocation()
                .then((location) => {
                    vm.currentLocation = location;
                })
                .catch((error) => {
                    // we don't really care here - should silently fail.
                });
        },

        handleRefresh() {
            this.handleMapInitialised(false);
        },

        handleMapTypeChanged(mapType) {
            this.mapProps.map.setMapTypeId(mapType);
            this.showMapInfo = false;
        },

        handleChangeView(mode) {
            if (mode == "preview") {
                this.previewAsOwner = true;
            } else if (mode == "edit") {
                this.previewAsOwner = false;
            }
        },

        handleMapLoadedEvent(event) {
            // this is (always?) the last thing to fire and is a good time to get rid of the loading mask
            this.isLoading = false;
        },

        /**
         * Fired by components to let the Dashboard know they've finished loading/rendering
         */
        handleLoadedEvent(event) {},

        selectCheckIn(id, panTo, zoom, addToBrowseHistory) {
            this.checkIns.setCurrentById(id);
            
            // required to allow sibling components to respond to navigation event
            this.$bus.$emit("didNavigateGlobal");
            
            if (panTo) {
                this.panToLocation = {
                    location: this.checkIns.current.location,
                    zoom: zoom ? zoom : 10,
                };
            }
            if (addToBrowseHistory ?? true) {
                this.updateUrl(this.adventure, this.checkIns.current);
            }
        },

        updateUrl(adventure, checkIn) {
            history.pushState(
                {},
                null,
                this.readOnly
                    ? UrlBuilder.urlForDashboard(
                          adventure.username,
                          adventure.id,
                          checkIn.id,
                          { mode: "view" }
                      )
                    : UrlBuilder.urlForDashboard(
                          adventure.username,
                          adventure.id,
                          checkIn.id,
                          { mode: "edit" }
                      )
            );
        },

        handleMapControlClick(id) {
            // Show map info
            if (id == 1) {
                this.showMapInfo = true;
                // const MAX = 5;
                // var bounds = new window.google.maps.LatLngBounds();
                // var earliestToShow =
                //   this.checkIns.length > MAX - 1 ? this.checkIns.length - MAX - 1 : 0;
                // for (var i = earliestToShow; i < this.checkIns.length; i++) {
                //   bounds.extend(this.checkIns[i].location.toGoogleMapLocation());
                // }
                // this.fitBounds = bounds;
            }

            // Go to latest checkin
            if (id == 2) {
                const latestCheckIn = this.checkIns.mostRecent();
                if (latestCheckIn) {
                    this.selectCheckIn(latestCheckIn.id, true, true);

                    // show sidebar
                    this.sidebarType = "CHECKIN";
                    this.showSidebar = true;
                }
            }

            // zoom to location
            if (id == 3) {
                let vm = this;
                let currentLocationControlOptions = this.mapControls.find(
                    (c) => {
                        return c.options.id == 3;
                    }
                );
                currentLocationControlOptions.isBlinking = true;
                this.getCurrentLocation()
                    .then((location) => {
                        currentLocationControlOptions.isBlinking = false;
                        vm.panToLocation = { location: location, zoom: 16 };
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        currentLocationControlOptions.isBlinking = false;
                    });
            }

            // Show statistics
            // if (id == 4) {
            //     this.handleShowStatistics();
            // }
        },

        // handleDisplayModeChanged(displayMode) {
        //   this.displayMode = displayMode;
        // },

        handleDidNavigateSidebar() {
            this.panToLocation = { location: this.checkIns.current.location };
            this.updateUrl(this.adventure, this.checkIns.current);
        },

        handleCheckInMarkerClick(checkIn) {
            this.selectCheckIn(checkIn.id, false, true);
            this.showPreviewMarker = false;
            this.sidebarType = "CHECKIN";
            this.showSidebar = true;
        },

        handleCheckInMarkerMoved(moveResult) {
            getNearestMarker(moveResult.location).then((marker) => {
                moveResult.checkIn.location = moveResult.location;
                //moveResult.checkIn.nearestTrailMarker = parseInt(marker.name);
                moveResult.checkIn.nearestTrailMarker = marker.trailKm;
            });
        },

        handleMapInitialised(moveToDefaultView) {
            // Do all the loading you need for the current adventure, layers and markers
            const vm = this;
            const adventureId = this.$route.params.adventureid;

            if (adventureId) {
                // must first load the adventure to see if it's owned by the logged in user or not
                getAdventure(adventureId)
                    .then((adventure) => {
                        vm.adventure = adventure;

                        // load all the other data

                        // passing in the owner's uid will mean we can view checkins from private adventures
                        // if you're not the owner firestore security will prevent you seeing private adventures.
                        const uid = this.isOwner ? this.user.uid : null;

                        const loadCheckIns = getCheckIns(uid, adventureId);
                        const loadLayers = getLayers("TA", LAYERTYPE.TRAIL);
                        const loadDistanceMarkers = getDistanceMarkers(
                            "TA",
                            LAYERTYPE.DISTANCE
                        );

                        Promise.all([
                            loadCheckIns,
                            loadLayers,
                            loadDistanceMarkers,
                        ])
                            .then((results) => {
                                const checkInsResults = results[0];
                                const layers = results[1];

                                // we don't actually need the distance markers, they will be accessed by, for example,
                                // getVisibleDistanceMarkers. Note calling getDistanceMarkers loads them all into memory
                                // const distanceMarkers = result[3];

                                // Update the vm with the loaded data
                                vm.adventure = adventure;
                                vm.checkIns = checkInsResults;
                                vm.layers = layers;
                            })
                            .catch(() => {
                                vm.$router.push(`/?error=${constants.ERROR.PERMISSION_DENIED}`);
                            })
                            .finally(() => {
                                vm.isLoading = false;
                                if (moveToDefaultView) {
                                    vm.moveToInitialView();
                                }
                            });
                    })
                    .catch((error) => {
                        // typically happens because the link is pointnig to an adventure that isn't public anymore
                        vm.$router.push(`/?error=${constants.ERROR.ADVENTURE_NOT_PUBIC}`);
                    });
            }
        },

        handleSavedSidebar(newCheckin) {
            this.checkIns.insertByDate(newCheckin);
        },

        handleCloseSidebar() {
            this.showSidebar = false;
        },

        handlePreviewClose() {
            this.showPreviewMarker = false;
        },

        handleFirstCheckIn(results) {
            this.showPreviewMarker = false;
            // ui should prevent this happening for public users but...
            if (this.readOnly) {
                return;
            }
            const newCheckIn = new CheckIn(results);
            newCheckIn.uid = this.user.uid;
            newCheckIn.type = "start";
            newCheckIn.adventureId = this.adventureId;
            // set to today's date (no time)
            newCheckIn.date = DateTime.fromObject({
                hour: 0,
                minute: 0,
                second: 0,
            });
            this.addNewCheckIn(newCheckIn);
        },

        /**
         * Handle the Preview component's checkin request.
         *
         * results: location, title, address, nearestTrailMarker
         */
        handleCheckIn(results) {
            this.showPreviewMarker = false;

            // ui should prevent this happening for public users but...
            if (this.readOnly) {
                return;
            }

            // create a new CheckIn record from the preview request
            const newCheckIn = new CheckIn(results);

            // append uid of logged in user
            newCheckIn.uid = this.user.uid;
            newCheckIn.adventureId = this.adventureId;

            // work out a reasonable date and distance walked for the new checkin...
            let closest = this.checkIns.closestCheckInTo(results.location);
            if (closest) {
                const distanceToClosest = Math.abs(
                    results.nearestTrailMarker - closest.nearestTrailMarker
                );
                newCheckIn.distanceWalked = distanceToClosest;
                if (closest == this.checkIns.first()) {
                    newCheckIn.date = closest.date;
                } else {
                    var daysSinceClosestCheckIn =
                        1 +
                        closest.numberOfRestDays +
                        closest.numberOfOffTrailDays;
                    newCheckIn.date = closest.date.plus({
                        days: daysSinceClosestCheckIn,
                    });
                }
            }

            this.addNewCheckIn(newCheckIn);
        },

        addNewCheckIn(newCheckIn) {
            addCheckIn(newCheckIn)
                .then((checkIn) => {
                    this.checkIns.insertByDate(newCheckIn);
                    newCheckIn.id = checkIn.id;
                    this.checkIns.setCurrentById(newCheckIn.id);
                    this.showSidebar = true;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        handleCurrentLocationClick(location) {
            let lastLocation = this.checkIns.mostRecent
                ? this.checkIns.mostRecent.location
                : null;
            this.showPreviewMarkerAndDialog(location, null, lastLocation);
        },

        handleMapClick(location, placeId) {
            let lastLocation = this.checkIns.mostRecent()
                ? this.checkIns.mostRecent().location
                : null;
            this.showPreviewMarkerAndDialog(location, placeId, lastLocation);
        },

        showPreviewMarkerAndDialog(location, placeId, lastLocation) {
            this.previewMarkerOptions = {
                location: location,
                placeId: placeId,
                lastLocation: lastLocation,
                isFirstCheckIn: this.checkIns.length == 0,
            };
            if (!this.readOnly) {
                this.showPreviewMarker = !this.showPreviewMarker;
            }

            // always close the sidebar if the map is clicked
            if (this.showSidebar) {
                this.showSidebar = false;
                this.showPreviewMarker = false;
            }
        },

        handleShowStatistics() {
            // this.showSidebar = false;
            this.sidebarType = "STATISTICS";
            this.showSidebar = true;
        },

        handleExitGraphClick() {
            this.sidebarType = "CHECKIN";
        },

        moveToInitialView() {
            // Always check whether lat, lng have been supplied and centre there as a priority
            let qs = this.$route.query;
            let latLngOnQueryString = qs.lat && qs.lng;
            let goto = qs.goto;

            if (latLngOnQueryString) {
                if (qs.zoom) {
                    this.panToLocation = {
                        location: new Location(qs.lat, qs.lng),
                        zoom: qs.zoom,
                    };
                } else {
                    this.panToLocation = {
                        location: new Location(qs.lat, qs.lng),
                    };
                }
            }

            // work out the checkin id to navigate select
            if (this.checkIns.length > 0) {
                let id = this.$route.params.checkInId;
                if (!id) {
                    if (goto == "latest" || !goto) {
                        id = this.checkIns.mostRecent().id;
                    } else if (goto == "start") {
                        id = this.checkIns.first().id;
                    }
                }
                if (id) {
                    // only pan to the checkin if no lat/lng/zoom have been provided
                    this.selectCheckIn(id, !latLngOnQueryString, null, false);
                    this.showSidebar = true;
                }
            } else {
                // there are no checkins yet, just take the user to the first marker
                const vm = this;
                getFirstDistanceMarker("TA").then(function (first) {
                    if (first) {
                        vm.panToLocation = {
                            location: first.location,
                            zoom: 16,
                        };
                    }
                });
            }
        },

        handelMapIdle(options) {
            if (!this.isLoading) {
                this.currentView = {
                    centre: options.centre,
                    zoom: options.zoom,
                };
                this.panToLocation = null;
                this.refreshVisibleMarkers(options.bounds, options.zoom);
            }
        },

        refreshVisibleMarkers(bounds, zoom) {
            //const start = performance.now();

            const vm = this;

            getVisibleDistanceMarkers("TA", bounds, zoom).then(
                function (visible) {
                    // add any markers that are newly visible
                    for (var i in visible) {
                        const existingMarker = vm.visibleDistanceMarkers.find(
                            (x) => x.id === visible[i].id
                        );
                        if (!existingMarker) {
                            vm.visibleDistanceMarkers.push(visible[i]);
                        } else {
                            existingMarker.showLabel = visible[i].showLabel;
                        }
                    }

                    // remove any previously visible markers that should be removed
                    var j = vm.visibleDistanceMarkers.length;
                    while (j--) {
                        if (
                            !visible.find(
                                (x) => x.id === vm.visibleDistanceMarkers[j].id
                            )
                        ) {
                            vm.visibleDistanceMarkers.splice(j, 1);
                        }
                    }
                },
                function (error) {
                    console.error("Failed!", error);
                }
            );
            //let end = performance.now();
            //console.log(`RefreshCVisibleMarkers took ${end - start} milliseconds.`);
        },
    },
});
</script>

<style scoped>
.dashboard {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dashboard.loading {
    background: var(---ish-blue);
    z-index: 1;
}

.content {
    height: 100%;
    overflow: auto;
    flex-grow: 1;
    position: relative;
}

.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

/** initial state before entering and after leaving  */
.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(200px);
    opacity: 0;
}
</style>
