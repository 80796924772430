//import { firebase } from "../firebaseInit";
import firebase from 'firebase/app';

export { Location }

/**
 * Common representation of a geo-coordinate based location.
 */
class Location {
  constructor(lat, lng) {
    this.lat = parseFloat(lat);
    this.lng = parseFloat(lng);
  }

  toString() {
    return `${this.lat}, ${this.lng}`;
  }

  toGeoPoint() {
    return new firebase.firestore.GeoPoint(this.lat, this.lng);
  }
  
  toGoogleMapLocation() {
    return { lat: this.lat, lng: this.lng };
  }

  static fromGoogleMapLocation(latLng) {
    return new Location(latLng.lat(), latLng.lng());
  }

  static fromGeoPoint(geoPoint) {
    return new Location(geoPoint.latitude, geoPoint.longitude);
  }

}