<template>
  <a
    class="subscribe-button"
    :data-formkit-toggle="campaignId"
    :href="`https://marvelous-designer-8310.ck.page/${campaignId}`"
  >
    <div class="subscribe-button__content">
        <span class="material-icons subscribe-button__icon">notifications</span>
        <span class="subscribe-button__text">Subscribe</span>
    </div>
  </a>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "subscribe-button",
  props: {
    campaignId: String,
    showText: {
      type: Boolean,
      default: true,
    },
  },

  mounted() {
    let id = "convertkit-designer-script";
    // if there is a script element with convertkit as part of the src, then this means the marvelous-designer script below has
    // already been loaded, so don't load again.
    let ckscript = document.querySelectorAll('[src*="convertkit"]');
    if (ckscript.length == 0) {
      let script = document.createElement("script");
      script.setAttribute(
        "src",
        `https://marvelous-designer-8310.ck.page/${this.campaignId}/index.js`
      );
      script.setAttribute("async", "");
      script.setAttribute("id", id);
      script.setAttribute("data-uid", this.campaignId);
      document.body.appendChild(script);
    }
  },
});
</script>

<style scoped>
.subscribe-button {
  position: relative;
  /* padding: 10px 20px; */
  border-radius: 3px;

  text-decoration: none;
  color: var(--ish-darkgrey);
  /* color: var(--ish-blue); */
  font-size: var(--ish-font-size-normal);
  font-weight: var(--ish-font-weight);
  /* border: 1px solid var(--ish-darkgrey); */
  /* box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
        0 2px 6px 2px rgba(60, 64, 67, 0.15); */
  border-bottom: 0px;
  margin-left: 10px;
  background: white;
  transition: background 400ms;
}
.subscribe-button:focus {
  outline: none;
}
.subscribe-button:hover {
  cursor: pointer;
  color: black;
}

.subscribe-button:disabled {
  cursor: default;
  background: white;
  color: var(--ish-mediumgrey);
}

.subscribe-button__icon {
  /* background-color: var(--ish-blue); */
  /* background-color: var(--ish-bluehover); */
  background-color: red;
  color: white;
  /* color: var(--ish-blue); */
  padding: 10px;
  font-size: 18px;
  border-radius: 3px 0px 0px 3px;
  border: 1px solid red;
}

.subscribe-button__text {
  padding: 11px 10px;
  border-radius: 0px 3px 3px 0px;
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color:  lightgray;
}

.subscribe-button__content {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 500px) {
  .subscribe-button__icon {
    border-radius: 3px 3px 3px 3px;
  }
  .subscribe-button__text {
    display: none;
  }
}

</style>