<template>
  <div class="ss">
    
    <div class="ss__stats">
      <hr class="divider" />
      <div class="ss__grid">
        <div class="ss__cell">  
          <statistic-card
            :value="statistics.distanceWalked"
            unit="km"
            title="total"
            ></statistic-card>
        </div>
        <div class="ss__cell">  
          <statistic-card 
            :value="statistics.numberOfTrailDays"
            unit="days"
            title="on trail"
            toolTipTitle="Days on Trail"
            toolTipText="This is the number of days, exluding any off-trail days but including rest days, since starting the walk."
            ></statistic-card>
        </div>
        <div class="ss__cell">  
          <statistic-card 
            :value="Math.round(statistics.averageDailyWalkingDistance)"
            unit="km"
            title="daily avg."
            toolTipTitle="Average Daily"
            toolTipText="<p>The average distance you walk (on those days you actually walk!).</p><p>Specifically, the average daily distance walked EXCLUDING any rest or transit days. For example if 100km have been walked in 5 days and 1 of these days was a rest day, the average is 25km/day (100 divided by 4).</p>"
            ></statistic-card>
        </div>
        <div class="ss__cell">  
          <statistic-card 
            :value="Math.round(statistics.rawAverageDailyDistance)"
            unit="km"
            title="raw daily avg."
            toolTipTitle="Raw Daily Average"
            toolTipText="The average daily distance walked INCLUDING any rest days. For example if 100km have been walked in 5 days and 1 of these days was a rest day, the average is 20km/day (100 divided by 5)."
            ></statistic-card>
        </div>
        <div class="ss__cell">  
          <statistic-card 
            :value="statistics.longestDay"
            unit="km"
            title="longest day"
            ></statistic-card>
        </div>
        <div class="ss__cell">  
          <statistic-card 
            :value="statistics.numberOfRestDays"
            unit=""
            title="days rest"
            toolTipTitle="Rest Day"
            toolTipText="A rest day is when you stay in a place without progressing on the trail. It count's towards the number of days on trail and is included in Raw Daily Average calculation."
            ></statistic-card>
        </div>
        <div class="ss__cell">  
          <statistic-card 
            :value="statistics.numberOfZeroKmDays"
            unit=""
            title="days in transit"
            toolTipTitle="Transit Day"
            toolTipText="Transit days are when you take a bus or plane and check in to a new place but haven't walked any distance. This counts towards the number of days on trail, but doesn't get included in average distances walked."
            ></statistic-card>
        </div>
        <div class="ss__cell">  
          <statistic-card 
            :value="statistics.numberOfOffTrailDays"
            unit=""
            title="days off trail"
            toolTipTitle="Off Trail Days"
            toolTipText="Off trail days mark when you're out of action because of illness or personal reasons - and would otherwise be on trail. These days don't count towards the number of days on trail."
            ></statistic-card>
        </div>
      </div>
      <hr class="divider" />
    </div>
    <div class="ss__charts">
      <div class="ss__chart">
        <distance-chart :checkIns="checkIns" :maximumVisibleBars="maximumVisibleBars" @selectedCheckIn="handleSelectedCheckInFromChart"></distance-chart>
      </div>

      <hr class="divider" />
      
      <div class="ss__chart">
        <cummulative-distance-chart :checkIns="checkIns" :maximumVisibleBars="maximumVisibleBars" @selectedCheckIn="handleSelectedCheckInFromChart"></cummulative-distance-chart>
      </div>
      
      <hr class="divider" />

      <div class="ss__chart--pie">
        <accommodation-chart :checkIns="checkIns" ></accommodation-chart>
      </div>
    </div>

  </div>
</template>

<script>
import { defineComponent } from "vue";
// import { Chart } from 'chart.js';

import CheckInArray from "../../api/classes/CheckinArray";
import StatisticCard from "../controls/StatisticCard.vue";
import AccommodationChart from "../controls/charts/accommodationChart.vue";
import CummulativeDistanceChart from "../controls/charts/cummulativeDistanceChart.vue";
import DistanceChart from "../controls/charts/distanceChart.vue";

export default defineComponent({
  name: "SidebarStatistics",
  trackAsPageView: true,
  trackWithPageName: 'Side Bar Data',
  emits: ['selectedCheckIn'],

  components: { 
    StatisticCard, 
    AccommodationChart,
    CummulativeDistanceChart,
    DistanceChart
    },

  props: {
    checkIns: CheckInArray,
    showAllDataInCharts: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    // theChart() {
    //   return Chart;
    // },
    maximumVisibleBars() {
      let result = this.showAllDataInCharts ? this.checkIns.statistics.numberOfTrailDays : 10;
      return result;
    },
    restDaysMessage() {
      const days = this.statistics.numberOfRestDays;
      let message = "including ";
      if (days > 0) {
        message = message + days + " rest day" + ((days > 1) ? "s" : "");
        return message;
      } 
      return null;
    },
    offTrailDaysMessage() {
      const days = this.statistics.numberOfOffTrailDays;
      let message = "excluding ";
      if (days > 0) {
        message = message + days + " day" + ((days > 1) ? "s" : "") + " off trail";
        return message;
      } 
      return null;
    },
    statistics() {
      return this.checkIns.statistics;
    }, 
    daysSinceLastCheckIn() {
      return this.checkIns.statistics.daysSinceMostRecentCheckIn;
    },
    
  },
  
  methods: {
    handleSelectedCheckInFromChart(checkIn) {
      this.$emit('selectedCheckIn', checkIn.id);
    },
    handleMoreButton() {
      this.$router.push(this.$route.path + "/statistics");
    },
  },

});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ss {
  padding: 0px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  width:100%;
  box-sizing: border-box;
}
.ss__stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  background: white;
}
.ss__grid {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: auto;
  margin-top: 10px;
  max-width: 600px;
  width: 100%;
}

.ss__charts {
  display:flex;
  flex-direction: column;
  align-items: center;
  margin-top: 160px;
  width: 100%;
}

/* .ss__cell {
  display:flex;
  border-bottom: 1px solid var(--ish-lightgrey);
} */

.ss__cell {
  overflow:hidden;
  border-right: 1px solid var(--ish-lightgrey);
}
.ss__cell:last-child {
  border-right: none;
}
/* .ss__cell:nth-last-child(-n+2) {
  border-bottom: none;
} */

.ss__chart--pie {
  width: 300px;
  height: 250px;
}

.ss__chart {
  width: 95%;
  height: 300px;
  /* background: var(--ish-extralightgrey); */
}

/* .ss__cell--topleft, .ss__cell--topright {
  border-bottom: 1px solid var(--ish-lightgrey);
}
.ss__cell--topright, .ss__cell--bottomright {
  border-left: 1px solid var(--ish-lightgrey);
} */
</style>
