<template>
  <div>
    <div class="label" v-if="label" :class="{ lightOnDark: lightOnDark }">{{ label }}</div>
    <input v-if="lines==0" :type="type" :class="{ 'is-centred': centred, underlineEffect: options.underlineEffect, hoverEffect: options.hoverEffect, blueplaceholder: lightOnDark, lightOnDark: lightOnDark }" v-model="value" :placeholder="placeholder" @input="validate" />
    <textarea v-else :rows="lines" v-model="value" :class="{ 'is-centred': centred, underlineEffect: options.underlineEffect, hoverEffect: options.hoverEffect, lightOnDark: lightOnDark }" :placeholder="placeholder" @input="validate" />
    <div v-if="validationMessage" class="validationMessage">{{ validationMessage }}</div>
  </div>
</template>

<script>

import { defineComponent } from "vue";

export default defineComponent({
  name: "TextInput",

  emits: ["update:modelValue"],

  props: {
    displayOptions: {
      type: Object,
      default: function() {
        return {
          hoverEffect: true,
          underlineEffect: true,          
        }
      }
    },
    type: {
      type: String,
      default: "text"
    },
    lightOnDark: {
      type: Boolean,
      default: false
    },
    modelValue: String,
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    lines: {
      type: Number,
      default: 0
    },
    // optional validation configuration, should return {delay, callback} where delay is milliseconds to wait between key presses
    // and callback is a promise that will be called after delay.
    validation: {
      required: false,
      type: Object,
      default: null,
    },
    centred: {
      type: Boolean,
      default: false,
      required: false,
    }
  },

  data() {
    return {
      validationDelayTimer: Object,
      validationMessage: "",
      lastValue: "",
    }
  },

  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },

    options() {
      return {
        hoverEffect: this.displayOptions.hoverEffect ?? true,
        underlineEffect: this.displayOptions.underlineEffect ?? true,
      }
    }

  },

  methods: {

    validate() {
      const vm = this;
      // set a timer to delay the check - this allows a number of keys to be pressed before we call validation callback
      if (this.validation) {
        clearTimeout(this.validationDelayTimer);
        
        this.validationDelayTimer = setInterval(function () {
          // only bother validating if the value is different.
          if (vm.value != vm.lastValue) {
            vm.lastValue = vm.value;
            console.log("?" + vm.validation.callback instanceof Promise);

            vm.validation.callback(vm.value)
              .catch( (error) => {
                console.log("ERROR: " + error);
              })
            }
          }, this.validation.delay);
        } 
    },
    
    beforeUnmount() {
      // make sure we don't have the timer still going
      clearTimeout(this.validationDelayTimer);
    }
  }
});
</script>

<style scoped>
input {
  height: 30px;
}

input.is-centred  {
  text-align:center;
}

</style>