export { COLLECTIONS, LAYERTYPE };

var COLLECTIONS = {
  CHECKINS: "check-ins",
  LAYERS: "map-layers",
  USERS: "users",
  USERS_PRIVATE: "private",
  ADVENTURES: "adventures",
  LOOKUPS: "lookups",
  SUBSCRIBERS: "subscribers",
  LOOKUPS_ACCOMMODATION: "ACCOMMODATION",
};

var LAYERTYPE = {
  DISTANCE: "DISTANCE",
  TRAIL: "TRAIL"
}