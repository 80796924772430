var Path = require('svg-path-generator');

export var constants = {
    ERROR: {
      PERMISSION_DENIED: "1",
      ADVENTURE_NOT_PUBIC: "2",
    },
    ICON_OPTIONS: {
        RED: {
            colour: "var(--ish-red)",
            size: "24px",
            background: {
                shape: "CIRCLE",
                colour: "transparent",
                size: "32px",
                borderRadius: "16px",
            },
            hover: {
                backgroundColour: "transparent",
                colour: "var(--ish-red)",
            },
        },
        BLUE: {
            colour: "white",
            size: "24px",
            background: {
                shape: "CIRCLE",
                colour: "var(--ish-blue)",
                size: "32px",
                borderRadius: "16px",
            },
            hover: {
                backgroundColour: "white",
                colour: "var(--ish-blue)",
            },
        },

        BLUE_TRANSPARENT: {
            colour: "var(--ish-lightblue)",
            size: "24px",
            background: {
                shape: "CIRCLE",
                colour: "transparent",
                size: "32px",
                borderRadius: "16px",
            },
            hover: {
                // backgroundColour: "var(--ish-bluehover)",
                colour: "white"
            },
            menu: null,
        },

        ON_WHITE_ACTIONABLE: {
            isClickable: true,
            colour: "var(--ish-darkgrey)",
            size: "24px",
            background: {
                shape: "CIRCLEE",
                colour: "transparent",
                size: "32px",
                borderRadius: "16px",
            },
            hover: {
                backgroundColour: "var(--ish-lightgrey)",
                colour: "black",
            },
        },
        ON_WHITE: {
            isClickable: false,
            colour: "var(--ish-darkgrey)",
            size: "24px",
            background: {
                shape: "CIRCLEE",
                colour: "transparent",
                size: "32px",
                borderRadius: "16px",
            },
        },

        ON_DARK: {
            colour: "white",
            size: "24px",
            background: {
                shape: "CIRCLE",
                colour: "transparent",
                size: "32px",
                borderRadius: "16px",
            },
            hover: {
                backgroundColour: "var(--ish-bluehover)",
                colour: "white"
            },
            menu: null,
        }
    },

    PATH_CAR: "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z",

    PATH_TENT:
        Path()
            .moveTo(10, 20)
            .relative().moveTo(0, -20)
            .relative().lineTo(-10, 20)
            .relative().lineTo(8, 0)
            .relative().lineTo(2, -15)
            .relative().lineTo(5, 15)
            .relative().lineTo(5, 0)
            .relative().lineTo(-10, -20)
            .end(),

    CHECKINTYPE: {
        STAY: "stay",
        START: "start",
        POI: "pointOfInterest",
        END: "end",
    },

    COLOURS: {
        BLUE: "#004aad",
        LIGHTBLUE: "#5263FA",
        ORANGE: "#ff914d",
        GREEN: "#6aa84f",
        BUTTON_TEXT_DEFAULT: "rgb(95, 99, 104)",
        BUTTON_TEXT_HOVER: "rgb(0, 0, 0)"
    },

    ICONCOLOUR: {
        DARK: "",
        LIGHT: "",
    },

    ICONSIZE: {
        SMALL: 18,
        MEDIUM: 24,
        LARGE: 36,
        HUGE: 48
    },

    MAPCONTROL_POSITION: {
        TOP_LEFT: 1,
        TOP_CENTRE: 2,
        TOP_RIGHT: 3,
        LEFT_CENTRE: 4,
        LEFT_TOP: 5,
        LEFT_BOTTOM: 6,
        RIGHT_TOP: 7,
        RIGHT_CENTRE: 8,
        RIGHT_BOTTOM: 9,
        //TOP_RIGHT: 9,
    },

    MARKERS: {

        START: {
            default: 'data:image/svg+xml;utf-8, \
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 192 192" width="32" height="32"> \
                    <path opacity="1" fill="rgb(56,118,29)" fill-opacity="1" d="M162 69.67C162 34.5 132.45 6 96 6C59.55 6 30 34.5 30 69.67C30 104.83 58.21 132.39 94.66 186C131.11 132.39 162 104.83 162 69.67Z"></path> \
                  </svg>',
            highlighted: 'data:image/svg+xml;utf-8, \
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 192 192" width="32" height="32"> \
                      <path opacity="1" fill="rgb(255,145,77)" fill-opacity="1" d="M162 69.67C162 34.5 132.45 6 96 6C59.55 6 30 34.5 30 69.67C30 104.83 58.21 132.39 94.66 186C131.11 132.39 162 104.83 162 69.67Z" id="l48soiLhyR"></path> \
                  </svg>',
        },
        DEFAULT: {
            default: 'data:image/svg+xml;utf-8, \
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 192 192" width="32" height="32"> \
                    <path opacity="1" fill="rgb(0,74,173)" fill-opacity="1" d="M162 69.67C162 34.5 132.45 6 96 6C59.55 6 30 34.5 30 69.67C30 104.83 58.21 132.39 94.66 186C131.11 132.39 162 104.83 162 69.67Z"></path> \
                  </svg>',
            highlighted: 'data:image/svg+xml;utf-8, \
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 192 192" width="32" height="32"> \
                      <path opacity="1" fill="rgb(255,145,77)" fill-opacity="1" d="M162 69.67C162 34.5 132.45 6 96 6C59.55 6 30 34.5 30 69.67C30 104.83 58.21 132.39 94.66 186C131.11 132.39 162 104.83 162 69.67Z" id="l48soiLhyR"></path> \
                  </svg>',

        },
        CURRENT_LOCATION: {
            default: 'data:image/svg+xml;utf-8, \
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 192 192" width="32" height="32"> \
                    <path opacity="1" fill="rgb(0,74,173)" fill-opacity="0.33" stroke-width="0" stroke-opacity="1" d="M192 96C192 148.98 148.98 192 96 192C43.02 192 0 148.98 0 96C0 43.02 43.02 0 96 0C148.98 0 192 43.02 192 96Z" id="aYhvUYpQW"/> \
                    <path opacity="1" fill="rgb(0,74,173)" fill-opacity="1" stroke="rgb(255,255,255)" stroke-width="20" d="M148.15 96C148.15 125.06 124.78 148.65 96 148.65C67.22 148.65 43.85 125.06 43.85 96C43.85 66.94 67.22 43.35 96 43.35C124.78 43.35 148.15 66.94 148.15 96Z"/> \
                  </svg>'
        },
        TENT: {
            default: 'data:image/svg+xml;utf-8, \
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 192 192" width="32" height="32"> \
                      <path opacity="1" fill="rgb(0,74,173)" fill-opacity="1" d="M162 69.67C162 34.5 132.45 6 96 6C59.55 6 30 34.5 30 69.67C30 104.83 58.21 132.39 94.66 186C131.11 132.39 162 104.83 162 69.67Z"></path> \
                      <path opacity="1" fill="white" fill-opacity="1" d="M130.27 113.1L110.84 113.25C104.09 87.34 99.87 71.15 98.19 64.67C98.18 64.63 98.12 64.63 98.11 64.67C96.94 71.15 93.99 87.34 89.28 113.25L61.73 113.1L96 36L130.27 113.1Z"></path> \
                  </svg>',
            highlighted: 'data:image/svg+xml;utf-8, \
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 192 192" width="32" height="32"> \
                      <path opacity="1" fill="rgb(255,145,77)" fill-opacity="1" d="M162 69.67C162 34.5 132.45 6 96 6C59.55 6 30 34.5 30 69.67C30 104.83 58.21 132.39 94.66 186C131.11 132.39 162 104.83 162 69.67Z" id="l48soiLhyR"></path> \
                      <path opacity="1" fill="white" fill-opacity="1" d="M130.27 113.1L110.84 113.25C104.09 87.34 99.87 71.15 98.19 64.67C98.18 64.63 98.12 64.63 98.11 64.67C96.94 71.15 93.99 87.34 89.28 113.25L61.73 113.1L96 36L130.27 113.1Z" id="a2dlSGpYFQ"></path> \
                  </svg>',
        },

    },

    DISPLAYMODE: {
        // Edit mode, only available to authenticated users
        EDIT: 0,
        // View mode for public display
        VIEW: 1,
        // Same as View mode, but gives authenticated users a few other options.
        PREVIEW: 2,
    }
}