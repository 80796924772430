
export default class ShortcutManager {

    /**
     * Constructor
     */
    constructor() {
        this.listeners = [];
        this._registerKeyDownListener();
    }

    _indexOfListener(name) {
        return this.listeners.findIndex( (listener) => {
            return listener.name == name;
        })
    }

    _registerKeyDownListener() {
        const vm = this;
        document.addEventListener('keydown', (evt) => {
            vm._keyDownListener(evt);
        });
    }
    
    _keyDownListener(evt) {
        
        // find all the listeners that are listening to this key
        let triggeredListeners = this.listeners.filter( (listener) => {
            return listener.listenTo.includes(evt.key) || listener.listenTo.includes(evt.keyCode);
        });
        // starting from the latest listener, let the listeners know the key was pressed, until we reach a blocking listener 
        // or have notified all listeners.
        for (let i = triggeredListeners.length - 1; i >= 0; i--) {

            let listener = triggeredListeners[i];
            listener.callback(evt.key);
            if (listener.block) {
                break;    
            } else {
                console.log('dont block');
            }
        }
    }

    /**
     * Add a new shortcut listener callback
     * @param {String} name a unique name to give the listener
     * @param {callback} callback the method to call when key being listened for is pressed
     * @param {Array} listenTo array of strings or numbers representing the shortcut key or keycode to be notified about
     * @param {Boolean} block optional flag that determines whether to block other listeners, further down the stack, from being notified. Default is true.
     */
    addListener(name, callback, listenTo, block) {
        
        if (this._indexOfListener(name) >= 0) {
            throw `${name} has already been registered with another listener`;
        } else {
            this.listeners.push(
                {
                    name: name,
                    callback: callback,
                    listenTo: listenTo,
                    block: block ?? true,
                }
            );
        }
    }

    removeListener(name) {
        let index = this._indexOfListener(name);
        if (index >= 0) {
            this.listeners.splice(index, 1);
        }
    }
}