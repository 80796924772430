export { deleteCheckIn, updateCheckIn, getCheckIn, getCheckIns, addCheckIn, updateCheckInLocation };

import { COLLECTIONS } from "./firestoreConstants.js";
import { db, storage } from "./firebaseInit.js";
import CheckInArray from "../api/classes/CheckinArray";
import { checkInConverter } from "./classes/CheckIn.js";

// const registerServerListener = function(callback) {
//   db.collection(COLLECTIONS.CHECKINS)
//     .onSnapshot((querySnapshot) => {
//       console.log("onSnapshot");  
//       let updatedCheckIns = [];
//       querySnapshot.forEach((doc) => {
//         updatedCheckIns.push(doc.data());
//       });
//       callback(updatedCheckIns);
//     });
// }

const getCheckIn = function(id) {
  return new Promise(function (resolve, reject) {
    db.collection(COLLECTIONS.CHECKINS)
      .doc(id)
      .withConverter(checkInConverter)
      .get()
      .then( function(doc) {
        resolve(doc.data());
      }).catch(
        function(error) {
          reject(error);
        }
      )
  });
}

const getPublicCheckIns = function(adventureId) {
  return new Promise(function (resolve, reject) {
    db.collection(COLLECTIONS.CHECKINS)
      .where("adventureId", "==", adventureId)
      .orderBy("date")
      .withConverter(checkInConverter)
      .get()
      .then(function (querySnapshot) {
        const result = new CheckInArray();
        querySnapshot.forEach(function (doc) {
          result.insertByDate(doc.data());
        })

        // mark the latest checkin (assumes date ordering is ascending! this is controlled by Firestore index)
        // TODO_ don't think we need this anymore
        // if (result.length != 0) {
        //   result[result.length - 1].isLatest = true;
        // }
        resolve(result);
      })
      .catch(function (error) {
        reject("Error reading Checkin " + error);
      })
  
});
}

/**
 * Returns a CheckInArray instance
 * @param {*} uid - the user id of user who owns the adventure
 * @param {*} adventureId 
 * @returns 
 */
const getCheckIns = function(uid, adventureId) {
    if (uid) {
      return new Promise(function (resolve, reject) {
          db.collection(COLLECTIONS.CHECKINS)
            .where("adventureId", "==", adventureId)
            .where("uid", "==", uid)
            .orderBy("date")
            .withConverter(checkInConverter)
            .get()
            .then(function (querySnapshot) {
              const result = new CheckInArray();
              querySnapshot.forEach(function (doc) {
                result.insertByDate(doc.data());
              })
              resolve(result);
            })
            .catch(function (error) {
              reject("Error reading Checkin " + error);
            })
        
      });
    }  else {
      return getPublicCheckIns(adventureId);
    }
  
};

/**
 * Add a new checkIn to the data store. 
 * 
 * Returns: promise, that if successful will contain the added CheckIn, including it's id.
 * 
 * @param {CheckIn} checkIn 
 */
const addCheckIn = function (checkIn) {
  return new Promise(function (resolve, reject) {
    if (checkIn.uid) {
      db.collection(COLLECTIONS.CHECKINS)
        .withConverter(checkInConverter)
        .add(checkIn, { merge: true })
        .then(function (docRef) {
          // could be safer to retrieve the data back to get the CheckIn instance...?
          let checkInSaved = checkIn;
          checkInSaved.id = docRef.id;
          resolve(checkInSaved);
        }).catch(function (error) {
          reject(error);
        });
    } else {
      reject("Check in records can't be added without a uid");
    }
  });
}


/*
* Update only the location of a checkin
*/
const updateCheckInLocation = function (id, location) {
  return updateCheckIn( { id: id, location: location} );
}


/**
 * Update all supplied properties of a checkin
 * 
 * @param {CheckIn} checkIn 
 */
const updateCheckIn = function(checkIn) {
  return new Promise(function (resolve, reject) {
    const id = checkIn.id;
    if (!id) {
      reject("Can't update : missing id");
    } else {
      db.collection(COLLECTIONS.CHECKINS)
        .doc(id)
        .withConverter(checkInConverter)
        .set(checkIn, { merge: true })
        .then(function () {
          checkIn._isDirty = false;
          resolve(checkIn);
        }).catch(function (error) {
          reject(error);
        });
    }
  });  
}

/**
 * Deletes Checkin from the data store
 * @param {String} id 
 */
const deleteCheckIn = function(checkIn) {
  return new Promise(function (resolve, reject) {
    if (!checkIn.id) { reject("Can't delete - CheckIn id is missing"); }
    // delete images from storage
    deleteImages(checkIn)
      .then ( () => {
        // delete checkin from firestore
        db.collection(COLLECTIONS.CHECKINS).doc(checkIn.id).delete()
          .then(function () {
            resolve();
          })
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

/**
 * 
 * @param {*} checkIn 
 * @param {*} imageIndex 
 */
const deleteImages = function(checkIn) {
  return new Promise(function (resolve, reject) {
    
    if (checkIn.images && checkIn.images.length > 0) {
      checkIn.images.forEach( (image) => {
        let ref = storage.ref().child(image.storagePath);
        ref.delete()
          .then(() => {
            resolve();
            })
          .catch((error) => {
            // Uh-oh, an error occurred!
            reject(error);
          });
      })
    } else {
      resolve();
    }
  });
}