<template>
  <div class="login">
    <header-main :hideButtons="true" @logoClick="$router.push('/')"></header-main>
    <div class="login__ui">
      <div id="firebaseui-auth-container"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { auth, firebase } from "../../api/firebaseInit";
import { ui } from "../../api/firebaseInitUI";
import { recordUserLogin } from "../../api/firestoreUser";
// import { User } from "../../api/classes/User";
import HeaderMain from "../controls/Header";
import 'firebaseui/dist/firebaseui.css';
import { UrlBuilder } from "../../api/classes/UrlBuilder";

const { DateTime } = require("luxon");

export default defineComponent({
  name: "Login",
  title: "Login",
  components: {
    HeaderMain,
  },

  setup() {
    auth.signOut();
  },

  computed: {
    
    uiConfig() {
      const vm = this;
      return {
        signInFlow: "popup",
        //signInSuccessUrl: "/tokes",
        signInOptions: [
          // Leave the lines as is for the providers you want to offer your users.
          {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            fullLabel: "Log in with Google",
          },
          {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
            fullLabel: "Log in with email",
            buttonColor: "#ff914d", //"#32a5d3",
          },
        ],
        // Terms of service url/callback.
        tosUrl: "/TermsOfService",
        // Privacy policy url/callback.
        privacyPolicyUrl: function () {
          vm.$router.push("/Privacy");
        },
        callbacks: {
          signInSuccessWithAuthResult: function (authResult, redirectUrl) {
            recordUserLogin(authResult.user.uid, DateTime.local())
              .then( (user) => {
                 if (user.username) {
                   if (user.private.lastAdventureId) {
                     vm.$router.push(UrlBuilder.urlForDashboard(user.username, user.private.lastAdventureId));
                   } else {
                     vm.$router.push(UrlBuilder.urlForUserHome(user.username));
                   }
                } else {
                  // need to finish signing up
                  vm.$router.push(`/signup2`);
                }
              })
              .catch ((error) => {
                console.log(error);
              })
            // don't automatically redirect
            return false;
          },
          // signInFailure callback must be provided to handle merge conflicts which
          // occur when an existing credential is linked to an anonymous user.
          signInFailure: function (error) {
            console.log(error);
          },
        },
      };
    },
  },

  mounted() {
    ui.start("#firebaseui-auth-container", this.uiConfig);
  },
});
</script>

<style>
.firebaseui-title {
  display: none;
}
</style>

<style scoped>

#firebaseui-auth-container {
  background-color: white;
  border-radius: 5px;
  width: 300px;
  max-width: 90%;
} 

.login {
  height: 100%;
}

.login__ui {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>

