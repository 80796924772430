<template>
  <div class="anonWrapper">
    <img @click="$router.push('welcome')" src="../../../assets/logo.png" />
    <div class="content">      
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AnonPageWrapper",
});

</script>

<style scoped>

.anonWrapper {
  height:100%;
  background-color: #004aad;
  display:flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 100px;
}

.content {
  position:relative;
  width: 80%;
}

img {
  width:40%;
  padding-bottom: 30px;
  
}
</style>