import CheckInArray from "./classes/CheckinArray";
import { CheckInStatistics } from "./classes/CheckInStatistics";
import * as statistics from "./statistics.js";

export { getAccommodationData, getDistanceData };


/**
 * Returns { labels:[], data:[], runningAverage[], rollingAverage[], cummulativeData[], footerText[], checkIns[]  } where
 *  labels represent the day number
 *  data is the distance travelled
 *  cummulativeData is the cummulative distance
 *  footerText is the place name
 *  checkIns are the checkin instance for each point
 * 
 * @param {CheckInArray} checkIns
 */
const getDistanceData = function (checkIns) {
  return new Promise(function (resolve, reject) {
    
    // OK, so this is tough to read but essentially flattens the distances and rest days into a single array of distances
    // for each trail day. 
    let distances = checkIns.map( c => [c.distanceWalked].concat(Array(c.numberOfRestDays).fill(0))).flat();
    let data = [...distances];

    let labels = Array(checkIns.statistics.numberOfTrailDays + 1).fill().map((element, index) => index)
    let footerText = checkIns.map(c => [(c.title ?? "") + (c.distanceWalked == 0 ? " (transit)" : "")].concat(Array(c.numberOfRestDays).fill(c.title + " (rest)"))).flat();
    let cummulativeDistanceWalked = statistics.runningTotal(distances);

    distances.shift(); // remove the first checkin (start) for averages
    let rawAverageDailyDistance = [null, ...statistics.runningAverage(distances).map(a => a.toFixed(0))];
    let averageDailyWalkingDistance = [null, ...statistics.runningAverage(distances, function (val) { return val != 0 }).map(a => a.toFixed(0))];
    
    resolve({ labels: labels, data: data, rawAverageDailyDistance: rawAverageDailyDistance, averageDailyWalkingDistance: averageDailyWalkingDistance, cummulativeDistanceWalked: cummulativeDistanceWalked, footerText: footerText });
  });
}


/**
 * Returns { labels:[], data:[] } for each accommodation type
 * 
 * @param {CheckInArray} checkIns
 */
const getAccommodationData = function (checkIns) {
  return new Promise(function (resolve, reject) {
    console.log('h');
    let labels = [];
    let data = [];
    
    // labels
    checkIns.forEach(checkIn => {
      if (checkIn.accommodation && checkIn.type != 'start') {
        if (labels.indexOf(checkIn.accommodation.value) < 0) {
          labels.push(checkIn.accommodation.value);
        }
      }
    })
    
    // data
    for (var i = 0; i < labels.length; i++) {
      data[i] = checkIns.filter(item => {
        return (item.accommodation && item.accommodation.value == labels[i]);
      }).length;
    }
    
    resolve({ labels: labels.map ( l => { return l == undefined ? "Not Set" : l }), data: data });

  });
}