<template>
  <div class="logo">
    <img v-if="colour == 'blue'" src="../../../assets/logo_blue.png"/>
    <img v-else src="../../../assets/i-stayed-here-logo-white.png"/>
  </div>    
</template>

<script>
export default {
  props: {
    colour: {
      type: String,
      default: 'white',
    },
  }
}
</script>

<style scoped>

.logo {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.logo img {
  float: left;
  width: 200px;
  margin-left: 20px;
}

.logoText {
  left: -60px;
  top: -5px;
  margin-top:10px;
  color: white;
  font-size: 1em;
  white-space: nowrap;
}

@media screen and (max-width: 650px) {

  .logo img {
    width: 150px;
  }

  .logoText {
    font-size: 0.8em;
    /* width:20%; */
  }
}
</style>