<template>
  <div class="stats">
    <div>
      <span class="stats--highlight"
        >{{ checkIn.distanceWalked }}km</span
      ><span class="stats--light"> day</span>
    </div>
    <div class="stats__acc" v-if="checkIn.accommodation">
      <icon
        class="stats__acc--img"
        :rotate="checkIn.accommodation.imageRotation"
        :tooltip="checkIn.accommodation.value"
        :options="accomodationIconOptions"
      >
        {{ checkIn.accommodation.imageName }}</icon
      >
      <div class="stats__acc--text">{{ checkIn.accommodation.value }}</div>
    </div>
    <div>
      <span class="stats--light">total </span
      ><span class="stats--highlight"
        >{{ distanceTravelledSoFar }}km</span
      >
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { constants } from "../../constants";

import { CheckIn } from "../../api/classes/CheckIn";

import Icon from "../../components/controls/Icon.vue";

export default defineComponent({
  name: "side-bar-day-stats",
  components: {
    Icon,
  },
  props: {
    checkIn: CheckIn,
    distanceTravelledSoFar: Number,
  },
  computed: {
    accomodationIconOptions() {
      return constants.ICON_OPTIONS.ON_WHITE;
    },
  },
});
</script>

<style scoped>
.stats {
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.stats--light {
  color: var(--ish-mediumgrey);
}

.stats--highlight {
  color: var(--ish-darkgrey);
  font-size: 1.2em;
  font-weight: bold;
}

.stats__acc {
  display:flex;
  flex-direction: column;
  align-items: center;
}
 
.stats__acc--text {
  font-size: var(--ish-font-size-small);
  color: var(--ish-mediumgrey);
  text-transform: lowercase;
}

</style>