<template>
    <div class="segment">
        <base-button
            class="segment__button"
            :isTransparent="selectedIndex != i"
            v-for="(item, i) in items"
            :key="item"
            @click="handleItemClick(i)"
            >{{ item.label }}</base-button
        >
    </div>
</template>

<script>
import { defineComponent } from "vue";

import BaseButton from "./BaseButton.vue";

export default defineComponent({
    components: { BaseButton },
    emits: ["itemSelected"],
    props: {
        items: Array,
    },
    data() {
        return {
            selectedIndex: this.items.findIndex((item) => item.selected),
        };
    },

    methods: {
        handleItemClick(index) {
            this.selectedIndex = index;
            this.$emit("itemSelected", this.items[index]);
        },
    },
});
</script>

<style scoped>
.segment {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: var(--ish-extralightgrey);
    border-radius: 3px;
    padding: 10px;
}

.segment__button {
    width: 30%;
    margin: 0px;
}
</style>