<template>
  <GoogleMapMarker 
    :map="map" 
    :google="google" 
    :options="options"
    @click="handleClick"
    @dragend="handleDragEnd">
  </GoogleMapMarker>
</template>

<script>
import { defineComponent } from "vue";
//import GoogleMarkerWithLabel from "../maps/GoogleMarkerWithLabel"
import GoogleMapMarker from "../maps/GoogleMapMarker"
import { CheckIn } from "../../api/classes/CheckIn";
import { constants } from "../../constants";

export default defineComponent({
  name: "CheckInMarker",
  
  emits: ["click", "moved"],
  
  components: {
    GoogleMapMarker,
  },

  props: {
    google: Object,
    map: Object,
    checkIn: CheckIn,
    daysOnTrail: {
      type: Number,
      default: 0
    },
    selected: Boolean,
    canMove: Boolean,
  },

  computed: {
    isStart() {
      return this.checkIn.type == 'start';
    },
    options() {
      return {
        position: this.checkIn.location.toGoogleMapLocation(),
        icon: {
          url: this.selected ? constants.MARKERS.START.highlighted : this.isStart ? constants.MARKERS.START.default : constants.MARKERS.DEFAULT.default,
          labelOrigin: new this.google.maps.Point(16, 12)
        },
        label: {
          color: "white",
          text: this.isStart ? "S" : this.daysOnTrail.toString(),
          //text: this.checkIn.accommodation.imageName, // codepoint from https://fonts.google.com/icons
          // fontFamily: "Material Icons",
          // fontSize: "14px",
          fontSize: "10px",
        },
        // labelAnchor: new this.google.maps.Point(this.marginForText(this.daysOnTrail.toString()), 25),
        draggable: this.canMove,
        zIndex: 100,
      }
    }
  },

  methods: {
    marginForText(text) {
      const left = text.length == 1 ? 3 : (text.length == 2 ? 5 : 7);
      return left;
    },

    handleClick() {
      this.$emit('click', this.checkIn);
    },

    handleDragEnd(location) {
      this.$emit('moved', { checkIn: this.checkIn, location: location } );
    },
  },

  render() {
    return null;
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.checkInMarkerLabel {
  color: white;
  font-size: 10px;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

</style>
