<template>
  <div
    id="sidebar"
    class="sidebar"
    :class="{
      minimize: isMinimised,
      maximize: isMaximised,
      edit: !readOnly,
    }"
  >
    <div
      ref="sidebar__title"
      class="sidebar__title"
      @click="handleControlBarClick"
    >
      <div class="controlBar">
        <div class="left-controls">
          <icon
            class="control"
            v-if="!isStatisticsView && !isFullScreen"
            :options="siderBarMenuOptions"
            @menuClick="handleSideBarMenuClick"
            >more_vert
          </icon>
          <icon
            class="control"
            v-else
            :options="iconOptions"
            @click="
              handleSidebarResize('maximise');
              $emit('clickedExitGraphs');
            "
            >arrow_back</icon
          >
        </div>
        <h2>
          {{ title }}
        </h2>

        <div class="right-controls">
          <icon
            class="control"
            :options="iconOptions"
            @click="handleSidebarResize('minimise')"
            v-if="!isStatisticsView && isMaximised && !isFullScreen"
            >minimize</icon
          >

          <icon
            class="control"
            :options="iconOptions"
            @click="handleSidebarResize('maximise')"
            v-if="!isStatisticsView && isMinimised && !isFullScreen"
            >maximize</icon
          >

          <icon
            v-if="!isCheckInView && isFullScreen && !isMobile()"
            class="control"
            :options="iconOptions"
            @click="handleSidebarResize('maximise')"
            >close_fullscreen</icon
          >

          <icon
            v-if="!isCheckInView && isMaximised && !isMobile()"
            class="control"
            :options="iconOptions"
            @click="handleSidebarResize('fullscreen')"
            >open_in_full</icon
          >

          <icon class="control" :options="iconOptions" @click="handleClose"
            >close</icon
          >
        </div>
      </div>
      <h1 v-if="subTitle" :title="subTitle" class="subtitle">{{ subTitle }}</h1>
    </div>
    <div class="sidebarContent">
      <CheckInNavigator
        v-if="sidebarType == 'CHECKIN'"
        :readOnly="readOnly"
        :showCheckInMenu="showCheckInMenu"
        :allCheckIns="allCheckIns"
        @navigated="handleCheckInNavigatorNavigated"
      >
      </CheckInNavigator>

      <side-bar-day-stats
        v-if="sidebarType == 'CHECKIN'"
        :checkIn="currentCheckIn"
        :distanceTravelledSoFar="
          allCheckIns.statistics[currentCheckIn.id].distanceTravelledSoFar
        ">
      </side-bar-day-stats>

      <SidebarCheckIn
        v-if="!readOnly && sidebarType == 'CHECKIN'"
        class="sidebarCheckIn"
        v-model="currentCheckIn"
        :distanceTravelledSoFar="
          allCheckIns.statistics[currentCheckIn.id].distanceTravelledSoFar
        "
        :adventure="adventure"
        :readOnly="readOnly"
        @cancel="handleClose"
        @saved="handleSaved"
        @swipe="handleSwipe"
      >
      </SidebarCheckIn>
      <sidebar-check-in-display
        v-if="readOnly && sidebarType == 'CHECKIN'"
        :checkIn="currentCheckIn"
        class="sidebarCheckIn"
        @swipe="handleSwipe"
      >
      </sidebar-check-in-display>

      <sidebar-statistics
        v-if="sidebarType == 'STATISTICS'"
        :checkIns="allCheckIns"
        :showAllDataInCharts="isFullScreen"
        @selectedCheckIn="handleCheckInSelected"
      >
      </sidebar-statistics>
    </div>

    <modal-dialog
      v-if="showDeleteAlert"
      title="Delete"
      :actions="deleteCheckInAlertActions"
      @close="showDeleteAlert = false"
    >
      <p v-html="deleteMessage"></p>
      <p>There's no turning back!</p>
    </modal-dialog>

    <export
      v-if="showExportWarning"
      :checkIns="allCheckIns"
      @close="showExportWarning = false"
      >
    </export>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapMutations } from "vuex";
import { mapState } from "vuex";

import SidebarCheckIn from "./SidebarCheckIn.vue";
import Icon from "../controls/Icon";
import CheckInNavigator from "./CheckInNavigator";

import CheckInArray from "../../api/classes/CheckinArray";
import { Adventure } from "../../api/classes/Adventure";
import { deleteCheckIn } from "../../api/firestoreCheckIn";
import { analytics } from "../../api/firebaseInit";

import { constants } from "../../constants";

import SidebarCheckInDisplay from "./SidebarCheckInDisplay.vue";
import ModalDialog from "../controls/ModalDialog.vue";
import SidebarStatistics from "./SidebarStatistics.vue";
import SideBarDayStats from "./SideBarDayStats.vue";
import Export from "../pages/modal/Export.vue"

var Hammer = require("hammerjs");
const { DateTime } = require("luxon");

export default defineComponent({
  name: "Sidebar",
  
  components: {
    SidebarCheckIn,
    CheckInNavigator,
    Icon,
    SidebarCheckInDisplay,
    ModalDialog,
    SidebarStatistics,
    SideBarDayStats,
    Export,
  },

  emits: [
    "close",
    "saved",
    "didNavigate",
    "clickedExitGraphs",
    "showShare",
    "showStatistics",
  ],

  data() {
    const vm = this;
    return {
      accommodationLookup: Array,
      isDragging: false,
      lastPosY: 0,
      isMinimised: true,
      isMaximised: false,
      isFullScreen: false,
      showDeleteAlert: false,
      showExportWarning: false,
      deleteCheckInAlertActions: [
        {
          id: 0,
          title: "Cancel",
          isSecondary: true,
          handle: () => {
            vm.showDeleteAlert = false;
          },
        },
        {
          id: 1,
          title: "Delete",
          isDestructive: true,
          handle: (action) => {
            action.showSpinner = true;
            vm.handleDelete(action)
              .then(() => {
                action.showSpinner = false;
                vm.showDeleteAlert = false;
              })
              .catch((error) => {
                console.log(error);
              });
          },
        },
      ],
    };
  },

  props: {
    adventure: Adventure,
    sidebarType: String,
    allCheckIns: CheckInArray,
    readOnly: Boolean,
  },

  watch: {
    sidebarType() {
      if (this.sidebarType == "STATISTICS") {
        // make sure we are always maximised
        this.handleSidebarResize("maximise");
      }
    },
    currentCheckIn() {
      this.logSelectCheckInEvent();
    },
  },

  computed: {
    ...mapState({
      user: "user",
      loggedIn: "loggedIn",
    }),
    isOwner() {
      return this.loggedIn && this.user.username == this.$route.params.username;
    },
    siderBarMenuOptions() {
      let options = { ...this.iconOptions };
      options.menu = {
        rightAligned: false,
        menuItems: [
          {
            id: 0,
            name: "Share",
            show: this.isCheckInView,
            iconName: "share",
          },
          {
            id: 1,
            name: "Dashboard",
            iconName: "bar_chart",
          },
          { isDivider: true, show: !this.readOnly && this.isOwner },
          {
            id: 3,
            name: "Export",
            iconName: "file_download",
          },
          { isDivider: true, show: !this.readOnly && this.isOwner },
          {
            id: 2,
            name: "Delete",
            show: !this.readOnly && this.isOwner,
            iconName: "delete_outline",
          },
        ],
      };
      return options;
    },
    deleteMessage() {
      let checkInTitle = this.allCheckIns.current.title;
      if (checkInTitle) {
        return `Are you sure you want to delete your check in at <b>${checkInTitle}</b>?`;
      } else {
        return `Are you sure you want to delete this checkin?`;
      }
    },
    isCheckInView() {
      return this.sidebarType == "CHECKIN";
    },

    isStatisticsView() {
      return this.sidebarType == "STATISTICS";
    },

    title() {
      if (this.isCheckInView) {
        if (this.currentCheckIn.type == "start") {
          return "";
        } else {
          return this.allCheckIns.statistics[this.currentCheckIn.id]
            .daysOnTrailDescription;
        }
      } else if (this.isStatisticsView) {
        return "Dashboard";
      }
      return "";
    },
    subTitle() {
      if (this.isCheckInView) {
        return this.currentCheckIn.title ?? "Unnamed Location";
      }
      return this.adventure.name;
    },

    iconOptions() {
      const options = constants.ICON_OPTIONS.ON_WHITE_ACTIONABLE;
      options.background.borderRadius = "25px";
      options.size = "24px";
      options.background.size = "50px";
      return options;
    },

    showCheckInMenu() {
      return !this.isMaximised;
    },

    currentCheckIn() {
      return this.allCheckIns.current;
    },
  },

  mounted() {
    // log the initial checkin that's displayed
    this.logSelectCheckInEvent();
    this.registerGestureHandlers();
    if (this.isCheckInView) {
      this.handleSidebarResize("minimise");
    } else {
      this.handleSidebarResize("maximise");
    }
    this.addShortcutListener({
      name: "sidebar-esc",
      callback: this.handleClose,
      listenTo: ["esc", "Escape", 27],
    });
  },

  unmounted() {
    this.removeShortcutListener("sidebar-esc");
  },

  methods: {
    ...mapMutations({
      addShortcutListener: "ADD_SHORTCUT_LISTENER",
      removeShortcutListener: "REMOVE_SHORTCUT_LISTENER",
    }),

    logSelectCheckInEvent() {
      if (!this.isOwner) {
        let title = (this.title.length > 0) ? this.title : "Start";
        analytics.logEvent("select_checkin",
          {
            checkin_date: this.currentCheckIn.date.toISODate({ format: 'basic' }),
            checkin_description: title,
            adventure_id: this.adventure.id,
          })
      }
    },  
    handleSideBarMenuClick(menu) {
      if (menu.id == 0) {
        this.$emit("showShare");
      } else if (menu.id == 1) {
        this.$emit("showStatistics");
      } else if (menu.id == 2) {
        this.showDeleteAlert = true;
      } else if (menu.id == 3) {
        this.exportCheckIns();
      }

    },
    daysOnTrailDescription(checkIn) {
      let dayDescription =
        this.allCheckIns.statistics[checkIn.id].daysOnTrailDescription;
      return dayDescription + " - " + checkIn.title;
    },
    isMobile() {
      return screen.width <= 760;
    },
    handleControlBarClick() {
      console.log("CONTROL BAR CLICK");
      if (!this.isFullScreen && !this.isStatisticsView) {
        this.handleSidebarResize(this.isMinimised ? "maximise" : "minimise");
      }
    },

    handleSwipe(type) {
      if (this.isMinimised && type == "swipeup") {
        this.handleSidebarResize("maximise");
      } else if (this.isMaximised && type == "swipedown") {
        this.handleSidebarResize("minimise");
      }
    },

    registerGestureHandlers() {
      if (Hammer) {
        const vm = this;
        var mc = new Hammer(this.$refs.sidebar__title);
        mc.get("swipe").set({ direction: Hammer.DIRECTION_VERTICAL });
        mc.on("swipeup swipedown", function (ev) {
          vm.handleSwipe(ev.type);
        });
      }
    },

    handleDrag(ev) {
      var sidebar = document.getElementById("sidebar");
      if (!this.isDragging) {
        this.isDragging = true;
        this.lastPosY = sidebar.offsetHeight;
      }
      var posY = -ev.deltaY + this.lastPosY;
      sidebar.style.height = posY + "px";
    },

    handleSidebarResize(resize) {
      var sidebar = document.getElementById("sidebar");
      if (resize == "minimise") {
        sidebar.style.height = this.isStatisticsView ? "195px" : "305px";
        this.isMaximised = false;
        this.isMinimised = true;
        this.isFullScreen = false;
      } else if (resize == "maximise") {
        sidebar.style.height = "99%";
        sidebar.style.width = "408px";
        this.isMaximised = true;
        this.isMinimised = false;
        this.isFullScreen = false;
      } else if (resize == "fullscreen") {
        sidebar.style.width = "100%";
        this.isFullScreen = true;
        this.isMaximised = false;
        this.isMinimised = false;
      }
    },

    handleCheckInNavigatorNavigated() {
      this.$emit("didNavigate");

      // required to allow sibling components to respond to navigation event
      this.$bus.$emit("didNavigateGlobal");
    },

    handleCheckInSelected(checkIn) {
      this.allCheckIns.setCurrentById(checkIn);
      this.$emit("didNavigate");
    },

    handleClose() {
      this.$emit("close");
    },

    handleSaved(savedData) {
      this.$emit("saved", savedData);
      this.$emit("close");
    },

    handleDeleteRequest() {
      this.showDeleteAlert = true;
    },

    handleDelete() {
      // this will delete record from firestore and it's images from storage
      const vm = this;
      return new Promise(function (resolve, reject) {
        deleteCheckIn(vm.allCheckIns.current)
          .then(() => {
            vm.allCheckIns.removeById(vm.allCheckIns.current.id);
            if (vm.allCheckIns.length == 0) {
              vm.$emit("close");
            }
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    exportCheckIns() {
      this.showExportWarning = true;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.controlBar {
  background-color: var(--ish-ligtgrey);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
  height:45px;
}

.controlBar .right-controls {
  justify-content: flex-end;
  margin-right: 10px;
}

.controlBar .left-controls {
  margin-left: 10px;
}
.controlBar .right-controls,
.controlBar .left-controls {
  width: 25%;
  display: flex;
}

/* .titleGroup {
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: center;
} */
.subtitle {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left:10px;
  margin-right:10px;
}
.sidebar {
  background-color: white;
  border-radius: 10px 10px;
  transition: height 400ms, width 400ms;
  width: 408px;
  /* min-width: 350px; */
  /* max-width:500px; */
  height: 99%;
  bottom: 0px;
  position: absolute;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
  padding-top: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.sidebarContent {
  background-color: white;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

@keyframes enter {
  0% {
    left: -140px;
    opacity: 0;
  }
  100% {
    left: 0px;
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .sidebar {
    width: 100% !important;
  }
}

.sidebarCheckIn,
.sidebarCheckInDisplay,
.sidebarCheckInTable {
  width: 100%;
  bottom: 0px;
  overflow-y: scroll;
  box-sizing: border-box;
  flex-grow: 1;
}
</style>
