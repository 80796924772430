<template>
  <div class="admin">
    <header-main></header-main>
    <h1>Admin</h1>
    <h2>KML Uploads</h2>
    <h3>TA Trail Version (yyyymmdd)</h3>
    <base-input class="version"></base-input>
    <h3>Trail Files (multi-select)</h3>
    <input type="file" id="uploaderTrail"  accept=".kml" ref="uploaderTrail" name="filenameTrail" multiple="true" />
    <h3>Distance Markers</h3>
      <input type="file" id="uploaderDistance"  ref="uploaderDistance" name="filenameDistance" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import BaseInput from "../controls/BaseInput.vue";
import HeaderMain from "../controls/Header.vue";

export default defineComponent({
  name: "Admin",
  components: {
    HeaderMain,
    BaseInput,
  },

  mounted() {
    this.$refs.uploaderDistance.addEventListener("change", this.handleFiles, false);
    this.$refs.uploaderDistance.addEventListener("loadend", this.handleUploadComplete);
    this.$refs.uploaderDistance.addEventListener("progress", this.handleUploadProgress);
  },

  methods: {
    handleFiles() {
      console.log("handleFiles");
    },
    handleUploadComplete() {
      console.log("handleUploadComplete");
    },
    handleUploadProgress(event) {
      console.log("Progress" + event);
    }
    
  },
});
</script>

<style scoped>
.admin {
  padding-top: 100px;
  padding-left: 30px;
  padding-right: 30px;
  background: white;
  height: 100%;
}

.version {
  width: 100px;
}
</style>