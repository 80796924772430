<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "GoogleMapControl",

  emits: ["click"],

  props: {
    google: Object,
    map: Object,
    isBlinking: Boolean, 
    options: {
      id: Number,
      index: Number,
      title: String,
      materialIconsName: String,
      position: Number,
    }
  },
  data() {
    return {
      mapCopy: this.map,
      button: Object,
      blinkTimer: Number,
      blinkState: Boolean,
    }
  },

  mounted() {
    
    this.button = document.createElement("div");
    this.button.classList.add(this.options.materialIconsClass ?? "material-icons", "md-32", "controlButton");
    this.button.textContent = this.options.materialIconsName;
    this.button.title = this.options.title;
    this.button.addEventListener("click", () => {
      this.$emit("click", this.options.id);

    });
    if (this.options.position) {
      this.mapCopy.controls[this.options.position].removeAt(this.options.index);
      this.mapCopy.controls[this.options.position].insertAt(this.options.index, this.button);
    } else {
      this.mapCopy.controls[this.google.maps.ControlPosition.RIGHT_CENTER].removeAt(this.options.index);
      this.mapCopy.controls[this.google.maps.ControlPosition.RIGHT_CENTER].insertAt(this.options.index, this.button);
    }
    
    if (this.options.blinking) {
      this.setBlinkingState(true);
    }
  },

  methods: {
    
    setBlinkingState(active) {
      let vm = this;
      if (active) {
        this.blinkTimer = setInterval(function () {
          if (vm.blinkState) {
            console.log("ON");
            vm.button.classList.add("blink");
          } else {
            console.log("OFF");
            vm.button.classList.remove("blink");    
          }
          vm.blinkState = !vm.blinkState;
        }, 500);
        console.log("new interval = " + this.blinkTimer);
      } else {
        this.blinkState = false;
        this.button.classList.remove("blink");    
        console.log("clear interval = " + this.blinkTimer);
        clearInterval(this.blinkTimer);
      }
    }
  },

  watch: {
    isBlinking() {
      console.log("BLINK WATCH " + this.isBlinking);
      this.setBlinkingState(this.isBlinking);
    }
  },

  render() {
    return null;
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
.controlButton {
  background: white;
  color:rgba(0, 0, 0, 0.7);
  margin: 5px 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
}

.controlButton:hover {
  color:rgba(0, 0, 0, 1);
}
.controlButton.blink {
  color: red;
}


</style>
