export {addAccommodationLookups, getAccommodationLookup}

import { db } from "./firebaseInit.js";
import { COLLECTIONS } from "./firestoreConstants.js";
import { accommodationLookupConverter } from "./classes/Accommodation";

// export function getAccommodationLookupIconName(key) {
//   switch (key) {
//     case 'TENT':  return "send";
//     case 'CABN': return "chalet";
//     case 'HUT' : return "cabin";
//     case 'HOTL': return "apartment";
//     case 'HAMM': return "brightness_3";
//     case 'BACK': return "holiday_village";
//     case 'BIVY': return "carpenter"
//     case 'OTHR': return "airline_seat_flat";
//     case 'STAR': return "bedtime";
//     default: return 'airline_seat_flat';
//   }
// }

const getAccommodationLookup = function() {
  return _getLookups(COLLECTIONS.LOOKUPS_ACCOMMODATION, accommodationLookupConverter);
}

/**
 * Bulk adds/updates accommodation loookups
 * @param {Array} accommodationArray - array of Accommodation instances
 * @returns A Promise
 */
const addAccommodationLookups = function(accommodationArray) {
  return new Promise(function (resolve, reject) {

    var batch = db.batch();
    var ref = db.collection(COLLECTIONS.LOOKUPS)
                .doc(COLLECTIONS.LOOKUPS_ACCOMMODATION)
                .collection("keys");

    // create a batch to add the lookups
    accommodationArray.forEach(a => {
      var aNoKey = {...a};
      delete aNoKey.key;
      batch.set(ref.doc(a.key), aNoKey);
    })

    // commit the lookups
    batch.commit()
      .then (() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      })
  })
}

function _getLookups(type, converter) {
  return new Promise(function (resolve, reject) {
    db.collection(COLLECTIONS.LOOKUPS).doc(type).collection("keys")
      .orderBy("order")
      .withConverter(converter)
      .get()
      .then(function (querySnapshot) {
        const result = [];
        querySnapshot.forEach(function (lookup) {
          result.push(lookup.data())
        })
        resolve(result);
      })
      .catch(function (error) {
        reject(error);
      })
  });
}
