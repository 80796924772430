import { constants } from "../../constants";

export class ErrorLookup {

  static messageForError(code) {
    console.log(code);
    console.log(constants.ERROR.ADVENTURE_NOT_PUBIC);
    switch (code) {
      case constants.ERROR.PERMISSION_DENIED:
        return "You tried to access something you don't have permission to view";
      case constants.ERROR.ADVENTURE_NOT_PUBIC:
        console.log("!!");
        return "The trail you access isn't public, get in touch with whoever gave you the link.";
      default:
        console.log("no match");
        return "Not quite sure what went wrong there!"
    }
  }
}