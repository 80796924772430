import { distanceBetweenTwoCoordinates } from "../firestoreDistanceMarkers";
import { CheckInStatistics } from './CheckInStatistics';
import { CheckIn } from "./CheckIn";

//const { DateTime } = require("luxon");

export default function CheckInArray() {
  this._internalUpdate = false;
  this.statistics = new CheckInStatistics(this);

  /**
   *  Returns all the checkins that are dirty and need saving
   */
  this.getDirtyCheckIns = function () {
    return this.filter((checkIn) => {
      return checkIn.isDirty;
    })
  }

  /**
   * Marks all checkins as NOT dirty
   */
  this.clearDirtyCheckIns = function () {
    this.forEach((checkIn) => {
      checkIn.resetIsDirty();
    })
  }

  /**
   * Remove a checkIn from the array
   * 
   * @returns {Boolean}
   *  Returns true if the checkIn was successfully removed, otherwise false.
   * @param {String} id
   *  id of the checkIn to be removed 
   */
  this.removeById = function (id) {
    const checkIn = this.find((item) => {
      return item.id === id;
    });
    if (!checkIn) return false;

    const index = this.indexOf(checkIn);

    const wasCurrent = checkIn === this.current;
    const wasMostRecent = checkIn === this.mostRecent();

    this.splice(index, 1);

    if (this.length == 0) {
      this.current == null;
    } else {
      
      // redefine current if we just deleted it
      if (wasCurrent && !wasMostRecent) {
        this.current = this[index];
      } else if (wasCurrent && wasMostRecent) {
        this.current = this.mostRecent();
      }
    }

    // this will have changed some of the stats, so update them
    this.statistics.updateCheckIns(this);

    return true;
  }

  // this.daysOffTrailSinceCheckIn = function (checkIn) {
  //   let total = 0;
  //   this.forEach((c) => {
  //     if (c.date < checkIn.date) {
  //       total += c.numberOfOffTrailDays;
  //     }
  //   })
  //   return total;
  // }

  // /**
  //  * Returns the number of days since beginning the journey, assuming the first checkin is the "Start" marker
  //  * where no distance was walked. 
  //  * @param {*} checkIn 
  //  */
  // this.daysSinceBeginning = function (checkIn) {
  //   if (checkIn === this.first()) {
  //     return 0;
  //   }
  //   const first = this.first().date;
  //   const firstStandardTime = DateTime.local(first.year, first.month, first.day);
  //   const checkInDateStandardTime = DateTime.local(checkIn.date.year, checkIn.date.month, checkIn.date.day);
  //   if (checkInDateStandardTime.diff) {
  //     return Math.round(checkInDateStandardTime.diff(firstStandardTime, "day").days) + 1;
  //   } else {
  //     // not sure why this would happen
  //     return 0;
  //   }
  // }

  // this.daysSinceMostRecentCheckIn = function () {
  //   if (!this.mostRecent()) { return 0 }

  //   const mostRecent = this.mostRecent().date;
  //   const mostRecentNoTime = DateTime.local(mostRecent.year, mostRecent.month, mostRecent.day);

  //   const todayNoTime = DateTime.local(DateTime.local().year, DateTime.local().month, DateTime.local().day);
  //   if (todayNoTime.diff) {
  //     return Math.round(todayNoTime.diff(mostRecentNoTime, "day").days);
  //   } else {
  //     // not sure why this would happen
  //     return 0;
  //   }
  // },

  // this.daysFromLastCheckIn = function (toCheckIn) {

  //   const indexOfCheckIn = this.indexOf(toCheckIn);
  //   if (indexOfCheckIn > 0) {
  //     return toCheckIn.nearestTrailMarker - this[indexOfCheckIn - 1].nearestTrailMarker;
  //   } else {
  //     return 0;
  //   }
  // }
  
  // this.distanceTravelledToCheckIn = function (checkIn) {
  //   if (checkIn.type == 'start') {
  //     return 0;
  //   }
  //   let total = 0;
  //   this.forEach((c) => {
  //     if (c.date <= checkIn.date) {

  //       // TODO = generalise to include  other modes of travel, not just walking.
  //       total += c.distanceWalked;
  //     }
  //   })
  //   return total;
  // }

  /**
   * Returns the closest checkin to the given location, or null if no checkins.
   * @param {} location 
   * @returns 
   */
  this.closestCheckInTo = function (location) {
    // const _this = this;
    // return new Promise(function (resolve) {
      let closest = this.first();
      let closestDistance = 10000000;
      this.forEach(function (checkIn) {
        const currentLocation = checkIn.location;
        const distance = distanceBetweenTwoCoordinates(currentLocation, location);
        if (distance < closestDistance) {
          closest = checkIn;
          closestDistance = distance;
        }
      })
      return closest;

    //   resolve(closest);
    // });
  };

  /**
   * Sorts the checkins ascending by date
   * @returns 
   */
  this.sortAscendingByDate = function () {
    return this.sort((a, b) => (a.date > b.date) ? 1 : -1);
  }

  /**
   * Returns the first checkin i.e. the one with the earliest date, typically the start of the adventure
   */
  this.first = function () {
    return this.length > 0 ? this[0] : null;
  }

  /**
   * Handle any changes to properties on any checkin instances
   */
  // this.proxyHandler = class {

  //   constructor(parent) {
  //     this.parent = parent;
  //   }

  //   get(obj, prop) {
  //     if (typeof obj[prop] === 'object' && obj[prop] !== null) {
  //       console.log("deep " + prop);
  //       return new Proxy(obj[prop], this)
  //     } else {
  //       return obj[prop];
  //     }
  //   }
  //   set(obj, prop, value) {

  //     // ignore if setting to same value - this happens because of the way the date component
  //     // sets itself 
  //     if (prop == 'date') {
  //       if (obj[prop].equals(value)) {
  //         return true;
  //       }
  //     }

  //     // persist but don't track any internal property changes
  //     if (prop.startsWith("_")) {
  //       obj[prop] = value;
  //       return true;
  //     }

  //     // persist data as normal
  //     obj[prop] = value;

  //     // if any of these props change, recalculate the stats
  //     // Note BUG no deep properties (like checkin.images[0].caption) trigger recalcs atm
  //     if (prop === 'date' ||
  //       prop === 'numberOfRestDays' ||
  //       prop === 'numberOfOffTrailDays' ||
  //       prop === 'distanceWalked') {
  //       // if (!this.parent._internalUpdate) {
  //       //   console.log(prop + " CHANGED");
  //       //   this.parent.autoCorrectDates();
  //       // }
  //       this.parent.recalculateStatistics();
  //     }
      
  //     // mark the parernt (i.e. CheckIn instance) dirty - not this will happen even when a child element of an object is changed
  //     this.parent._isDirty = true;

  //     if (prop === 'date') {
  //       // this may be heavy handed, the only thing likely to change here is the day count for the checkin
  //       // and/or the definition of what's most recent or first
  //       if (this.parent) {
  //         this.parent.sortAscendingByDate();
  //         this.parent.recalculateStatistics();
  //       }
  //     }

  //     return true;
  //   }
  // }

  this.insertManyByDate = function(checkIns) {
    checkIns.forEach(function (checkIn) {
      this.insertByDate(checkIn);
    });
  }

  /**
   * Inserts a checkIn, ensuring it is inserted according to it's date to maintain order. This is the only way
   * we should add checkins to the array. Don't use push.
   * 
   * @param {CheckIn} checkIn 
   */
  this.insertByDate = function (checkIn) {

    let _this = this;

    // we always want to add a proxy to the checkin, so we can track property changes to keep the order and stats up to date.
    const proxy = CheckIn.trackedInstance(checkIn, (id, propertyName) => {
      // refresh the statistics if on of these properties updates
      let recalculateTriggers = ['date', 'numberOfRestDays', 'numberOfOffTrailDays', 'distanceWalked'];
      if (_this.statistics) {
        if (recalculateTriggers.includes(propertyName)) {
          _this.statistics.updateCheckIns(_this);
          _this.fred += 1;
        }
      }
    });

    // insert checkin to the appropriate location based on date
    const date = checkIn.date;

    // if no date has been supplied just put it at the end
    if (!date) {
      this.push(proxy);
    }
    // if the checkin is marked as 'start' make sure there are no other checkins marked as start too
    else if (checkIn.type == 'start') {
      if (this.find(c => { c.type == 'start' })) {
        throw 'duplicate start checkin';
      }

      // if the checkIn is marked as start, make sure it's date is less than or equal to all other checkins
      if (this.find( c => { c.date < checkIn.date })) {
        throw 'start checkin is not the earliest checkin in the array';
      } 
      else {
        // put at the start
        this.splice(0, 0, proxy);
      }

    } else {
      // make sure the checkin date is after or equal to the start (if it exists)
      if (this.start() && checkIn.date < this.start().date) {
        throw "checkin can't be before the start";
      }

      // we are assuming all inserts are initiated here, so the array can be 
      // assumed to be sorted, ascending by date. Therefore we can insert into the 
      // appropriate position in the array.
      var found = false;
      for (var i = 0; i < this.length; i++) {
        if (this[i].date > date) {
          this.splice(i, 0, proxy);
          found = true;
          break;
        }
      }
      // if no date is greater than the date of the checkin, just put it at the end
      if (!found) {
        this.push(proxy);
      }
    }

    // if this was the first checkin, set current
    if (this.length == 1) {
      this.current = proxy;
    }

    // redefine the statistics since we've added a new checkIn
    this.statistics.updateCheckIns(this);
  }

  // /**
  //  * Return statistics about a checkin, or if not supplied, the who journey.
  //  */
  // this.getCombinedStatistics = function () {
  //   if (this._statistics) {
  //     return this._statistics;
  //   } else {
  //     return {
  //       distanceWalked: 0,
  //       numberOfOffTrailDays: 0,
  //       numberOfRestDays: 0,
  //       numberOfDaysSinceStart: 0,
  //       numberOfTrailDays: 0,
  //       lastCheckIn: undefined,
  //       longestDay: 0,
  //     }
  //   }
  // }

  // /**
  //  * recalculate all statistics
  //  */
  // this.recalculateStatistics = function () {

  //   const _this = this;

  //   // some statistics are part of the whole journey
  //   _this._statistics = {
  //     distanceWalked: 0,
  //     numberOfOffTrailDays: 0,
  //     numberOfRestDays: 0,
  //     numberOfDaysSinceStart: 0,
  //     numberOfTrailDays: 0,
  //     lastCheckIn: undefined,
  //     longestDay: 0,
  //   }

  //   // Update individual checkin statistics
  //   this.forEach(function (checkIn) {
  //     const day = _this.daysSinceBeginning(checkIn);
  //     checkIn._statistics.daysSinceStart = day;
  //     checkIn._statistics.daysOnTrail = day - _this.daysOffTrailSinceCheckIn(checkIn);
  //     checkIn._statistics.distanceTravelledSoFar = _this.distanceTravelledToCheckIn(checkIn);
  //   });

  //   // Update combined statistics
  //   this.forEach(function (checkIn) {
  //     _this._statistics.distanceWalked += checkIn.distanceWalked;
  //     _this._statistics.numberOfRestDays += checkIn.numberOfRestDays;
  //     _this._statistics.numberOfOffTrailDays += checkIn.numberOfOffTrailDays;
  //   });
  //   _this._statistics.numberOfDaysSinceStart = _this.mostRecent()._statistics.daysSinceStart;
  //   _this._statistics.numberOfTrailDays = _this._statistics.numberOfDaysSinceStart - _this._statistics.numberOfOffTrailDays;
  //   _this._statistics.lastCheckIn = _this.mostRecent();
  //   _this._statistics.longestDay = Math.max(..._this.map(x => x.distanceWalked));
  //   _this._statistics.averageDailyDistance = _this._statistics.distanceWalked / _this._statistics.numberOfTrailDays;
    
  // }

  // this.autoCorrectDates = function() {
  //   this._internalUpdate = true;
  //   this.forEach((checkIn) => {
  //     let previous = this.previousTo(checkIn);
  //     if (previous && previous.date) {
  //       console.log("current = " + checkIn.title + ", previous = " + previous.title ?? "no title")
  //       if (previous.type == 'start') {
  //         checkIn.date = previous.date;
  //       } else {
  //         // we only override dates if
  //         checkIn.date = previous.date.plus({ days: previous.numberOfRestDays + previous.numberOfOffTrailDays + 1});
  //       }
  //     }
  //   });
  //   this._internalUpdate = false;
  // }

  this.setCurrentById = function (id) {
    let checkIn = this.find((item) => {
      return item.id == id;
    });
    if (checkIn) {
      this.current = checkIn;
    }
  }

  /**
   * Set the current CheckIn by index
   * @param {} index 
   */
  this.setCurrentByIndex = function (index) {
    if (index >= 0 && index < this.length) {
      this.current = this[index];
    } 
  }

  this.currentIndex = function () {
    return this.indexOf(this.current);
  }

  this.canMoveFirst = function() {
    return this.length > 0 && this.currentIndex() != 0;
  },

  this.canMoveLatest = function () {
    return this.length > 0 && this.currentIndex() != (this.length - 1);
  },
  
  this.canMoveNext = function () {
    return this.currentIndex() < (this.length - 1);
  }

  this.canMovePrevious = function () {
    return this.currentIndex() > 0;
  }

  /**
   * Moves context to the first check in, if successful returns true.
   */
  this.moveFirst = function () {
    if (this.length > 0) {
      this.setCurrentByIndex(0);
      return true;
    } else {
      return false;
    }
  }

  /**
   * Moves context to the next check in, if successful returns true.
   */
  this.moveNext = function () {
    if (this.canMoveNext()) {
      this.setCurrentByIndex(this.currentIndex() + 1);
      return true;
    } else {
      return false;
    }
  }

  /**
   * Moves context to the first check in, if successful returns true.
   */
  this.moveLatest = function () {
    if (this.length > 0) {
      this.setCurrentByIndex(this.length - 1);
      return true;
    } else {
      return false;
    }
  }


  /**
   * Returns the checkIn before the supplied checkIn
   * @param {} checkIn 
   * @returns 
   */
  this.previousTo = function (checkIn) {
    let index = this.indexOf(checkIn);
    if (index == 0) return null;
    return this[index - 1];
  }

  /** 
   * Moves context to the previous check in, if successful returns true.
   */
  this.movePrevious = function () {
    if (this.canMovePrevious()) {
      this.setCurrentByIndex(this.currentIndex() - 1);
      return true;
    }
    return false;
  }

  this.mostRecent = function () {
    if (this.length == 0) { return undefined }

    // this function relies on the array always ensuring the correct order. This is done by ordering on push
    // and when a date changes (tracked through Proxy handler)
    return this[this.length - 1];
  }

  this.first = function () {
    if (this.length == 0) { return undefined }

    // this function relies on array elements being added with this.insertByDate, which ensures the array is always
    // in the correct order
    return this[0];
  }

  this.start = function () {
    return this.find( c => c.type == 'start');
  }

}

CheckInArray.prototype = Array.prototype;
