<script>
import { defineComponent } from "vue";
import { Location } from "../../api/classes/Location";
import MarkerWithLabel from '@googlemaps/markerwithlabel';

export default defineComponent({
  name: "GoogleMarkerWithLabel",

  emits: ["dragend", "click"],

  data() {
    return {
      markerWithLabel: Object,
    };
  },

  props: {
    google: Object,
    map: Object,
    options: Object,
  },

  mounted() {
    if (this.options.position) {
      this.markerWithLabel = new MarkerWithLabel({
        ...this.options
      });
      this.attachListeners();
    }
  },

  methods: {
    attachListeners() {
      let vm = this;
      this.markerWithLabel.addListener("dragend", (e) => {
        vm.$emit("dragend", Location.fromGoogleMapLocation(e.latLng));
      });

      this.google.maps.event.addListener(this.markerWithLabel, "click", (event) => {
        event.cancelBubble = true;
        if (event.stopPropagation) {
          event.stopPropagation();
        }
        if (event.preventDefault) {
          event.preventDefault();
        } else {
          event.returnValue = false;
        }
        vm.$emit("click");
      });
    },

    marker() {
      return this.markerWithLabel;
    },
  },

  /**
   * Remove the marker from the map. Note for some reason the
   * setMap(null) method is only removing the label not the marker,
   * so we additionally set it's visibility.
   */
  beforeUnmount() {
    this.markerWithLabel.setMap(null);
    this.markerWithLabel.setVisible(false);
    this.markerWithLabel = null;
  },

  watch: {
    "options.labelClass": function (className) {
      this.markerWithLabel.set('labelClass', className);
    },
    "options.labelContent": function (content) {
      this.markerWithLabel.set('labelContent', content);
    },
    "options.scale": function (scale) {
      const icon = this.markerWithLabel.getIcon();
      icon.scale = scale;
      this.markerWithLabel.setIcon = icon;
    },
    "options.icon.url": function (url) {
      const icon = this.markerWithLabel.getIcon();
      icon.url = url;
      this.markerWithLabel.setIcon(icon);
    },
  },

  render() {
    return null;
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
