import Dashboard from "./components/pages/Dashboard";
import UserHome from "./components/pages/UserHome";
import Login from "./components/pages/Login.vue";
import Admin from "./components/pages/Admin.vue";
import SignUp from "./components/pages/SignUp.vue";
import SignUpStep2 from "./components/pages/SignUpStep2.vue";
import Welcome from "./components/pages/Welcome.vue";
import ForgotPassword from "./components/pages/ForgotPassword";
import Adventure from "./components/pages/Adventure";

export default [
    { name: "home", path: '/', component: Welcome, meta: { page_title: 'Home' } },
    { name: "login", path: '/login', component: Login, meta: { page_title: 'Login' }  },
    { path: '/forgotPassword', component: ForgotPassword, meta: { page_title: 'Forgot Password' }},
    { name: "signup", path: '/signup', component: SignUp, meta: { page_title: 'Signup' }},
    { name: "signup2", path: '/signup2', component: SignUpStep2, meta: { page_title: 'Signup Step 2' } },
    
    // Admin pages
    { path: '/admin', component: Admin, meta: { page_title: 'Admin', requiresAuth: true, requiresAdmin: true } },
    
    // secure links - must be authenticated
    { path: '/adventure', component: Adventure, meta: { page_title: 'Adventure', requiresAuth: true } },
    { path: "/adventure/:adventureid", component: Adventure, meta: { page_title: 'Adventure', requiresAuth: true } },
    
    { path: '/:username', component: UserHome, meta: { page_title: 'User Home' } },
    
    { path: '/:username/edit/:adventureid', component: Dashboard, meta: { page_title: 'Map Edit', requiresAuth: true } },
    { path: '/:username/edit/:adventureid/:checkInId', component: Dashboard, meta: { page_title: 'Map Edit', requiresAuth: true } },

    // Public urls
    { path: '/:username/view/:adventureid', component: Dashboard, meta: { page_title: 'Map View' } },
    { path: '/:username/view/:adventureid/:checkInId', component: Dashboard, meta: { page_title: 'Map View' } },

    //
    { path: '/:pathMatch(.*)*', component: Welcome, meta: { page_title: 'Home' } },
    { path: '/:pathMatch(.*)', component: Welcome, meta: { page_title: 'Home' } },
];
