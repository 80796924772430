<template>
  <div class="sc">
    <div class="sc__top">
      <div v-if="iconName" class="sc__image">
        <icon :onWhite="true">{{ iconName }}</icon>
      </div>
      <div class="sc__statistic">
        <div>
          <span class="sc__value">{{ value }}</span
          ><span class="sc__unit">{{ unit }}</span>
        </div>
        <div
          class="sc__title"
          :class="{ 'sc__title--clickable': toolTipText }"
          @click="handleClick"
        >
          {{ title }}
        </div>
      </div>
    </div>
    <div v-if="subtitle" class="sc__subtitle">{{ subtitle }}</div>

    <modal-dialog
      v-if="showToolTip"
      @close="showToolTip = false"
      :title="toolTipTitle ?? 'Help'"
    >
      <div v-html="toolTipText"></div>
    </modal-dialog>
  </div>
</template>

<script>
import Icon from "../controls/Icon";
import ModalDialog from "./ModalDialog.vue";

export default {
  name: "statistic-card",
  components: {
    Icon,
    ModalDialog,
  },
  props: {
    value: Number,
    unit: String,
    title: String,
    subtitle: String,
    iconName: String,
    toolTipTitle: String,
    toolTipText: String,
  },

  data() {
    return {
      showToolTip: false,
    };
  },

  methods: {
    handleClick() {
      if (this.toolTipText) {
        this.showToolTip = true;
      }
    },
  },
};
</script>

<style scoped>
.sc {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 10px;
  box-sizing: border-box;
}

.sc__top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sc__image {
  width: 10px;
}

.sc__statistic {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--ish-darkgrey);
}

.sc__title {
  font-size: var(--ish-font-size-small);
  color: var(--ish-mediumgrey);
  width: 100%;
  text-align: center;
}

.sc__subtitle {
  font-size: var(--ish-font-size-small);
  color: var(--ish-mediumgrey);
  text-align: center;
  width: 100%;
  padding-top: 10px;
}

.sc__value {
  font-size: var(--ish-font-size-medium);
  font-weight: bold;
  color: var(-ish-mediumgray);
}

.ss__tooltip {
  position: absolute;
  top: 50px;
  width: 150px;
  height: 50px;
  background: var(--ish-lightgrey);
  color: white;
}

.sc__title--clickable {
  color: var(--ish-blue);
}

.sc__title--clickable:hover {
  text-decoration: underline;
  cursor: pointer;
}

.sc__unit {
  font-size: var(--ish-font-size-small);
  color: var(-ish-mediumgray);
  padding-left: 2px;
}
</style>