import { updateCheckIn } from "../firestoreCheckIn";
import { auth } from "../firebaseInit";

export { AutoSave }

class AutoSave {

  /**
   * 
   * @param {Object} library 
   * @param { (String) => {} } progressHandler 
   */
  constructor(library, progressHandler) {
    this.progressHandler = progressHandler;
    this.checkIns = library.checkIns;
  }

  start() {
    const _this = this;
    this.autoSaveCheck = setInterval(function () {
      _this.saveCheckIns();
    }, 5000);
  }

  saveCheckIns() {
    const _this = this;
    const dirty = _this.checkIns.getDirtyCheckIns();

    if (dirty.length > 0) {
      _this.progressHandler("Saving...");
      let promises = [];
      dirty.forEach((checkIn) => {
        if (checkIn.uid == auth.currentUser.uid) {
          promises.push(updateCheckIn(checkIn));
        }
      });
      Promise.all(promises).then(() => {
        _this.progressHandler("");
        _this.checkIns.clearDirtyCheckIns();
      }).catch((error) => {
        _this.progressHandler(error);
      })
    } 
  }

  stop() {
    clearTimeout(this.autoSaveCheck);
  }

}