<template>
  <div class="loader" >
    <div class="google-map" ref="googleMap"></div>
    <template v-if="Boolean(google) && Boolean(map)">
      <slot v-bind:google="google" v-bind:map="map" />
    </template>
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';
import { Location } from "../../api/classes/Location";
import ComponentLoadedEvent from "../../api/classes/ComponentLoadedEvent";

export default {
  emits: ["idle", "dblclick", "click", "initialised", "loaded"],

  props: {
    mapConfig: Object,
    apiKey: String,
    panToLocation: Object,
    fitBounds: Object,
    showMinMaxControls: Boolean,
  },

  data() {
    return {
      google: null,
      map: null,
      clickEventDelayTimer: Object,
    };
  },

  async mounted() {

    const loader = new Loader({
      apiKey: this.apiKey,
      version: "weekly",
      libraries: ["places"]
    });

    let vm = this;
    loader
      .load()
      .then(() => {
        vm.google = window.google;
        vm.initializeMap();
        vm.attachListeners();
      })
      .catch(e => {
        console.log(e);
      });
  },

  watch: {
    panToLocation(options) {
      if (options && this.map) {
        this.map.panTo(options.location.toGoogleMapLocation());
        let zoom = parseInt(options.zoom);
        if (zoom ?? zoom != this.map.getZoom()) {
          this.map.setZoom(zoom);
        }
      }
    },
    fitBounds(bounds) {
      if (bounds) {
        this.map.fitBounds(bounds);
      }
    },
    showMinMaxControls(show) {
      this.setZoomControlVisibility(show);  
    }
  },

  methods: {
    setZoomControlVisibility(show) {
      if (this.map) {
        this.map.setOptions({
          zoomControl: show,
          zoomControlOptions: {
            position: this.google.maps.ControlPosition.RIGHT_BOTTOM,
          }
        })
      }
    },

    /**
     * Creates the google map instance and raises the 'initialised' event to let listeners
     * know they can interact with it.
     */
    initializeMap() {
      const mapContainer = this.$refs.googleMap;
      this.map = new this.google.maps.Map(mapContainer, this.mapConfig);

      this.map.mapTypes.set("OSM", new this.google.maps.ImageMapType({
        getTileUrl: function(coord, zoom) {
          return "https://tile.opentopomap.org/" + zoom + "/" + coord.x + "/" + coord.y + ".png";
        },
        tileSize: new this.google.maps.Size(256, 256),
        name: "Topo",
        maxZoom: 18
      }));

      // show/hide zoom controls
      this.setZoomControlVisibility(this.showMinMaxControls);

      //google.maps.ControlPosition
      this.$emit('initialised');
    },

    attachListeners() {
      const vm = this;

      // idle - fired whenever the map's viewport stops moving )
      this.google.maps.event.addListener(this.map, "idle", () => {
        vm.$emit("idle", {
          centre: Location.fromGoogleMapLocation(vm.map.getCenter()),
          bounds: vm.map.getBounds(),
          zoom: vm.map.getZoom(),
        });
      });

      // dblclick - fired whenever the map is double-clicked.
      this.google.maps.event.addListener(this.map, "dblclick", function () {
        // cancel the click event delay to allow the double click event to happen (to zoom in for example),
        // otherwise the click event will always take precedence and cancel the dblclick.
        clearTimeout(vm.clickEventDelayTimer);
      });

      // click - fired whenever the map is clicked
      this.google.maps.event.addListener(this.map, "click", (e) => {
        if (e.placeId) {
          e.stop();
        }
        vm.clickEventDelayTimer = setTimeout(function () {
          vm.$emit("click", Location.fromGoogleMapLocation(e.latLng), e.placeId);
        }, 200);
      });

      this.google.maps.event.addListener(this.map, "tilesloaded", function () {
        vm.$emit("loaded", new ComponentLoadedEvent("map", "map loaded"));
      });

    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loader, .google-map {
  box-sizing: border-box;
  height: 100%;
}
</style>


