<template>
  <modal-dialog title="Map Settings" @close="$emit('close')">
    <h2 class="light">MAP TYPE</h2>
    <div class="maptypes">
      <base-segmented-control
        :items="mapTypeItems"
        @itemSelected="handleItemSelected"
      ></base-segmented-control>
    </div>
    <br />
    <!-- <h2 class="light">MARKERS</h2>
    <div class="maptypes">
      <base-segmented-control
        :items="markerItems"
        @itemSelected="handleMarkerItemSelected"
      ></base-segmented-control>
    </div>
    <br /> -->
    <h2 class="light">LEGEND</h2>

    <ul class="leaders">
      <li>
        <div class="legend__trail yellow"></div>
        <span>Main trail</span>
      </li>
      <li>
        <div class="legend__trail red"></div>
        <span>Road walking :-(</span>
      </li>
      <li>
        <div class="legend__trail purple"></div>
        <span>Bypass</span>
      </li>
      <li>
        <div class="legend__trail blue"></div>
        <span>Water</span>
      </li>
      <li>
        <div class="legend__trail green"></div>
        <span>Seasonal</span>
      </li>
    </ul>
    <p>
      <i>Trail version: {{ trailVersion }}</i>
    </p>
  </modal-dialog>
</template>

<script>
import { defineComponent } from "vue";
import { analytics } from "../../api/firebaseInit";

import BaseSegmentedControl from "../controls/BaseSegmentedControl.vue";

import ModalDialog from "../controls/ModalDialog.vue";

export default defineComponent({
  trackAsPageView: true,
  trackWithPageName: "Map Info",
  name: "map-info",
  emits: ["close"],
  components: { ModalDialog, BaseSegmentedControl },
  props: {
    map: Object,
    google: Object,
    trailVersion: String,
  },
  computed: {
    markerItems() {
      return [
        {
          label: "Day",
          selected: true,
        },
        {
          label: "Accommodation",
          selected: true,
        },
        {
          label: "Small",
          selected: true,
        },
      ];
    },
    mapTypeItems() {
      return [
        {
          label: "Terrain",
          value: this.google.maps.MapTypeId.TERRAIN,
          selected: this.map.mapTypeId == this.google.maps.MapTypeId.TERRAIN,
        },
        {
          label: "Topo",
          value: "OSM",
          selected: this.map.mapTypeId == "OSM",
        },
        {
          label: "Satellite",
          value: this.google.maps.MapTypeId.SATELLITE,
          selected: this.map.mapTypeId == this.google.maps.MapTypeId.SATELLITE,
        },
      ];
    },
  },

  //   mounted() {
  //     let vm = this;
  //     // This event will fire once the new mapType has fully loaded
  //     this.map.addListener("tilesloaded", () => {
  //       vm.$emit("close");
  //     });
  //   },

  methods: {
    handleItemSelected(item) {
      this.isChanging = true;
      this.map.setMapTypeId(item.value);
      analytics.logEvent("maptype_changed", {
        content_type: item.value,
      });
    },
  },
});
</script>

<style scoped>
.legend__trail {
  width: 120px;
  height: 6px;
}
.legend__trail.red {
  background-color: red;
}

.legend__trail.yellow {
  background-color: yellow;
}

.legend__trail.purple {
  background-color: purple;
}

.legend__trail.blue {
  background-color: blue;
}

.legend__trail.green {
  background-color: green;
}
li {
  height: 30px;
}

ul.leaders {
  text-indent: 0px;
  max-width: 40em;
  margin: 10px 0px;
  overflow-x: hidden;
  list-style: none;
  list-style-type: none;
  padding: 0px;
}
ul.leaders li:before {
  float: left;
  width: 0;
  white-space: nowrap;
  content: ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . ";
}
/* ul.leaders span:first-child {
    padding-right: 0.33em;
    background: white;
} */
ul.leaders div + span {
  float: right;
  padding-left: 0.33em;
  background: white;
}
ul div {
  display: inline-block;
}
</style>