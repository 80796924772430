export { Accommodation, accommodationLookupConverter }

class Accommodation {
  constructor(config) {
    this.key = config.key;
    this.value = config.value;
    this.order = config.order ?? 0;
    this.imageName = config.imageName;
    this.imageRotation = config.imageRotation ?? 0;
  }

  static nullInstance() {
    return new Accommodation({key: null})
  }
  
}

var accommodationLookupConverter = {
  toFirestore: function (accommodation) {
    return {
      value: accommodation.value,
      order: accommodation.order,
      imageName: accommodation.imageName,
      imageRotation: accommodation.imageRotation,
    };
  },

  fromFirestore: function (snapshot, options) {
    const data = snapshot.data(options);

    const config = {
      key: snapshot.id,
      value: data.value,
      order: data.order,
      imageName: data.imageName,
      imageRotation: data.imageRotation,
    }

    return new Accommodation(config);
  }
}
