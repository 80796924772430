import { CheckInArray } from "./CheckinArray";
import { CheckIn } from "./CheckIn";
//import { distanceBetweenTwoCoordinates } from "../firestoreDistanceMarkers";

const { DateTime } = require("luxon");

/**
 * Extends Array to provide statistics for each checkIn as well as combinded statistics across all checkIns
 * 
 * Indivual statistics are accessed via this[checkInId] which will return an object with properties
 * - daysSinceStart
 * - daysOnTrail
 * - distanceTravelledSoFar:
 * 
 * @constructor
 *  
 * @param {CheckInArray}
 *  CheckInArray instance which will be gauranteed to be ordered
 */
export class CheckInStatistics {

  constructor(checkIns) {
    this.updateCheckIns(checkIns);    
  }

  /**
   * Total distance walked across all checkins
   */
  distanceWalked = 0;

  /**
   * Total number of days you spent OFF the trail across all checkins
   */
  numberOfOffTrailDays = 0;

  /**
   * Total number of rest days across all checkins
   */
  numberOfRestDays = 0;

  /**
   * Total number of checkins where the number of kms was Zero - these are treated as rest days for some stats, like daily averages don't consider these days a walking day.
   */
  numberOfZeroKmDays = 0;

  /**
   * Total number of checkins where the number of kms was Zero - these are treated as rest days for some stats, like daily averages don't consider these days a walking day.
   */
  numberOfWalkingZeroKmDays = 0;

  /**
   * Total number of days since beginning. Your first checkin (after the start marker) will be day 1.
   * This will include rest days and days off the trail.
   */
  numberOfDaysSinceStart = 0;

  /**
   * Total number of days on the trail, excluding off trail days but including rest days.
   * Note this is used on the map checkin markers
   */
  numberOfTrailDays = 0;

  /**
   * The longest distance walked across all checkins
   */
  longestDay = 0;

  /**
   * The raw average daily distance, including 0 days, but excluding off trail days.
   * This one tells you if you're going to keep within the 25km / day to finish inside
   * 120 days.
   */
  rawAverageDailyDistance = 0;

  /**
   * The average distance walked, across all checkins, excluding zero days and days where 0km were walked (typically travel days).
   */
  averageDailyWalkingDistance = 0;

  /**
   * The number of days since the last checkin
   */
  daysSinceMostRecentCheckIn = 0;

  /**
   * Updates the checkins for the statistics
   * @param {CheckInArray} checkIns 
   */
  updateCheckIns(checkIns) {
    this.checkIns = checkIns;
    this.refresh();
  }

  /**
   * Refreshes the statistics based on the latest checkin data
   */
  refresh() {

    if (!this.checkIns || this.checkIns.length == 0) {
      return;
    }

    // Clear and redefine checkIn statistics
    // this.length = 0;
    let _this = this;
    this.checkIns.forEach(function (checkIn) {
      const day = _this.daysSinceBeginning(checkIn);
      // let dayDescription = `Day ${day}`;
      // if (_this.numberOfRestDays > 0) {
      //   dayDescription = `Day ${day} - ${day + checkIn.numberOfRestDays}`;
      // } 
      _this[checkIn.id] = {
        /**   
         * Number of days since starting, doesn't take in to account rest and off trail days
         */
        daysSinceStart: day,
        /**
         * Number of days on the trail, excluding off trail days
         */
        daysOnTrail: day - _this.daysOffTrailSinceCheckIn(checkIn),
        /**
         * User friendly description of the day (or days) representing the checkin. 
         * e.g. 'Day 10' or 'Day 8 - 9' if day 8 was followed by a rest day
         */
        daysOnTrailDescription: _this.daysOnTrailDescription(checkIn),
        /**
         * Total distance walked up to and including this checkin
         */
        distanceTravelledSoFar: _this.distanceTravelledToCheckIn(checkIn),
      }
    });

    // Update combined statistics

    // Reset to zero
    this.distanceWalked = 0;
    this.numberOfRestDays = 0;
    this.numberOfOffTrailDays = 0;
    this.numberOfZeroKmDays = 0;

    // Increment totals
    this.checkIns.forEach(function (checkIn) {
      _this.distanceWalked += checkIn.distanceWalked;
      _this.numberOfZeroKmDays += (checkIn.distanceWalked == 0 && checkIn.type != 'start') ? 1 : 0;

      // don't add the last checkins rest/off/travel days - they are irrelevant to stats
      if (checkIn != _this.checkIns.mostRecent()) {
        _this.numberOfRestDays += checkIn.numberOfRestDays;
        _this.numberOfOffTrailDays += checkIn.numberOfOffTrailDays;
      }
    });

    // Update stats
    this.numberOfDaysSinceStart = this[this.checkIns.mostRecent().id].daysSinceStart;
    this.numberOfTrailDays = this.numberOfDaysSinceStart - this.numberOfOffTrailDays;
    this.longestDay = Math.max(...this.checkIns.map(x => x.distanceWalked));

    this.rawAverageDailyDistance = (this.distanceWalked / this.numberOfTrailDays);
    if (isNaN(this.rawAverageDailyDistance)) { this.rawAverageDailyDistance = 0}

    this.averageDailyWalkingDistance = (this.distanceWalked / (this.numberOfTrailDays - this.numberOfRestDays - this.numberOfZeroKmDays));
    if (isNaN(this.averageDailyWalkingDistance)) { this.averageDailyWalkingDistance = 0}
    
    this.daysSinceMostRecentCheckIn = this.getDaysSinceMostRecentCheckIn();
  }


  /**
   * Returns a user friendly formatted string of the day(2) represented by the checkin. Note this uses the
   * number of trail days since starting, ignoring any off-trail days.
   */
  daysOnTrailDescription(checkIn) {
    const daysOnTrail = this.daysSinceBeginning(checkIn) - this.daysOffTrailSinceCheckIn(checkIn);
    
    if (checkIn.numberOfRestDays > 0) {
      return `Day ${daysOnTrail} - ${daysOnTrail + checkIn.numberOfRestDays}`;
    } else {
      return `Day ${daysOnTrail}`;
    }
  }

  daysOffTrailSinceCheckIn(checkIn) {
    let total = 0;
    this.checkIns.forEach((c) => {
      if (c.date < checkIn.date) {
        total += c.numberOfOffTrailDays;
      }
    })
    return total;
  }

  /**
   * Returns the number of days since the beginning the journey, assuming the first checkin is the beginning marker
   * where no distance was walked. Does not take into account off trail days.

  * @param {CheckIn} checkIn 
   */
   daysSinceBeginning(checkIn) {
    if (checkIn === this.checkIns.first()) {
      return 0;
    }
    const first = this.checkIns.first().date;
    const firstStandardTime = DateTime.local(first.year, first.month, first.day);
    const checkInDateStandardTime = DateTime.local(checkIn.date.year, checkIn.date.month, checkIn.date.day);
    if (checkInDateStandardTime.diff) {
      return Math.round(checkInDateStandardTime.diff(firstStandardTime, "day").days) + 1;
    } else {
      // not sure why this would happen
      return 0;
    }
  }

  getDaysSinceMostRecentCheckIn() {
    if (!this.checkIns.mostRecent()) { return 0 }

    const mostRecent = this.checkIns.mostRecent().date;
    const mostRecentNoTime = DateTime.local(mostRecent.year, mostRecent.month, mostRecent.day);

    const todayNoTime = DateTime.local(DateTime.local().year, DateTime.local().month, DateTime.local().day);
    if (todayNoTime.diff) {
      return Math.round(todayNoTime.diff(mostRecentNoTime, "day").days);
    } else {
      // not sure why this would happen
      return 0;
    }
  }

  daysFromLastCheckIn(toCheckIn) {

    const indexOfCheckIn = this.indexOf(toCheckIn);
    if (indexOfCheckIn > 0) {
      return toCheckIn.nearestTrailMarker - this.checkIns[indexOfCheckIn - 1].nearestTrailMarker;
    } else {
      return 0;
    }
  }
  
  distanceTravelledToCheckIn(checkIn) {
    if (checkIn.type == 'start') {
      return 0;
    }
    let total = 0;
    this.checkIns.forEach((c) => {
      if (c.date <= checkIn.date) {
        // TODO = generalise to include  other modes of travel, not just walking.
        total += c.distanceWalked;
      }
    })
    return total;
  }

  // /**
  //  * Returns the closest checkin to the given location, or null if no checkins.
  //  * @param {} location 
  //  * @returns 
  //  */
  // closestCheckInTo(location) {
  //   let closest = this.checkIns.first();
  //   let closestDistance = 10000000;
  //   this.checkIns.forEach(function (checkIn) {
  //     const currentLocation = checkIn.location;
  //     const distance = distanceBetweenTwoCoordinates(currentLocation, location);
  //     if (distance < closestDistance) {
  //       closest = checkIn;
  //       closestDistance = distance;
  //     }
  //   })
  //   return closest;
  // }

}