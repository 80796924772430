<template>
  <div class="previewMarker">
    <GoogleMapMarker
      :map="map"
      :google="google"
      :options="markerOptions"
      @dragend="handleDragEnd"
    >
    </GoogleMapMarker>

    <div class="dialog" v-if="showDialog">
      <Icon :options="iconOptions" class="closeButton" @click="closeDialog"
        >close</Icon
      >
      <div class="placename">{{ dialogTitle }}</div>
      <div class="topSection">
        <div class="addressSection">
          
          <div v-show="dialogSubtitle" class="address">
            {{ dialogSubtitle }}
          </div>
        </div>
        <base-button
          class="button"
          v-if="options.isFirstCheckIn"
          @click="checkinStart"
          >I Started Here</base-button
        >
        <base-button
          class="button"
          v-if="!options.isFirstCheckIn"
          @click="checkin"
          >I Stayed Here</base-button
        >
      </div>

      <hr class="divider" />

      <div class="bottomSection">
        <div class="statisticBlock">
          <div>
            <span class="statisticValue">{{ nearestTrailMarker }}</span
            ><span class="statisticUnit">km</span>
          </div>
          <div class="statisticDescription">nearest trail marker</div>
        </div>
        <div class="statisticBlock" v-show="!options.isFirstCheckIn">
          <div>
            <span class="statisticValue">{{ trailDistanceToLastLocation }}</span
            ><span class="statisticUnit">km</span>
          </div>
          <div class="statisticDescription">from last check in</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapMutations } from 'vuex'

import GoogleMapMarker from "./GoogleMapMarker.vue";
import BaseButton from "../controls/BaseButton.vue";
import Icon from "../controls/Icon.vue";
import { constants } from "../../constants";

import { getLocationDetails } from "../../api/googleServices.js";
import { getNearestMarker } from "../../api/firestoreDistanceMarkers.js";

export default defineComponent({
  name: "PreviewMarker",

  emits: ["close", "checkin", "firstcheckin"],

  components: {
    GoogleMapMarker,
    BaseButton,
    Icon,
  },

  data() {
    return {
      eventualLocation: null,
      lastLocation: null,
      dialogTitle: "",
      dialogSubtitle: "",
      nearestTrailMarker: 0,
      nearestTrailMarkerOfLastLocation: 0,
    };
  },

  props: {
    google: Object,
    map: Object,
    options: Object,
    showDialog: Boolean,
  },

  computed: {
    trailDistanceToLastLocation() {
      return Math.abs(this.nearestTrailMarker - this.nearestTrailMarkerOfLastLocation);
    },

    iconOptions() {
      let options = { ...constants.ICON_OPTIONS.ON_WHITE_ACTIONABLE };
      options.size = "24px";
      options.background.size = "32px";
      options.background.borderRadius = "3px";
      // options.size = "18px";
      // options.background.size = "20px";
      return options;
    },
    markerOptions() {
      return {
        position: this.options.location,
        draggable: true,
      };
    },
  },

  mounted() {
    this.eventualLocation = this.options.location;
    this.eventualPlaceId = this.options.placeId;
    this.lastLocation = this.options.lastLocation;
    this.updateDetails(this.eventualLocation, this.eventualPlaceId, this.lastLocation);
    this.addShortcutListener({ name: 'preview-esc', callback: this.closeDialog, listenTo: ['esc','Escape',27]});
  },

  unmounted() {
    this.removeShortcutListener('preview-esc');
  },

  methods: {

    ...mapMutations({
      addShortcutListener: 'ADD_SHORTCUT_LISTENER',
      removeShortcutListener: 'REMOVE_SHORTCUT_LISTENER',
    }),
    
    updateDetails(location, placeId, lastLocation) {
      const vm = this;
      let promises = [];
      
      promises.push(getLocationDetails(
        this.google,
        this.map,
        location,
        placeId
      ).then((result) => {
        vm.dialogTitle = result.name;
        let splitAddress = result.address.split(",").map((item) => item.trim());
        vm.dialogSubtitle = splitAddress.join("\n");
      }));

      promises.push(getNearestMarker(location).then(function (marker) {
        //vm.nearestTrailMarker = marker.name;
        vm.nearestTrailMarker = marker.trailKm;
      }));

      if (lastLocation) {
        promises.push(getNearestMarker(lastLocation).then( (marker) => {
          //vm.nearestTrailMarkerOfLastLocation = marker.name;
          vm.nearestTrailMarkerOfLastLocation = marker.trailKm;
        }));
      }

      Promise.all(promises).then(() => {
        // why am I waiting for all the promises to end?
        console.log("preview marker info ready");
      });
    },

    handleDragEnd(location) {
      // for now, when you drag you lose any place icon you may have selected
      this.eventualLocation = location;
      this.updateDetails(this.eventualLocation, null, this.lastLocation);
    },

    closeDialog() {
      this.$emit("close");
    },

    checkin() {
      this.$emit("checkin", {
        location: this.eventualLocation,
        title: this.dialogTitle,
        address: this.dialogSubtitle,
        nearestTrailMarker: this.nearestTrailMarker,
      });
    },

    checkinStart() {
      this.$emit("firstcheckin", {
        location: this.eventualLocation,
        title: this.dialogTitle,
        address: this.dialogSubtitle,
        nearestTrailMarker: this.nearestTrailMarker,
      });
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@keyframes enter {
  0% {
    top: 100px;
    opacity: 0;
  }
  80% {
    top: 80px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}

.previewMarker {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.dialog {
  position: relative;
  bottom: 20px;
  align-items: center;
  border-radius: 5px;
  width: 350px;
  background-color: white;
  padding: 15px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}

.topSection,
.bottomSection {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.placename {
  font-size: var(--ish-font-size-normal);
  font-weight: bold;
  text-align: left;
  padding-top: 20px;
  padding-bottom: 6px;
  color: var(--ish-darkgrey);
  height: 40px;
}

.address {
  font-size: var(--ish-font-size-small);
  font-weight: var(--ish-font-weight);
  margin-bottom: 10px;
  white-space: pre-wrap;
  color: var(--ish-darkgrey);
}

.button {
  margin-bottom: 10px;
}
.closeButton {
  top: 10px;
  right: 15px;
  position: absolute;
}

.statisticBlock {
  margin: 0px;
}
.statisticValue {
  color: var(--ish-darkgrey);
}

.statisticUnit {
  color: var(--ish-darkgrey);
}

.statisticDescription {
  color: var(--ish-darkgrey);
}
</style>
