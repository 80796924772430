<template>
    <div class="fileuploader">
        <div>
            <input type="file" id="uploader" ref="uploader" name="filename" />
            <icon :options="iconOptions" @click="browseFile"
                >add_to_photos</icon
            >
        </div>
        <div>{{ progress }}</div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { storage } from "../../api/firebaseInit";
import Icon from "../controls/Icon";
import { constants } from "../../constants";

export default defineComponent({
    name: "FileUploader",

    emits: ["uploaded"],

    components: {
        Icon,
    },

    props: {
        // where the file should be uploaded to in storage
        uploadToPath: String,
    },

    data() {
        return {
            progress: "",
        };
    },

    computed: {
        iconOptions() {
            return constants.ICON_OPTIONS.ON_WHITE_ACTIONABLE;
        },
    },

    mounted() {
        this.$refs.uploader.addEventListener("change", this.handleFiles, false);
        this.$refs.uploader.addEventListener("loadend", () => {
            this.progress = "";
        });
        this.$refs.uploader.addEventListener("progress", (event) => {
            this.progress = Math.round((event.loaded / event.total) * 100);
        });
    },

    methods: {
        browseFile() {
            this.$refs.uploader.click();
        },

        handleFiles() {
            var storageRef = storage.ref();
            var fileReference = storageRef.child(this.uploadToPath);

            // Currently only supporting single file uploads
            const file = this.$refs.uploader.files[0];
            const vm = this;
            fileReference.put(file).then(() => {
                this.$emit("uploaded", {
                    storagePath: vm.uploadToPath,
                    filename: file.name,
                });
            });
        },
    },
});
</script>

<style scoped>
input {
    display: none;
}

.inputLabel {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.uploadIcon {
    color: var(--ish-mediumgrey);
}
.uploadIcon:hover {
    color: var(--ish-darkgrey);
}
</style>