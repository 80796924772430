
/**
 * Map apis to retrieve layers, km markers...
 */

/**
 * api exports 
 */
export { getVisibleDistanceMarkers, getNearestMarker, getFirstDistanceMarker, distanceBetweenTwoCoordinates, getDistanceMarkers };

import { getLayers } from "./firestoreMapLayer.js";
import { Location } from "./classes/Location";
import { LAYERTYPE } from "../api/firestoreConstants";

/**
 * Promise to return array of distance marker definitions in the format [{ name, lat, lng }]
 */
const extractDistanceMarkers = function (url) {

  return new Promise(function (resolve, reject) {

    var xhttp = new XMLHttpRequest();
    xhttp.overrideMimeType('application/xml');
    xhttp.onreadystatechange = function () {

      if (xhttp.readyState == 4 && xhttp.status == 200) {
        const xmlDoc = xhttp.responseXML;

        if (xmlDoc) {
          let markers = []
          if (xmlDoc.documentElement.nodeName == "kml") {
            for (var item of xmlDoc.getElementsByTagName('Placemark')) {

              // names
              let name = item.getElementsByTagName('name')[0].childNodes[0].nodeValue
              let points = item.getElementsByTagName('Point')

              // marker (only one per name, not sure why this is an array)
              for (var point of points) {
                var coords = point.getElementsByTagName('coordinates')[0].childNodes[0].nodeValue.trim()
                let coord = coords.split(",")
                let location = new Location(+coord[1], +coord[0]);
                markers.push({ name: name, location: location });
              }
            }
            resolve(markers);
          }
        } else {
          reject('Invalid XML returned from ' + url);
        }
      }
    }
    xhttp.open("GET", url, true);
    xhttp.send();
  });
}

// store on window object, so that we don't keep retrieving from server - must be a better way!
window.distanceMarkers = [];

/**
 * Returns all the distance markers on the given Route. 
 * 
 * Return is { id, name, location, isMajor }
 */
const getDistanceMarkers = (trailId) => {
  return new Promise(function (resolve, reject) {

    if (window.distanceMarkers.length > 0) {
      resolve(window.distanceMarkers);
    } else {
      getLayers(trailId, LAYERTYPE.DISTANCE)
        .then((result) => {
          if (result.length == 1) {
            extractDistanceMarkers(result[0].url)
              .then((markers) => {
                if (markers.length == 0) {
                  reject("No items returned from ");
                } else {
                  const distanceMarkers = markers.map((item) => {
                    return {
                      id: item.name,
                      name: item.name,
                      location: new Location(item.location.lat, item.location.lng),
                      isMajor: item.name % 10 == 0
                    }
                  });
                  window.distanceMarkers = distanceMarkers;
                  resolve(window.distanceMarkers);
                }
              })
              .catch((error) => {
                reject(error);
              })
          } else {
            reject(`Too many distance layer files returned, should be 1 received ${result.length}`);
          }
        })
        .catch((error) => {
          reject(error);
        })
    }
  });
};

const getFirstDistanceMarker = function (trailId) {
  return new Promise(function (resolve, reject) {
    getDistanceMarkers(trailId).then(
      (markers) => {
        if (markers.length > 0) {
          resolve(markers[0]);
        } else {
          reject("No KM Markers");
        }
      }).catch(
        function (error) {
          reject(error)
        }
      )
  });
}

/**
 * 
 * @param {*} bounds 
 * @param {*} zoom 
 */
const getVisibleDistanceMarkers = function (trailId, bounds, zoom) {
  return new Promise(function (resolve) {
    // make sure we've got all the markers
    getDistanceMarkers(trailId).then((markers) => {

      // get the visible markers based on the zoom level to reduce clutter.
      const visible = markers.filter((item) => {

        let inBounds = bounds.contains(item.location.toGoogleMapLocation());
        let include = inBounds;
        if (zoom < 6) {
          include = inBounds && item.name % 1000 == 0;
        } else if (zoom < 7) {
          include = inBounds && item.name % 500 == 0;
        } else if (zoom < 8) {
          include = inBounds && item.name % 100 == 0;
        } else if (zoom < 11) {
          include = inBounds && item.name % 20 == 0;
        }
        return include;
      });

      for (var i in visible) {
        if (zoom >= 13) {
          visible[i].showLabel = true;
        } else {
          visible[i].showLabel = visible[i].name % 10 == 0
        }
      }
      resolve(visible);
    });
  });
};

/**
 * Returns the nearest marker definition ( { name, location, distance } ) to the provided location
 * @param {Location} location 
 */
const getNearestMarker = function (location) {
  return new Promise(function (resolve) {
    getDistanceMarkers().then((markers) => {
      let previous = markers[0];
      let closest = markers[0];
      let next = markers[0];
      let closestDistance = 10000000;
      for (let i = 0; i < markers.length; i++) {
        const currentLocation = markers[i].location;
        const distance = distanceBetweenTwoCoordinates(currentLocation, location);
        if (distance < closestDistance) {
          if (i > 0) {
            previous = markers[i-1];
          }
          if ((i + 1) < markers.length) {
            next = markers[i+1];
          }
          closest = markers[i];
          closestDistance = distance;
        }
      }
      
      let trailKm = parseInt(closest.name);

      // let distanceToPrevious = distanceBetweenTwoCoordinates(location, previous.location);
      // let distanceToNext = distanceBetweenTwoCoordinates(location, next.location);
      // let nextClosestDistance = Math.min(distanceToPrevious, distanceToNext);
      // let offSet = (Math.pow(closestDistance, 2) - Math.pow(nextClosestDistance,2) + 1)/2;
      // console.log("offset: " + offSet);
      // if (distanceToPrevious <= distanceToNext) {
      //   console.log("closest to previous");
      //   trailKm = parseInt(closest.name) - offSet;
      // } else {
      //   console.log("closest to next");
      //   trailKm = parseInt(closest.name) + offSet;
      // }
      resolve({
        name: closest.name,
        trailKm: parseFloat(trailKm.toFixed(1)),
        location: closest.location,
        distance: closestDistance,
      });
    });
  });
};

const distanceBetweenTwoCoordinates = function (location1, location2) {
  const lat1 = location1.lat;
  const lat2 = location2.lat;
  const lng1 = location1.lng;
  const lng2 = location2.lng;
  const R = 6371e3; // metres
  const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
  const φ2 = lat2 * Math.PI / 180;
  const Δφ = (lat2 - lat1) * Math.PI / 180;
  const Δλ = (lng2 - lng1) * Math.PI / 180;

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) *
    Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c;

  return d / 1000;  // in kilometres
};