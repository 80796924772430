<template>
  <div class="navigator">
    <hr class="divider">
    <div class="navigator__container">
      
      <div v-if="!canMoveFirst" class="spacerSmall"></div>
      <icon
        v-if="canMoveFirst"
        :options="iconOptionsSmall"
        @click="handleFirst"
        tooltip="First"
      >first_page</icon>

      <div v-if="previousDisabled" class="spacer"></div>
      <icon
        v-if="!previousDisabled"
        :options="iconOptions"
        @click="handlePrevious"
        tooltip="Previous"
      >keyboard_arrow_left</icon>

      <div class="navigator__date">{{dateDescription}}</div>
      <div v-if="nextDisabled" class="spacer"></div>
      <icon
        v-if="!nextDisabled"
        :options="iconOptions"
        @click="handleNext"
        tooltip="Next"
      >keyboard_arrow_right</icon>
      <div v-if="!canMoveLatest" class="spacerSmall"></div>
      <icon
        v-if="canMoveLatest"
        :options="iconOptionsSmall"
        @click="handleLatest"
        tooltip="Latest"
      >last_page</icon>

    </div>
    <hr class="divider">
  </div>
</template>

<script>
const { DateTime } = require("luxon");

import { defineComponent } from "vue";

import { constants } from "../../constants";

// Classes
import CheckInArray from "../../api/classes/CheckinArray";

// Components
import Icon from "../controls/Icon";

export default defineComponent({
  name: "CheckInNavigator",

  components: {
    Icon,
  },

  emits: ["dateClicked", "navigated"],

  props: {
    allCheckIns: CheckInArray,
    readOnly: {
      type: Boolean,
      default: true,
      required: false,
    }
  },

data() {
    return {
      dayDescription: {
        type: String,
        default: "-",
      },
      dateDescription: {
        type: String,
        default: "-",
      },
      nextDisabled: false,
      previousDisabled: false,
      canMoveFirst: false,
      canMoveLatest: false,
    };
  },

  created() {
    this.refreshDate();
    this.configureNavigationControls();
  },

  computed: {

    checkIn() {
      return this.allCheckIns.current;
    },
    iconOptions() {
      let options = {
        colour: constants.COLOURS.BUTTON_TEXT_DEFAULT,
        size: "32px",
        background: {
          shape: "CIRCLE",
          colour: "rgba(0, 0, 0, 0.07)",
          size: "50px",
          borderRadius: "25px"
        },
        hover: {
          shape: "CIRCLE",
          backgroundColour: "rgba(0, 0, 0, 0.1)",
          colour: constants.COLOURS.BUTTON_TEXT_HOVER
        },
      };
      return options;
    },
    iconOptionsSmall() {
      const options = {...constants.ICON_OPTIONS.ON_WHITE_ACTIONABLE};
      options.size = "16x";
      options.background.size = "32px";
      return options;
    },
    // iconOptionsPrevious() {
    //   let options = JSON.parse(JSON.stringify(this.iconOptions));
    //   options.background.colour = this.previousDisabled ? "red" : "gray";
    //   return options;
    // }
  },

  watch: {
    "allCheckIns.current.date": function () {
      this.refreshDate();
    },
    "allCheckIns.current": function () {
      this.refreshDate();
      this.configureNavigationControls();
    },
    "checkIn.numberOfRestDays": function () {
      this.refreshDate();
    },
  },

  methods: {
    configureNavigationControls() {

      this.nextDisabled = !this.allCheckIns.canMoveNext();
      this.previousDisabled = !this.allCheckIns.canMovePrevious();
      this.canMoveLatest = this.allCheckIns.canMoveLatest();
      this.canMoveFirst = this.allCheckIns.canMoveFirst();

    },
    handleMoreMenu() {},
    refreshDate() {
      let current = this.allCheckIns.current;
      this.dayDescription = this.allCheckIns.statistics[current.id].daysOnTrailDescription;
      this.dateDescription = current.date.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
    },

    handlePrevious() {
      if (this.allCheckIns.movePrevious()) {
        this.$emit("navigated");
      }
    },

    handleFirst() {
      if (this.allCheckIns.moveFirst()) {
        this.$emit("navigated");
      }
    },

    handleLatest() {
      if (this.allCheckIns.moveLatest()) {
        this.$emit("navigated");
      }
    },

    handleNext() {
      if (this.allCheckIns.moveNext()) {
        this.$emit("navigated");
      }
    },
  },
});
</script>

<style scoped>
.spacer {
  width: 50px;
}
.spacerSmall {
  width: 25px;
}
.navigator {
  display:flex;
  flex-direction: column;
  align-items: center;
}

.navigator__container {
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 3px;
  padding: 0.0em 10px 0px 10px;
  box-sizing: border-box;
}

.navigator__date {
  flex-grow: 1;
  text-align: center;
}
</style>