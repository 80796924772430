<template>
  <modal-dialog title="About" @close="$emit('close')">
    <div>
      <p>
        iStayedHere was built by me, Andrew Tokeley (Tokes), to help friends and
        family follow my 2021/22 journey on Te Araroa. I loved it!
      </p>
      <p>
        As I worked on the project I realised it was also a great way to
        support the wider hiker community who would regularly post questions
        like,
        <a
          href="https://istayedhere.com/tokes/view/xRbf9iEmF00ejJSwIm4u/nus4ill8OvcoDx9AUfzO?lat=-38.071852512472496&lng=174.96087170005404&zoom=10&share=true"
          target="_blank"
          >'Where did you stay between Pirongia and Waitomo'</a
        >
        or
        <a
          href="https://istayedhere.com/tokes/view/xRbf9iEmF00ejJSwIm4u/U58SRegHdJUvwJ18oXSG?lat=-35.218369421241114&lng=173.3833356546648&zoom=13&share=true"
          target="_blank"
          >'Are there any wild camping options in Raetea Forest?'</a
        >
      </p>
      <p>
        If you click the links above you'll see how I tackled these sections :-)
      </p>
      <p>
        I have a few people trying out iStayedHere and if enough people show
        interest who knows how it might evolve. If you want to give it a whirl
        get in touch,
        <a
          title="send email"
          target="_blank"
          href="mailto:andrewtokeley@gmail.com?subject=Interested in iStayedHere!"
          >andrewtokeley@gmail.com</a
        >
      </p>
      <p>Version {{ appVersion }}</p>
    </div>
  </modal-dialog>
</template>

<script>
import { defineComponent } from "vue";
import ModalDialog from "../controls/ModalDialog.vue";

export default defineComponent({
  name: "about",
  trackAsPageView: true,
  trackWithPageName: "About",
  emits: ["close"],
  components: {
    ModalDialog,
  },
  data() {
    return {
      appVersion: "",
    }
  },
  mounted() {
    this.appVersion = process.env.VUE_APP_VERSION;
  }
});
</script>

<style scoped>
</style>