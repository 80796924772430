<template>
  <div class="page">
    <header-main :hideButtons="true" @logoClick="$router.push('/')"></header-main>
    <div class="wrapper">
      <h1>Let's get started</h1>
      <p>Create an account, it's free! iStayedHere is the perfect record of your next thru hike or long adventure.</p>
      <div id="firebaseui-auth-container"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { firebase } from "../../api/firebaseInit";
import { ui } from "../../api/firebaseInitUI";

import HeaderMain from "../controls/Header";
import 'firebaseui/dist/firebaseui.css';

export default defineComponent({
  name: "SignUp",
  title: "Sign Up",
  components: {
    HeaderMain,
  },

  computed: {
    uiConfig() {
      return {
        signInFlow: "popup",
        signInSuccessUrl: '/signup2',
        signInOptions: [
          // Leave the lines as is for the providers you want to offer your users.
          {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            fullLabel: "Sign up with Google",
          },
          {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
            fullLabel: "Sign up with email",
            buttonColor: "#ff914d", //"#32a5d3",
          }
        ],
        // tosUrl and privacyPolicyUrl accept either url string or a callback
        // function.
        // Terms of service url/callback.
        tosUrl: '/TermsOfService',
        // Privacy policy url/callback.
        privacyPolicyUrl: function() {
          window.location.assign('/Privacy');
        },
        callbacks: {
          signInSuccessWithAuthResult: function(authResult, redirectUrl) {
            // Process result. This will not trigger on merge conflicts.
            // On success redirect to signInSuccessUrl.
            return true;
          },
          // signInFailure callback must be provided to handle merge conflicts which
          // occur when an existing credential is linked to an anonymous user.
          signInFailure: function(error) {
            console.log(error);
          }
        }
      }
    }
  },

  mounted() {
     ui.start('#firebaseui-auth-container', this.uiConfig);
  },

});

</script>

<style>
/* .firebaseui-title {
  display: none;
}
.firebaseui-card-header {
  padding-bottom: 16px;
}
.firebaseui-card-header::after {
  content: 'Sign uppp with email';
  color: rgba(0, 0, 0, 0.87);
  direction: ltr;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  padding: 0;
  padding-bottom: 0px;
  text-align: left;
} */
</style>
<style scoped>
.page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  background: white;
  border-radius: 5px;
  margin: 0px 20px;
  padding: 5px;
  max-width: 500px;
  /* padding-top: 15px; */
}

.wrapper h1 {
  font-size: 1.5em;
  color: var(--ish-darkgrey);
  text-align: center;
}

.wrapper p {
  color: var(--ish-mediumgrey);
  font-weight: 400;
  padding: 0px 20px 20px 20px;
}
</style>