<template>
  <div class="popup">
    <div class="popup__message">{{ message }}</div>
  </div>
</template>

<script>

import { defineComponent } from "vue";

export default defineComponent({
  name: "popupmessage",

  emits: ['close'],

  props: {
    message: String,
  },

  mounted() {
    const vm = this;
    setTimeout(() => {
      vm.$emit('close');
    }, 3000)
  }
})

</script>

<style scoped>

.popup {
  position: absolute;
  bottom: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup__message {
  min-width: 200px;
  border-radius: 5px;
  padding:10px 20px 20px 20px;
  /* height: 40px; */
  color: white;
  text-align: center;
  background: rgba(0,0,0,0.7);
}
</style>