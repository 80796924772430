import colorPalette from "@/constants/colorPalette";
import { constants } from "../constants";
var Path = require('svg-path-generator');

var tent = Path()
            .moveTo(0, 0)
            .relative().moveTo(0, -20)
            .relative().lineTo(-10, 20)
            .relative().lineTo(8, 0)
            .relative().lineTo(2, -15)
            .relative().lineTo(5, 15)
            .relative().lineTo(5, 0)
            .relative().lineTo(-10, -20)
            .end();

const {
  COLOR_LANDSCAPE,
  COLOR_BORDERS,
  COLOR_WATER,
  COLOR_LINE,
  COLOR_POINT_FILL,
  COLOR_SELECTED_POINT
} = colorPalette;

const COLORS = {
  BORDERS: COLOR_BORDERS,
  LANDSCAPE: COLOR_LANDSCAPE,
  LINE: COLOR_LINE,
  POINT: COLOR_SELECTED_POINT,
  POINT_FILL: COLOR_POINT_FILL,
  WATER: COLOR_WATER
};

const POINT_MARKER_ICON_CONFIG = {
  path: tent,
  strokeOpacity: 0.7,
  strokeWeight: 1,
  strokeColor: COLORS.POINT,
  fillColor: "#ffffff",
  fillOpacity: 0.7,
  scale: 1
};

const LINE_SYMBOL_CONFIG = {
  path: "M 0,-2 0,2",
  strokeOpacity: 1,
  strokeWeight: 2,
  scale: 1
};

const LINE_PATH_CONFIG = {
  clickable: false,
  geodesic: false,
  strokeOpacity: 0,
  strokeColor: COLORS.LINE,
  icons: [
    {
      icon: LINE_SYMBOL_CONFIG,
      repeat: "10px"
    }
  ]
};

const NEW_ZEALAND_BOUNDS = {
  north: -32.36,
  south: -47.35,
  west: 166.28,
  east: -175.81,
};

const mapSettings = {
  clickableIcons: true,
  streetViewControl: false,
  panControlOptions: false,
  gestureHandling: "greedy",
  backgroundColor: COLORS.LANDSCAPE,
  fullscreenControl: false,
  mapTypeControl: false,
  mapTypeControlOptions: {
    mapTypeIds: ['terrain', 'OSM'],
    position: constants.MAPCONTROL_POSITION.TOP_RIGHT,
  },
  mapTypeId: "terrain",
  // mapTypeId: "OSM",
  zoomControl: false,
  zoomControlOptions: {
    style: "SMALL",
    position: 9,
  },
  zoom: 5,
  minZoom: 2,
  draggableCursor: 'auto',
  restriction: {
    latLngBounds: NEW_ZEALAND_BOUNDS,
    strictBounds: false,
  },
  //maxZoom: 8,
  
  
};

export { mapSettings, LINE_PATH_CONFIG, POINT_MARKER_ICON_CONFIG };
