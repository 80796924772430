<template>
  <div class="base-autocomplete">
    <base-input
      ref="autocompleteRef"
      v-bind="$attrs"
      v-model="searchText"
      :options="{ ...inputOptions, inlineErrors: true }"
      @input="handleInput"
      @focus="displayResults"
      @blur="hideResults"
    />
    <div class="base-autocomplete__results" v-if="shouldShowResults">
      <ul class="base-autocomplete__list">
        <li
          class="base-autocomplete__list-item"
          v-for="item in filteredResults"
          :key="item"
          @click="clickItem(item)"
          @mousedown.prevent
        >
          {{ displayItem(item) }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import BaseInput from "./BaseInput.vue";

export default {
  components: { BaseInput },
  name: "base-autocomplete",
  props: {
    debounce: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 10,
    },
    results: {
      type: Array,
      default: () => {
        return [];
      },
    },
    displayItem: {
      type: Function,
      default: (item) => {
		return typeof item === "string" ? item : item.name;
      },
    },
    inputOptions: {},
  },
  emits: ["input", "onSelect"],

  setup(props, context) {
    const autocompleteRef = ref(null);
    let inputWidth = ref(0);
    let searchText = ref("");
    let timeout = null;
    let showResults = ref(true);

    /**
     * Triggered on input changes with a dynamic debounce
     * @param { InputEvent } e
     */
    function handleInput(e) {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        context.emit("input", e.target.value);
      }, props.debounce);
    }
    /**
     * Triggered on click on a result item
     */
    function clickItem(data) {
      context.emit("onSelect", data);
      this.searchText = "";
      showResults.value = false;
    }

    /**
     * Called on focus
     */
    function displayResults() {
      showResults.value = true;
    }

    /**
     * Called on blur
     */
    function hideResults() {
      showResults.value = false;
    }

    /**
     * Show results depending on results length and showResults boolean
     */
    let shouldShowResults = computed(() => {
      return showResults.value && props.results.length > 0;
    });

    /**
     * Return results filtered with the 'max' props
     */
    const filteredResults = computed(() => {
      return props.results.slice(0, props.max);
    });

    /**
     * Return data, making them reactive
     */
    return {
      searchText,
      showResults,
      autocompleteRef,
      inputWidth,
      displayResults,
      hideResults,
      handleInput,
      clickItem,
      filteredResults,
      shouldShowResults,
    };
  },
};
</script>

<style scoped>
.base-autocomplete__results {
  position: absolute;
  left: 20px;
  right: 20px;
  z-index: 100;
}
.base-autocomplete__list {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  background-color: white;
  list-style-type: none;
  margin-inline-start: 0em;
  margin-inline-end: 0em;
  margin-block-start: 0em;
  margin-block-end: 0em;
  padding-block-start: 0em;
  padding-block-end: 0em;
  padding-inline-start: 0em;
  padding-inline-end: 0em;
  /* padding: 5px; */
}

.base-autocomplete__list-item {
  cursor: pointer;
  padding: 10px 5px;
  font-size: var(--ish-font-size-normal);
}

.base-autocomplete__list-item:hover {
  background: var(--ish-lightgrey);
}
</style>