<template>
  <div class="registerStep2">
    <header-main
      :hideButtons="true"
      @logoClick="$router.push('/')"
    ></header-main>
    <div class="container">
      <div class="welcome">
        <h1>Welcome to I Stayed Here!</h1>
        <p>Record and share your biggest adventures.</p>
      </div>
      <div class="entry">
        <h2>Before you get started...</h2>
        <p>
          Choose a URL that uniquely identifies you - maybe it's even your trail
          name.
        </p>
        <div class="url">
          <div class="urlbase">https://istayedhere.com/</div>
          <base-input
            v-model="username"
            :options="{ placeholder: 'Add username' }"
            :validation="{ delay: 500, callback: validateUsername }"
          >
          </base-input>

          <!-- <text-input
            type="text"
            class="username"
            v-model="username"
            :validation="{ delay: 500, callback: validateUsername }"
            placeholder="Add username"
          ></text-input> -->

          <!-- <input
            type="text"
            class="username"
            v-model="username"
            @input="restartValidationDelayTimer"
            placeholder="Add username"
          /> -->
          <loader class="loader" :size="30" v-show="isValidating"></loader>
        </div>

        <div class="messageBlock">
          <div class="errorMessage" v-if="errorMessage">{{ errorMessage }}</div>
          <div class="confirmationMessage" v-if="confirmationMessage">
            {{ confirmationMessage }}
          </div>
        </div>
        <div class="buttons">
          <base-button buttonActionType="secondary" @click="handleCancel"
            >Cancel</base-button
          >
          <base-button
            :disabled="!validUsername || isValidating"
            @click="handleContinue"
            >Continue</base-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapState } from "vuex";

// Components
import BaseButton from "../controls/BaseButton.vue";
import Loader from "../controls/Loader";
import HeaderMain from "../controls/Header";
import BaseInput from "../controls/BaseInput.vue";

// API
import { isUniqueUsername, updateUserPublic } from "../../api/firestoreUser";
import { UrlBuilder } from "../../api/classes/UrlBuilder";

export default defineComponent({
  title: "Sign Up Step 2",
  components: {
    BaseButton,
    Loader,
    HeaderMain,
    BaseInput,
  },

  data() {
    return {
      username: "",
      errorMessage: undefined,
      confirmationMessage: undefined,
      validUsername: false,
      isValidating: false,
    };
  },

  computed: {
    ...mapState({
      user: "user",
    }),
  },

  mounted() {
    if (this.user.username) {
      this.$router.push(UrlBuilder.urlForUserHome(this.user.username));
    }  
  },

  methods: {
    handleContinue() {
      if (this.validUsername) {
      const vm = this;
      this.$store.commit("SET_USER_DATA", { username: this.username });
      this.user.username = this.username;
      updateUserPublic(this.user)
        .then(() => {
          vm.$router.push(`/${this.username}`);
        })
        .catch((error) => {
          console.log(error);
          vm.$router.push("/");
        });
      } else {
        this.displayMessage(`Sorry, '${this.username}' is already taken`, true);
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },

    displayMessage(message, isError) {
      if (isError) {
        this.confirmationMessage = "";
        this.errorMessage = message;
      } else {
        this.errorMessage = "";
        this.confirmationMessage = message;
      }
    },

    validateUsername(username) {
      const vm = this;
      return new Promise((resolve, reject) => {
        vm.isValidating = true;
        if (username.length == 0) {
          vm.displayMessage("Can't be empty", true);
          vm.isValidating = false;
          reject("username can't be empty");
        } else {
          console.log("checking " + username);  
          isUniqueUsername(username).then((isUnique) => {
            if (!isUnique) {
              vm.validUsername = false;
              vm.displayMessage(`Sorry, '${username}' is already taken`, true);
            } else {
              vm.validUsername = true;
              vm.displayMessage(`Yes! '${username}' is yours!`, false);
            }
            vm.isValidating = false;
            resolve();
          });
        }
      });
    },
  },
});
</script>

<style scoped>
.registerStep2 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  width: 40%;
  /* height: 50%; */
  border-radius: 5px;
  border: var(--ish-lightgrey) 1px solid;
  background: white;
  /* padding:20px; */
  box-sizing: border-box;
}

.welcome {
  background: var(--ish-lightgrey);
  padding: 10px 20px;
  border-radius: 5px;
}

.entry {
  padding: 10px 20px;
}

.url {
  display: flex;
}

.url .urlbase,
.url .username {
  border: 1px solid var(--ish-lightgrey);
  padding: 5px 10px;
  height: 30px;
  display: flex;
  align-items: center;
}

.url .urlbase {
  background-color: var(--ish-lightgrey);
}

.loader {
  position: relative;
}

.messageBlock {
  margin-top: 20px;
  height: 40px;
}

.errorMessage {
  color: var(--ish-red);
}

.confirmationMessage {
  color: var(--ish-darkgrey);
}

.buttons {
  float: right;
}

@media screen and (max-width: 650px) {
  .container {
    width: 90%;
  }
}

</style>