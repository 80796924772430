<template>
  <div class="follow">
    <modal-dialog @close="$emit('close')" title="Find Friends">
      
        <base-auto-complete
          class="follow__search__autocomplete"
          :inputOptions="{ placeholder: 'Search by name or username'}"
          v-model="userToFollow"
          @input="getItems"
          @onSelect="handleSelect"
          :results="items"
          :displayItem="displayUserFormat"
        >
        </base-auto-complete>

      <h2 v-if="following.length > 0">
        Friends
      </h2>

      <ul
        class="follow__list"
        v-for="follow in following"
        :key="follow.username"
      >
        <li class="follow__list-item">
          <div class="follow__list-item__left">
            <router-link :to="userHomePage(follow.username)">{{
              follow.displayName + " (" + follow.username + ")"
            }}</router-link>
          </div>
          <icon
            class="follow__list-item__delete"
            :showSpinner="isRemoving[follow.username] ?? false"
            tooltip="remove from list"
            :options="deleteIconOptions"
            @click="removeFollow(follow)"
            >close</icon
          >
        </li>
      </ul>

      <div class="follow__button-strip">
        <base-button :isSecondary="true" @click="$emit('close')">Close</base-button>
      </div> 
    </modal-dialog>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { constants } from "../../constants";

import { addFollow, removeFollow } from "../../api/firestoreUser";
import { getUsernames } from "../../api/firestoreUser";
import { UrlBuilder } from "../../api/classes/UrlBuilder";

import Icon from "./Icon";
// import BaseInput from "./BaseInput.vue";
import BaseButton from "./BaseButton.vue";
import ModalDialog from "./ModalDialog.vue";
import BaseAutoComplete from "./BaseAutoComplete.vue";

export default defineComponent({
  components: {
    // BaseInput,
    BaseButton,
    ModalDialog,
    Icon,
    BaseAutoComplete,
  },
  emits: ["close"],
  name: "Follow",

  data() {
    return {
      allUsers: [],
      following: [],
      userToFollow: {
        type: Object,
        default: null,
      },
      isSaving: false,
      isRemoving: {},
      isSearching: false,
      errorMessage: "",
      items: [],
    };
  },

  computed: {
    ...mapState({
      user: "user",
      loggedIn: "loggedIn",
    }),
    deleteIconOptions() {
      return constants.ICON_OPTIONS.ON_WHITE_ACTIONABLE;
    }
    
  },

  watch: {
    following() {
      this.$store.commit("SET_USER_DATA", {
        following: this.following,
      });
    },
  },

  mounted() {
    this.following = [...this.user.following];

    let vm = this;
    let exclude = [this.user.username, ...this.following.map( u => u.username )];
    getUsernames(exclude).then ((users) => {
      vm.allUsers = users;
    })
    // getFollowing(this.user.uid).then((following) => {
    //   vm.following = following;
    // });
  },

  methods: {
    getItems(search) {
      if (search == "") {
        this.items = [];
      } else {
        this.items = this.allUsers.filter(
          (u) => u.username.includes(search) || u.displayName.includes(search)
        );
      }
    },

    displayUserFormat(user) {
      return `${user.displayName} (${user.username})`;
    },

    handleSelect(data) {
      this.userToFollow = data;
      this.addFollow(data);
    },

    userHomePage(follow) {
      return UrlBuilder.urlForUserHome(follow);
    },

    addFollow(user) {
      let vm = this;
      this.isSaving = true;
      addFollow(this.user.uid, user)
        .then(() => {
          vm.following.push(user);
          // exclude the newly added user from further searches
          let index = vm.allUsers.findIndex( u => u.username == user.username);
          if (index >= 0) {
            vm.allUsers.splice(index, 1);
          }
          vm.userToFollow = "";        
        })
        .finally(() => {
          vm.isSaving = false;
        });
    },

    removeFollow(follow) {
      let vm = this;
      this.isRemoving[follow] = true;
      removeFollow(this.user.uid, follow)
        .then(() => {
          let index = vm.following.indexOf(follow);
          if (index >= 0) {
            vm.following.splice(index, 1);

            // add this user back to the users who can be searched
            vm.allUsers.push(follow);
            vm.allUsers.sort( (a,b) => (a.username > b.username));
          }
        })
        .finally(() => {
          vm.isRemoving[follow] = false;
        });
    },
  },
});
</script>

<style scoped>
.follow__search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.follow__list-item__delete {
  display: none;
}
.follow__list {
  list-style-type: none;
  margin-inline-start: 0em;
  margin-inline-end: 0em;
  margin-block-start: 0em;
  margin-block-end: 0em;
  padding-block-start: 0em;
  padding-block-end: 0em;
  padding-inline-start: 0em;
  padding-inline-end: 0em;
}

.follow__list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  padding: 5px;
  padding-left: 20px;
  border-bottom: var(--ish-lightgrey) solid 1px;
}

.follow__list-item__left {
  font-size: var(--ish-font-size-normal);
}

.follow__list-item:hover {
  background: var(--ish-extralightgrey);
}
.follow__list-item:hover .follow__list-item__delete {
  display: block;
}
/* .follow__p {
  margin-top: 40px;
} */

.follow__button-strip {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>