import { db, auth, firebase } from "./firebaseInit.js";
import { COLLECTIONS } from "./firestoreConstants.js";
import { userPublicConverter, userPrivateConverter, UserPrivate } from "./classes/User";


/**
 * Gets all publically searchable usernames (and their dsplayNames)
 * 
 * @param excludeUsers - array of usernames to exclude from the results
 * @returns Returns a promise containing and array of { username: didplayName} for all users up to a limit of 1000 for now - no paginate at this stage
 */
export function getUsernames(excludeUsers) {
  return new Promise(function (resolve, reject) {
    db.collection(COLLECTIONS.USERS)
      .withConverter(userPublicConverter)
      .limit(1000)
      .get()
      .then((querySnapshot) => {
        let results = [];
        querySnapshot.forEach( (doc) => {
          let user = doc.data();
          if (!excludeUsers || !excludeUsers.includes(user.username)) {
            results.push({ username: user.username, displayName: user.displayName });
          }
        })
        results.sort((a, b) => (a.username > b.username));
        resolve(results);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getUserByUsername(username) {

  return new Promise(function (resolve, reject) {
    db.collection(COLLECTIONS.USERS)
      .withConverter(userPublicConverter)
      .where("username", "==", username)
      .get()
      .then((querySnapshot) => {
        let resultCount = querySnapshot.size;
        if (resultCount == 1) {
          resolve(querySnapshot.docs[0].data());
        } else {
          resolve(null);
        }        
      })
      .catch(function (error) {
        reject("Can't fnd user");
      });
  });
}

export function getUser(uid, includePrivate) {
  return new Promise(function (resolve, reject) {
    let promises = [getUserPublic(uid)];
    if (includePrivate) {
      promises.push(getUserPrivate(uid));
    }
    Promise.all(promises).then ( (results) => {
      let user = results[0];
      if (results.length > 0) {
        user.private = results[1];
      }
      resolve(user);
    }).catch ( (error) => {
      reject(error);
    })
  });
}

export function getUserPublic(uid) {
  return new Promise(function (resolve, reject) {
    var docRef = db.collection(COLLECTIONS.USERS).doc(uid);
    docRef.withConverter(userPublicConverter)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.exists) {
          resolve(querySnapshot.data());
        } else {
          reject(`user does not exist, ${uid} (uid)`);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  });
}

export function getUserPrivate(uid) {
  return new Promise(function (resolve, reject) {
    var docRef = db.collection(COLLECTIONS.USERS).doc(uid).collection(COLLECTIONS.USERS_PRIVATE).doc(uid);
    docRef.withConverter(userPrivateConverter)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.exists) {
          resolve(querySnapshot.data());
        } else {
          //reject(`user has no private data does not exist, ${uid} (uid)`);
          // they don't have any private data 
          resolve(new UserPrivate({
            uid: uid,
            lastAdventureId: null,
            following: [],
          }));
        }
      })
      .catch(function (error) {
        reject(error);
      })
  });
}

export function recordUserLogin(uid, datetime) {
  return new Promise(function (resolve, reject) {
    db.collection(COLLECTIONS.USERS).doc(uid).collection(COLLECTIONS.USERS_PRIVATE).doc(uid)
      .withConverter(userPrivateConverter)
      .set({ 
        lastLoggedInDate: datetime 
        }, 
        { merge: true })
      .then(() => {
        getUser(uid, true).then( (user) => {
          resolve(user);
        })
        .catch ( (error) => {
          reject(error);  
        })
      })
      .catch( (error) => {
        reject(error);
      });
  });
}

/**
 * Updates the full user including user.private fields if not null.
 * @param {*} user 
 * @returns 
 */
export function updateUser(user) {
  let promises = [updateUserPublic(user)];
  if (user.private) {
    promises.push(updateUserPrivate(user));
  }
  return Promise.all(promises);
}

/**
 * Updates only the public records of a user record, or adds a new record if it doesn't exist.
 * @param {User} user 
 */
export function updateUserPublic(user) {
  return new Promise(function (resolve, reject) {

    isUniqueUsername(user.username)
      .then ( (isUnique) => {
        if (isUnique) {
          db.collection(COLLECTIONS.USERS).doc(user.uid)
            .withConverter(userPublicConverter)
            .set(user, { merge: true })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          reject("Username exists");
        }
      })
      .catch ( (error) => {
        reject("error checking uniqueness of username. " + error);
      })
    
  })
}

/**
 * Updates only the private records of a user record
 * @param {User} user 
 */
 export function updateUserPrivate(user) {
  return new Promise(function (resolve, reject) {

    db.collection(COLLECTIONS.USERS).doc(user.uid).collection(COLLECTIONS.USERS_PRIVATE).doc(user.uid)
      .withConverter(userPrivateConverter)
      .set(user, { merge: true })
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });
  })
}


/**
 * Returns a promise that verifies whether the given username is unique and available to use. Not it won't match
 * on the current user's username.
 * @param {} username 
 * @returns 
 */
export function isUniqueUsername(username) {

  return new Promise(function (resolve, reject) {
    db.collection(COLLECTIONS.USERS)
      .where("username", "==", username)
      .get()
      .then( (querySnapshot) => {
        if (querySnapshot.empty) {
          // definitely unique
          resolve(true);
        } else {
          // if there's only one record (there should be) then verify it's not
          // the current user
          if (querySnapshot.size == 1 && querySnapshot.docs[0].id != auth.currentUser.uid) {
            resolve(false);
          } else {
            // is "unique" in that you already use this name
            resolve(true);
          }
        }        
      })
      .catch((error) => {
        reject(error);
      })
  });
}

/**
 * Adds a reference to a friend's username and displayname
 * @param {*} uid - uid of the user who wants to add the follow
 * @param {*} follow {username: displayName} of the user to follow
 * @returns 
 */
export function addFollow(uid, follow) {
  return new Promise(function (resolve, reject) {
    db.collection(COLLECTIONS.USERS).doc(uid).collection(COLLECTIONS.USERS_PRIVATE).doc(uid)
      .update( {
        following: firebase.firestore.FieldValue.arrayUnion(follow)
      })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      })
    })
  }

  /**
   * Remove reference to friend
   * @param {*} uid 
   * @param {*} follow { usrname: displayName}
   * @returns 
   */
export function removeFollow(uid, follow) {
  return new Promise(function (resolve, reject) {
    db.collection(COLLECTIONS.USERS).doc(uid).collection(COLLECTIONS.USERS_PRIVATE).doc(uid)
      .update({
        following: firebase.firestore.FieldValue.arrayRemove(follow)
      })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      })
  })
}

export function getFollowing(uid) {
  return new Promise(function (resolve, reject) {
    getUserPrivate(uid).then((userPrivate) => {
      let following = [];
      if (userPrivate) {
        following = userPrivate.following;
        following.sort();
      }
      resolve(following);
    }).catch ( (error) => {
      reject(error);
    })
      // .get()
      // .then((user) => {
      //   resolve();
      // })
      // .catch((error) => {
      //   reject(error);
      // })
  })
}


