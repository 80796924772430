<template>
  <modal-dialog
    title="Account Settings"
    :actions="settingsActions"
    @close="$emit('close')"
  >
    <div>
      <base-input 
        :options="{ placeholder: 'Username',
                    descriptionText: `${userNameDescriptionText }`} "
        :validation="{ delay: 500, callback: validateUsername }" 
        v-model="username">
      </base-input>
      
      <base-input 
        :options="{ placeholder: 'Display Name',
                    descriptionText: 'This name will be displayed at the top of your home page' }"
        :validation="{ delay: 500, callback: validateDisplayName }" 
        v-model="displayName">
      </base-input>

      
    </div>
  </modal-dialog>
</template>

<script>
import { defineComponent } from "vue";
import ModalDialog from "./ModalDialog.vue";
import { mapState } from "vuex";
import { User } from "../../api/classes/User";
import { isUniqueUsername, updateUser } from "../../api/firestoreUser";
import BaseInput from "./BaseInput.vue";
import { UrlBuilder } from "../../api/classes/UrlBuilder";

export default defineComponent({
  name: "user-settings",

  emits: ["close", "displayNameChanged"],

  components: {
    ModalDialog,
    BaseInput,
  },

  computed: {
    ...mapState({
      user: "user",
    }),
  },

  data() {
    return {
      dbUser: User,
      displayName: "",
      username: "",
      publicUrl: "",
      userNameDescriptionText: "",
      settingsActions: [
        {
          isSecondary: true,
          title: "Close",
          handle: () => {
            this.$emit("close");
          },
        },
        {
          title: "Update",
          handle: (action) => {
            action.showSpinner = true;
            let vm = this;
            this.handleUpdate()
            .then( (user) => {
              vm.$emit("close");
            })
            .catch((error) => {
              console.log(error);
            })
            .finally( () => {
              action.showSpinner = false;
            });
          },
        },
      ]
    }
  },

  created() {
    this.username = this.user.username;
    this.displayName = this.user.displayName;
    this.publicUrl = UrlBuilder.urlForUserHome(this.username);
    this.updateUserNameDescriptionText();
  },

  methods: {
    updateUserNameDescriptionText() {
      if (this.publicUrl) {
        this.userNameDescriptionText = `Your public home page is <a href='${this.publicUrl}'>${this.publicUrl}</a>`
      } else {
        this.userNameDescriptionText = `<div style='color: red'>Sorry, '${this.username}' is not available</div>`;
      }
    },
    validateDisplayName(displayName) {
      return new Promise( (resolve, reject) => {
        if (displayName.length <= 100) {
          resolve();
        } else {
          reject('Invalid display name');
        }
      });
    },

    validateUsername(username) {
      let promise = Promise.resolve(true);
      if (this.user.username != username) {
        let vm = this;
        promise = isUniqueUsername(username).then( (unique) => {
          if (unique) {
            vm.publicUrl = UrlBuilder.urlForUserHome(username);
          } else {
            vm.publicUrl = null;
          }
          this.updateUserNameDescriptionText();
        })
      } else {
        this.publicUrl = UrlBuilder.urlForUserHome(username);
        this.updateUserNameDescriptionText();
      }
      return promise;
    },

    handleUpdate() {
      let vm = this;
      return new Promise( function(resolve, reject) {
        vm.user.username = vm.username;
        vm.user.displayName = vm.displayName;
        updateUser(vm.user) 
          .then( () => {
            vm.$store.commit("SET_USER_DATA", {
              username: vm.username,
              displayName: vm.displayName,
            });
            resolve(vm.user);
          })
          .catch( error => {
            reject(error);
          })
        
      });
    },
  }
});

</script>

<style scoped>

</style>